/*
 * Renders the info property component
 */
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Displays the info property component
 * @returns A flex box with label and value
 */
const InfoProperty = (props) => {
  const { label, value, colorEvenItems, testName, ...flexProps } = props;
  return (
    <Flex
      as="dl"
      direction={{
        base: 'column',
        sm: 'row',
      }}
      px="6"
      py="4"
      _even={
        colorEvenItems
          ? {
              bg: useColorModeValue('horvath.grey-light'),
            }
          : null
      }
      {...flexProps}
    >
      <Box as="dt" minWidth="180px" data-testid={`Label: ${testName}`}>
        {label}
      </Box>
      <Box as="dd" flex="1" fontWeight="semibold" data-testid={`Value: ${testName}`}>
        {value}
      </Box>
    </Flex>
  );
};

export default InfoProperty;
