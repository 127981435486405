/**
 * Slice which provides reducer and state of the auth state
 */
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import api from '../utils/api';
import useAsyncThunk from '../utils/sliceUtils';

const initialState = {
  auth: false,
};

/**
 * Tries to login the user
 */
export const login = useAsyncThunk('auth/login', async (payload) => {
  await api.post('/auth/login', payload, {
    withCredentials: true,
  });

  return true;
});

/**
 * Logouts the user
 */
export const logout = createAsyncThunk('auth/logout', async () => {
  await api.post(
    '/auth/logout',
    {},
    {
      withCredentials: true,
    },
  );
  return true;
});

/**
 * The slice of the auth state
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticate: (state) => {
      state.auth = true;
    },
    deauthenticate: (state) => {
      state.auth = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'login pending';
      })
      .addCase(login.fulfilled, (state) => {
        state.status = 'login succeeded';
        state.auth = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.auth = false;
      });
  },
});

/**
 * CreateSelector checks if the state has changed and only returns the changed part.
 * This is necessary, because the state is immutable and the component would rerender even if the state hasn't changed.
 * @see https://redux-toolkit.js.org/api/createSelector
 *
 * @important Do not try to destructure the auth state, when calling the selector in a component.
 * The auth state is already destructured in the selector!
 */
export const authSelector = createSelector(
  (state) => state.auth,
  (slice) => slice.auth,
);

// Action creators are generated for each case reducer function
export const { authenticate, deauthenticate } = authSlice.actions;

export default authSlice.reducer;
