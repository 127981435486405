/*
 * Renders the FinanceEvaluation view
 */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Center,
  Divider,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../components/ContentWrapper';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import { selectBenchmarkingCompanyInfo } from '../slices/benchmarkingCompanyInfoSlice';
import { fetchCompany, selectCompanyName, selectCompanyStatus } from '../slices/companySlice';

import QuartileChart from '../components/charts/QuartileChart';
import SelectionChart from '../components/charts/SelectionChart';
import {
  fetchBenchmarkingFinanceEvaluation,
  selectBenchmarkingFinanceEvaluationData,
  selectBenchmarkingFinanceEvaluationError,
  selectBenchmarkingFinanceEvaluationStatus,
} from '../slices/benchmarkingFinanceEvaluationSlice';
import {
  fetchBenchmarkingForEvaluation,
  selectBenchmarkingForEvaluationData,
} from '../slices/benchmarkingForEvaluationSlice';

/**
 * Displays cards for process and single processes with input fields for different finance KPIs
 * @returns Cards with input fields for different finance KPIs of the process and single processes
 */
const FinanceEvaluation = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const toast = useToast();
  const dispatch = useDispatch();
  const benchmarkingFinance = useSelector(selectBenchmarkingForEvaluationData);
  const benchmarkingFinanceEvaluation = useSelector(selectBenchmarkingFinanceEvaluationData);
  const companyName = useSelector(selectCompanyName);

  const benchmarkingCompanyInfo = useSelector(selectBenchmarkingCompanyInfo);

  const companyStatus = useSelector(selectCompanyStatus);

  const benchmarkingFinanceStatus = useSelector(selectBenchmarkingFinanceEvaluationStatus);
  const benchmarkingFinanceError = useSelector(selectBenchmarkingFinanceEvaluationError);

  const benchmarkingFinanceEvaluationStatus = useSelector(
    selectBenchmarkingFinanceEvaluationStatus,
  );
  const benchmarkingFinanceEvaluationError = useSelector(selectBenchmarkingFinanceEvaluationError);

  /**
   * Fetches the company data
   */
  const fetchCompanyData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .catch(() => {
        toast({
          title: t('errors.evaluation.finance.fetchCompanyDataFailed.title'),
          description: t('errors.evaluation.finance.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetches the benchmarking data
   */
  const fetchBenchmarkingData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(
          fetchBenchmarkingForEvaluation({ companyId: company.id, benchmarkingId: id }),
        ).catch(() => {
          toast({
            title: t('benchmarking.finance.fetchCompanyDataFailed.title'),
            description: t('benchmarking.finance.fetchCompanyDataFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
      })
      .catch(() => {
        toast({
          title: t('errors.evaluation.finance.fetchBenchmarkingDataFailed.title'),
          description: t('errors.evaluation.finance.fetchBenchmarkingDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetches the benchmarking data for evaluation
   */
  const fetchBenchmarkingEvaluationData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(
          fetchBenchmarkingFinanceEvaluation({ companyId: company.id, benchmarkingId: id }),
        ).catch(() => {
          toast({
            title: t('errors.evaluation.finance.fetchBenchmarkingEvaluationDataFailed.title'),
            description: t(
              'errors.evaluation.finance.fetchBenchmarkingEvaluationDataFailed.description',
            ),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
      })
      .catch(() => {
        toast({
          title: t('errors.evaluation.finance.fetchCompanyDataFailed.title'),
          description: t('errors.evaluation.finance.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Fetch company data
   */
  React.useEffect(() => {
    // Only fetch company data if the status of the reducer is 'idle'
    if (companyStatus === 'idle') {
      fetchCompanyData();
    }
  }, [companyStatus, dispatch]);

  /**
   * Fetch benchmarking data
   */
  React.useEffect(() => {
    if (benchmarkingFinanceStatus === 'idle') {
      fetchBenchmarkingData();
    }
  }, [benchmarkingFinanceStatus, dispatch]);

  /**
   * Fetch benchmarking evaluation data
   */
  React.useEffect(() => {
    // Only fetch finance benchmarking evaluation data if the status of the reducer is 'idle'
    if (benchmarkingFinanceEvaluationStatus === 'idle') {
      fetchBenchmarkingEvaluationData();
    }
  }, [benchmarkingFinanceEvaluationStatus, dispatch]);

  /**
   * Checks if not all kpis of process debtors are null
   */
  const checkDebtorsKPIsNotNull = () => {
    return (
      benchmarkingFinance.finDebitorsProcess.durationDays !== null ||
      benchmarkingFinance.finDebitorsProcess.percentageShare !== null ||
      benchmarkingFinance.finDebitorsProcess.percentageShareCustomer !== null ||
      benchmarkingFinance.finDebitorsProcess.numberBills !== null ||
      benchmarkingFinance.finDebitorsProcess.percentageShareIncorrectBills !== null ||
      benchmarkingFinance.finDebitorsProcess.numberBillPosition !== null ||
      benchmarkingFinance.finDebitorsProcess.timeSpanUntilBill !== null ||
      benchmarkingFinance.finDebitorsProcess.percentageShareElecBills !== null ||
      benchmarkingFinance.finDebitorsProcess.percentageShareBillsReminder !== null ||
      benchmarkingFinance.finDebitorsProcess.incomingPayments !== null ||
      benchmarkingFinance.finDebitorsProcess.timeSpanUntilClosingBusinessCase !== null ||
      benchmarkingFinance.finDebitorsProcess.incorrectPaymentAssignment !== null ||
      benchmarkingFinance.finDebitorsProcess.receivablesTurnoverTime !== null ||
      benchmarkingFinance.finDebitorsProcess.outsourcedReceivables !== null
    );
  };

  /**
   * Checks if not all values of process debtors are null
   */
  const checkDebtorsNotNull = () => {
    return (
      benchmarkingFinance.finDebitorsProcess !== null &&
      (benchmarkingFinance.finDebitorsProcess.cost !== null ||
        benchmarkingFinance.finDebitorsProcess.fte !== null ||
        checkDebtorsKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process creditors are null
   */
  const checkCreditorsKPIsNotNull = () => {
    return (
      benchmarkingFinance.finCreditorsProcess.durationProcessingCreditors !== null ||
      benchmarkingFinance.finCreditorsProcess.incorrectOrders !== null ||
      benchmarkingFinance.finCreditorsProcess.suppliersEdi !== null ||
      benchmarkingFinance.finCreditorsProcess.numberOrders !== null ||
      benchmarkingFinance.finCreditorsProcess.numberOrderPositions !== null ||
      benchmarkingFinance.finCreditorsProcess.automatedOrderEntry !== null ||
      benchmarkingFinance.finCreditorsProcess.electrSentOrders !== null ||
      benchmarkingFinance.finCreditorsProcess.numberBills !== null ||
      benchmarkingFinance.finCreditorsProcess.differenceBillsOrders !== null ||
      benchmarkingFinance.finCreditorsProcess.billWithoutOrder !== null ||
      benchmarkingFinance.finCreditorsProcess.incorrectPaymentsCreditors !== null ||
      benchmarkingFinance.finCreditorsProcess.timeSpanBillincomingPayment !== null
    );
  };

  /**
   * Checks if not all values of process creditors are null
   */
  const checkCreditorsNotNull = () => {
    return (
      benchmarkingFinance.finCreditorsProcess !== null &&
      (benchmarkingFinance.finCreditorsProcess.cost !== null ||
        benchmarkingFinance.finCreditorsProcess.fte !== null ||
        checkCreditorsKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process charges travel are null
   */
  const checkChargesTravelKPIsNotNull = () => {
    return (
      benchmarkingFinance.finChargesTravel !== null &&
      (benchmarkingFinance.finChargesTravel.payroll !== null ||
        benchmarkingFinance.finChargesTravel.employees !== null ||
        benchmarkingFinance.finChargesTravel.incorrectPayrolls !== null ||
        benchmarkingFinance.finChargesTravel.travelExpenseReport !== null ||
        benchmarkingFinance.finChargesTravel.processingDays !== null)
    );
  };

  /**
   * Checks if not all values of process charges travel are null
   */
  const checkChargesTravelNotNull = () => {
    return (
      benchmarkingFinance.finChargesTravel !== null &&
      (benchmarkingFinance.finChargesTravel.cost !== null ||
        benchmarkingFinance.finChargesTravel.fte !== null ||
        checkChargesTravelKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process asset management are null
   */
  const checkAssetManagementKPIsNotNull = () => {
    return (
      benchmarkingFinance.finAssetManagement !== null &&
      (benchmarkingFinance.finAssetManagement.assetsMasterData !== null ||
        benchmarkingFinance.finAssetManagement.assetsBookedProcessed !== null)
    );
  };

  /**
   * Checks if not all values of process asset management are null
   */
  const checkAssetManagementNotNull = () => {
    return (
      benchmarkingFinance.finAssetManagement !== null &&
      (benchmarkingFinance.finAssetManagement.cost !== null ||
        benchmarkingFinance.finAssetManagement.fte !== null ||
        checkAssetManagementKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process consolidation are null
   */
  const checkConsolidationKPIsNotNull = () => {
    return (
      benchmarkingFinance.finConsolidation.journalEntries !== null ||
      benchmarkingFinance.finConsolidation.daysMonthlyClosing !== null ||
      benchmarkingFinance.finConsolidation.daysMonthlyClosingReleaseExternReporting !== null ||
      benchmarkingFinance.finConsolidation.daysQuarterlyReport !== null ||
      benchmarkingFinance.finConsolidation.daysQuarterlyReportReleaseExternReporting !== null ||
      benchmarkingFinance.finConsolidation.daysAnnualReport !== null ||
      benchmarkingFinance.finConsolidation.daysAnnualReportReleaseExternReporting !== null ||
      benchmarkingFinance.finConsolidation.numberConsolidatedLegalEntities !== null ||
      benchmarkingFinance.finConsolidation.fteConsolidation !== null ||
      benchmarkingFinance.finConsolidation.costConsolidation !== null ||
      benchmarkingFinance.finConsolidation.completionDataReporting !== null ||
      benchmarkingFinance.finConsolidation.monthlyCompletion !== null ||
      benchmarkingFinance.finConsolidation.quarterlyCompletion !== null ||
      benchmarkingFinance.finConsolidation.annualCompletion !== null ||
      benchmarkingFinance.finConsolidation.extraLoadManualActivity !== null ||
      benchmarkingFinance.finConsolidation.qualityDataReporting !== null ||
      benchmarkingFinance.finConsolidation.qualityVotingProcess !== null ||
      benchmarkingFinance.finConsolidation.integrationLegalManagement !== null ||
      benchmarkingFinance.finConsolidation.generationConsolidatedNumbers !== null ||
      benchmarkingFinance.finConsolidation.challengeGroupAccounting !== null
    );
  };

  /**
   * Checks if not all values of process consolidation are null
   */
  const checkConsolidationNotNull = () => {
    return (
      benchmarkingFinance.finConsolidation !== null &&
      (benchmarkingFinance.finConsolidation.cost !== null ||
        benchmarkingFinance.finConsolidation.fte !== null ||
        checkConsolidationKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process tax are null
   */
  const checkTaxKPIsNotNull = () => {
    return (
      benchmarkingFinance.finTax !== null &&
      (benchmarkingFinance.finTax.reportingUnit !== null ||
        benchmarkingFinance.finTax.taxDeclaration !== null ||
        benchmarkingFinance.finTax.taxDeclarationBalance !== null)
    );
  };

  /**
   * Checks if not all values of process tax are null
   */
  const checkTaxNotNull = () => {
    return (
      benchmarkingFinance.finTax !== null &&
      (benchmarkingFinance.finTax.cost !== null ||
        benchmarkingFinance.finTax.fte !== null ||
        checkTaxKPIsNotNull())
    );
  };

  /**
   * Checks if not all kpis of process treasury are null
   */
  const checkTreasuryKPIsNotNull = () => {
    return (
      benchmarkingFinance.finTreasury.avgExternPaymentTransactions !== null ||
      benchmarkingFinance.finTreasury.avgExternManualPayments !== null ||
      benchmarkingFinance.finTreasury.avgInternPayments !== null ||
      benchmarkingFinance.finTreasury.employeesPaymentTransactions !== null ||
      benchmarkingFinance.finTreasury.financeTransactionsConcluded !== null ||
      benchmarkingFinance.finTreasury.inventoryTreasuryFinanceTransactions !== null ||
      benchmarkingFinance.finTreasury.automatedProcessingTreasuryTransactions !== null ||
      benchmarkingFinance.finTreasury.employeesTreasuryFinanceTransactionsCompleted !== null ||
      benchmarkingFinance.finTreasury.employeesProcessingTreasuryFinanceTransactions !== null ||
      benchmarkingFinance.finTreasury.efficiency !== null ||
      benchmarkingFinance.finTreasury.liquidityPlanningHorizonMonths !== null ||
      benchmarkingFinance.finTreasury.liquidityPlanningFrequenceMonths !== null ||
      benchmarkingFinance.finTreasury.liquidityPlanningDurationDays !== null ||
      benchmarkingFinance.finTreasury.numberEmployeesLiquidityPlanning !== null ||
      benchmarkingFinance.finTreasury.accuracyLiquidityPlanning !== null
    );
  };

  /**
   * Checks if not all values of process treasury are null
   */
  const checkTreasuryNotNull = () => {
    return (
      benchmarkingFinance.finTreasury !== null &&
      (benchmarkingFinance.finTreasury.cost !== null ||
        benchmarkingFinance.finTreasury.fte !== null ||
        checkTreasuryKPIsNotNull())
    );
  };

  /**
   * Checks if not all values of process further development are null
   */
  const checkFurtherDevelopmentNotNull = () => {
    return (
      benchmarkingFinance.finFurtherDevelopmentFinance !== null &&
      (benchmarkingFinance.finFurtherDevelopmentFinance.cost !== null ||
        benchmarkingFinance.finFurtherDevelopmentFinance.fte !== null)
    );
  };

  /**
   * Checks if single processes should be displayed
   */
  const checkSingleProcessesNotNull = () => {
    return (
      checkDebtorsNotNull() ||
      checkCreditorsNotNull() ||
      checkChargesTravelNotNull() ||
      checkAssetManagementNotNull() ||
      checkConsolidationNotNull() ||
      checkTaxNotNull() ||
      checkTreasuryNotNull() ||
      checkFurtherDevelopmentNotNull()
    );
  };

  const renderFinanceEvaluation = () => {
    if (
      benchmarkingFinanceStatus === 'loading' ||
      benchmarkingFinanceEvaluationStatus === 'loading'
    ) {
      return (
        <>
          <Center mt={8}>
            <Spinner />
          </Center>
          <Center>
            <Text>{t('finance.evaluation.loading')}</Text>
          </Center>
        </>
      );
    }
    if (
      benchmarkingFinanceStatus === 'succeeded' &&
      benchmarkingFinanceEvaluation.totalProcess.totalFte === null &&
      benchmarkingFinanceEvaluation.totalProcess.totalCost === null
    ) {
      return (
        <Center>
          <Text>{t('finance.evaluation.noDataExists')}</Text>
        </Center>
      );
    }
    if (benchmarkingFinanceStatus === 'succeeded') {
      return (
        <>
          {benchmarkingFinance.sumCostFinance !== null ||
          benchmarkingFinance.sumFteFinance !== null ? (
            <Card>
              <CardHeader
                title={t('finance.benchmarking.totalProcess.title')}
                tooltipText={t('finance.benchmarking.totalProcess.tooltip')}
              />
              <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                {benchmarkingFinance.sumFteFinance !== null &&
                benchmarkingFinance.sumFteFinance !== 0 ? (
                  <Center>
                    <QuartileChart
                      legendTextLeft={t('finance.evaluation.legendText.fte')}
                      companyName={companyName}
                      quartileData={benchmarkingFinanceEvaluation.totalProcess.totalFte}
                      companyBenchmarkingValue={benchmarkingFinance.sumFteFinance}
                      data-testid
                    />
                  </Center>
                ) : null}
                ;
                {benchmarkingFinance.sumCostFinance !== null &&
                benchmarkingFinance.sumCostFinance !== 0 ? (
                  <Center>
                    <QuartileChart
                      legendTextLeft={t('finance.evaluation.legendText.costs')}
                      companyName={companyName}
                      quartileData={benchmarkingFinanceEvaluation.totalProcess.totalCost}
                      companyBenchmarkingValue={benchmarkingFinance.sumCostFinance}
                    />
                  </Center>
                ) : null}
                ;
              </Stack>
            </Card>
          ) : null}
          <br />
          {checkSingleProcessesNotNull() ? (
            <Card>
              <CardHeader
                title={t('finance.benchmarking.singleProcesses.title')}
                tooltipText={t('finance.benchmarking.singleProcesses.tooltip')}
              />
              <Accordion allowToggle>
                {checkDebtorsNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkDebtorsKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.debtorsProcess.title')}
                            tooltipText={t('finance.benchmarking.debtorsProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finDebitorsProcess.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finDebitorsProcess.fte}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finDebitorsProcess.cost}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.cost
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finDebitorsProcess.durationDays !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsDurationDays.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess.durationDays
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.durationDays
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.percentageShare !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsPercentageShare.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess.percentageShare
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.percentageShare
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.percentageShareCustomer !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsPercentageShareCustomer.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .percentageShareCustomer
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.percentageShareCustomer
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.numberBills !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsNumberBill.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess.numberBills
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.numberBills
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.percentageShareIncorrectBills !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsPercentageShareIncorrectBills.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .percentageShareIncorrectBills
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess
                                    .percentageShareIncorrectBills
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.numberBillPosition !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsNumberBillPosition.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .numberBillPosition
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.numberBillPosition
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.timeSpanUntilBill !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilBill.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess.timeSpanUntilBill
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.timeSpanUntilBill
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.percentageShareElecBills !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsPercentageShareElecBills.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .percentageShareElecBills
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.percentageShareElecBills
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.percentageShareBillsReminder !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsPercentageShareBillsReminder.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .percentageShareBillsReminder
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess
                                    .percentageShareBillsReminder
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.incomingPayments !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsIncomingPayments.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess.incomingPayments
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.incomingPayments
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess
                            .timeSpanUntilClosingBusinessCase !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilClosingBusinessCase.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .timeSpanUntilClosingBusinessCase
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess
                                    .timeSpanUntilClosingBusinessCase
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.incorrectPaymentAssignment !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsIncorrectPaymentAssignment.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .incorrectPaymentAssignment
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.incorrectPaymentAssignment
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.receivablesTurnoverTime !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsReceivablesTurnoverTime.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .receivablesTurnoverTime
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.receivablesTurnoverTime
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finDebitorsProcess.outsourcedReceivables !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.debtorsProcess.debtorsOutsourcedReceivables.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finDebitorsProcess
                                    .outsourcedReceivables
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finDebitorsProcess.outsourcedReceivables
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkCreditorsNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkCreditorsKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.creditorsProcess.title')}
                            tooltipText={t('finance.benchmarking.creditorsProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finCreditorsProcess.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finCreditorsProcess.fte}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.cost
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finCreditorsProcess.durationProcessingCreditors !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsDurationProcessingCreditors.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .durationProcessingCreditors
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess
                                    .durationProcessingCreditors
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.incorrectOrders !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsIncorrectOrders.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.incorrectOrders
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.incorrectOrders
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.suppliersEdi !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsSuppliersEdi.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.suppliersEdi
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.suppliersEdi
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.numberOrders !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsNumberOrders.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.numberOrders
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.numberOrders
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.numberOrderPositions !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsNumberOrderPositions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .numberOrderPositions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.numberOrderPositions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.automatedOrderEntry !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsAutomatedOrderEntry.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .automatedOrderEntry
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.automatedOrderEntry
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.electrSentOrders !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsElectrSentOrders.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.electrSentOrders
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.electrSentOrders
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.numberBills !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsNumberBills.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.numberBills
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.numberBills
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.differenceBillsOrders !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsDifferenceBillsOrders.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .differenceBillsOrders
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.differenceBillsOrders
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.billWithoutOrder !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsBillWithoutOrder.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess.billWithoutOrder
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.billWithoutOrder
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.incorrectPaymentsCreditors !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsIncorrectPaymentsCreditors.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .incorrectPaymentsCreditors
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess.incorrectPaymentsCreditors
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finCreditorsProcess.timeSpanBillincomingPayment !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.creditorsProcess.creditorsTimeSpanBillincomingPayment.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finCreditorsProcess
                                    .timeSpanBillincomingPayment
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finCreditorsProcess
                                    .timeSpanBillincomingPayment
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkChargesTravelNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkChargesTravelKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.finChargesTravel.title')}
                            tooltipText={t('finance.benchmarking.finChargesTravel.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finChargesTravel.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finChargesTravel.fte}
                                companyBenchmarkingValue={benchmarkingFinance.finChargesTravel.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finChargesTravel.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finChargesTravel.cost}
                                companyBenchmarkingValue={benchmarkingFinance.finChargesTravel.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finChargesTravel.payroll !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finChargesTravel.finChargesTravelPayroll.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finChargesTravel.payroll
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finChargesTravel.payroll
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finChargesTravel.employees !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finChargesTravel.finChargesTravelEmployees.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finChargesTravel.employees
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finChargesTravel.employees
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finChargesTravel.incorrectPayrolls !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finChargesTravel.finChargesTravelIncorrectPayrolls.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finChargesTravel.incorrectPayrolls
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finChargesTravel.incorrectPayrolls
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finChargesTravel.travelExpenseReport !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finChargesTravel.finChargesTravelTravelExpenseReport.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finChargesTravel.travelExpenseReport
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finChargesTravel.travelExpenseReport
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finChargesTravel.processingDays !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finChargesTravel.finChargesTravelProcessingDays.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finChargesTravel.processingDays
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finChargesTravel.processingDays
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkAssetManagementNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkAssetManagementKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.assetMgtProcess.title')}
                            tooltipText={t('finance.benchmarking.assetMgtProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finAssetManagement.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finAssetManagement.fte}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finAssetManagement.fte
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finAssetManagement.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finAssetManagement.cost}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finAssetManagement.cost
                                }
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finAssetManagement.assetsMasterData !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.assetMgtProcess.assetMgtAssetsMasterData.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finAssetManagement.assetsMasterData
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finAssetManagement.assetsMasterData
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finAssetManagement.assetsBookedProcessed !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.assetMgtProcess.assetsMgtAssetsBookedProcessed.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finAssetManagement
                                    .assetsBookedProcessed
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finAssetManagement.assetsBookedProcessed
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkConsolidationNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkConsolidationKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.consolidationProcess.title')}
                            tooltipText={t('finance.benchmarking.consolidationProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finConsolidation.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finConsolidation.fte}
                                companyBenchmarkingValue={benchmarkingFinance.finConsolidation.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finConsolidation.cost}
                                companyBenchmarkingValue={benchmarkingFinance.finConsolidation.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finConsolidation.journalEntries !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolJournalEntries.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.journalEntries
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.journalEntries
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.daysMonthlyClosing !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosing.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.daysMonthlyClosing
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.daysMonthlyClosing
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation
                            .daysMonthlyClosingReleaseExternReporting !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosingReleaseExternReporting.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .daysMonthlyClosingReleaseExternReporting
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation
                                    .daysMonthlyClosingReleaseExternReporting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.daysQuarterlyReport !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReport.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.daysQuarterlyReport
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.daysQuarterlyReport
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation
                            .daysQuarterlyReportReleaseExternReporting !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReportReleaseExternReporting.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .daysQuarterlyReportReleaseExternReporting
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation
                                    .daysQuarterlyReportReleaseExternReporting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.daysAnnualReport !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysAnnualReport.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.daysAnnualReport
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.daysAnnualReport
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation
                            .daysAnnualReportReleaseExternReporting !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolDaysAnnualReportReleaseExternReporting.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .daysAnnualReportReleaseExternReporting
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation
                                    .daysAnnualReportReleaseExternReporting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.numberConsolidatedLegalEntities !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolNumberConsolidatedLegalEntities.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .numberConsolidatedLegalEntities
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation
                                    .numberConsolidatedLegalEntities
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.fteConsolidation !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolFteConsolidation.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.fteConsolidation
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.fteConsolidation
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.costConsolidation !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolCostConsolidation.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.costConsolidation
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.costConsolidation
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.completionDataReporting !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolCompletionDataReporting.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .completionDataReporting
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.completionDataReporting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.annualCompletion !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolAnnualCompletion.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.annualCompletion
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.annualCompletion
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.quarterlyCompletion !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolQuarterlyCompletion.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.quarterlyCompletion
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.quarterlyCompletion
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.monthlyCompletion !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolMonthlyCompletion.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finConsolidation.monthlyCompletion
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.monthlyCompletion
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.extraLoadManualActivity !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .extraLoadManualActivity
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.veryHigh',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.significant',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.noticeable',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.none',
                                  ),
                                ]}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finConsolidation.extraLoadManualActivity
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.qualityDataReporting !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolQualityDataReporting.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .qualityDataReporting
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.veryPoor',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.unsatisfactory',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.ok',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.veryGood',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingFinance.finConsolidation.qualityDataReporting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.qualityVotingProcess !== null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .qualityVotingProcess
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.veryPoor',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.unsatisfactory',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.ok',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.veryGood',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingFinance.finConsolidation.qualityVotingProcess
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.integrationLegalManagement !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .integrationLegalManagement
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.none',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.little',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.dataModel',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.fully',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingFinance.finConsolidation.integrationLegalManagement
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.generationConsolidatedNumbers !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .generationConsolidatedNumbers
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.one',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.two',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.more',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.noAnswer',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingFinance.finConsolidation.generationConsolidatedNumbers
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finConsolidation.challengeGroupAccounting !==
                          null ? (
                            <Center>
                              <SelectionChart
                                legendTextLeft={t(
                                  'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.title',
                                )}
                                type="vertical"
                                companyName={companyName}
                                selectionData={
                                  benchmarkingFinanceEvaluation.finConsolidation
                                    .challengeGroupAccounting
                                }
                                labels={[
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.IFRS',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.moreInfo',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.euTax',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.closingDates',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.processAutomation',
                                  ),
                                  t(
                                    'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.newSkills',
                                  ),
                                ]}
                                companySelectedValue={
                                  benchmarkingFinance.finConsolidation.challengeGroupAccounting
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkTaxNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkTaxKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.finTaxProcess.title')}
                            tooltipText={t('finance.benchmarking.finTaxProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finTax.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTax.fte}
                                companyBenchmarkingValue={benchmarkingFinance.finTax.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTax.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTax.cost}
                                companyBenchmarkingValue={benchmarkingFinance.finTax.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finTax.reportingUnit !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTaxProcess.finTaxReportingUnit.title',
                                )}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTax.reportingUnit}
                                companyBenchmarkingValue={benchmarkingFinance.finTax.reportingUnit}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTax.taxDeclaration !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTaxProcess.finTaxTaxDeclaration.title',
                                )}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTax.taxDeclaration}
                                companyBenchmarkingValue={benchmarkingFinance.finTax.taxDeclaration}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTax.taxDeclarationBalance !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTaxProcess.finTaxTaxDeclarationBalance.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTax.taxDeclarationBalance
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTax.taxDeclarationBalance
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkTreasuryNotNull() ? (
                  <AccordionItem p={2} isDisabled={!checkTreasuryKPIsNotNull()}>
                    {({ isExpanded }) => (
                      <Card>
                        <AccordionButton
                          sx={{
                            padding: '0',
                            display: 'block',
                          }}
                        >
                          <CardHeader
                            title={t('finance.benchmarking.finTreasuryProcess.title')}
                            tooltipText={t('finance.benchmarking.finTreasuryProcess.tooltip')}
                            action={
                              isExpanded ? (
                                <ChevronUpIcon fontSize="30px" />
                              ) : (
                                <ChevronDownIcon fontSize="30px" />
                              )
                            }
                          />
                        </AccordionButton>
                        <Stack
                          direction={{ base: 'column', xl: 'row' }}
                          justifyContent="space-evenly"
                        >
                          {benchmarkingFinance.finTreasury.fte !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.fte')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTreasury.fte}
                                companyBenchmarkingValue={benchmarkingFinance.finTreasury.fte}
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.cost !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t('finance.evaluation.legendText.costs')}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTreasury.cost}
                                companyBenchmarkingValue={benchmarkingFinance.finTreasury.cost}
                              />
                            </Center>
                          ) : null}
                        </Stack>
                        <AccordionPanel>
                          <Divider mb={1} />
                          {benchmarkingFinance.finTreasury.avgExternPaymentTransactions !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryAvgExternPaymentTransactions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .avgExternPaymentTransactions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.avgExternPaymentTransactions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.avgExternManualPayments !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryAvgExternManualPayments.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury.avgExternManualPayments
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.avgExternManualPayments
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.avgInternPayments !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryAvgInternPayments.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury.avgInternPayments
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.avgInternPayments
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.employeesPaymentTransactions !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryEmployeesPaymentTransactions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .employeesPaymentTransactions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.employeesPaymentTransactions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.financeTransactionsConcluded !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryFinanceTransactionsConcluded.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .financeTransactionsConcluded
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.financeTransactionsConcluded
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.inventoryTreasuryFinanceTransactions !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryInventoryTreasuryFinanceTransactions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .inventoryTreasuryFinanceTransactions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury
                                    .inventoryTreasuryFinanceTransactions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury
                            .automatedProcessingTreasuryTransactions !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryAutomatedProcessingTreasuryTransactions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .automatedProcessingTreasuryTransactions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury
                                    .automatedProcessingTreasuryTransactions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury
                            .employeesTreasuryFinanceTransactionsCompleted !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryEmployeesTreasuryFinanceTransactionsCompleted.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .employeesTreasuryFinanceTransactionsCompleted
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury
                                    .employeesTreasuryFinanceTransactionsCompleted
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury
                            .employeesProcessingTreasuryFinanceTransactions !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryEmployeesProcessingTreasuryFinanceTransactions.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .employeesProcessingTreasuryFinanceTransactions
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury
                                    .employeesProcessingTreasuryFinanceTransactions
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.efficiency !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryEfficiency.title',
                                )}
                                companyName={companyName}
                                quartileData={benchmarkingFinanceEvaluation.finTreasury.efficiency}
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.efficiency
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.liquidityPlanningHorizonMonths !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningHorizonMonths.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .liquidityPlanningHorizonMonths
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.liquidityPlanningHorizonMonths
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.liquidityPlanningFrequenceMonths !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningFrequenceMonths.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .liquidityPlanningFrequenceMonths
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.liquidityPlanningFrequenceMonths
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.liquidityPlanningDurationDays !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningDurationDays.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .liquidityPlanningDurationDays
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.liquidityPlanningDurationDays
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.numberEmployeesLiquidityPlanning !==
                          null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryNumberEmployeesLiquidityPlanning.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .numberEmployeesLiquidityPlanning
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.numberEmployeesLiquidityPlanning
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                          {benchmarkingFinance.finTreasury.accuracyLiquidityPlanning !== null ? (
                            <Center>
                              <QuartileChart
                                legendTextLeft={t(
                                  'finance.benchmarking.finTreasuryProcess.treasuryAccuracyLiquidityPlanning.title',
                                )}
                                companyName={companyName}
                                quartileData={
                                  benchmarkingFinanceEvaluation.finTreasury
                                    .accuracyLiquidityPlanning
                                }
                                companyBenchmarkingValue={
                                  benchmarkingFinance.finTreasury.accuracyLiquidityPlanning
                                }
                                data-testid
                              />
                            </Center>
                          ) : null}
                        </AccordionPanel>
                      </Card>
                    )}
                  </AccordionItem>
                ) : null}
                {checkFurtherDevelopmentNotNull() ? (
                  <AccordionItem p={2}>
                    <Card>
                      <AccordionButton
                        sx={{
                          padding: '0',
                          display: 'block',
                        }}
                      >
                        <CardHeader
                          title={t('finance.benchmarking.finFurtherDevelopmentFinance.title')}
                          tooltipText={t(
                            'finance.benchmarking.finFurtherDevelopmentFinance.tooltip',
                          )}
                        />
                      </AccordionButton>
                      <Stack
                        direction={{ base: 'column', xl: 'row' }}
                        justifyContent="space-evenly"
                      >
                        {benchmarkingFinance.finFurtherDevelopmentFinance.fte !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('finance.evaluation.legendText.fte')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingFinanceEvaluation.finFurtherDevelopmentFinance.fte
                              }
                              companyBenchmarkingValue={
                                benchmarkingFinance.finFurtherDevelopmentFinance.fte
                              }
                              data-testid
                            />
                          </Center>
                        ) : null}
                        {benchmarkingFinance.finFurtherDevelopmentFinance.cost !== null ? (
                          <Center>
                            <QuartileChart
                              legendTextLeft={t('finance.evaluation.legendText.costs')}
                              companyName={companyName}
                              quartileData={
                                benchmarkingFinanceEvaluation.finFurtherDevelopmentFinance.cost
                              }
                              companyBenchmarkingValue={
                                benchmarkingFinance.finFurtherDevelopmentFinance.cost
                              }
                            />
                          </Center>
                        ) : null}
                      </Stack>
                    </Card>
                  </AccordionItem>
                ) : null}
              </Accordion>
            </Card>
          ) : null}
        </>
      );
    }
    if (benchmarkingFinanceStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingFinanceError}
          onRefresh={fetchBenchmarkingData}
          maxW="7xl"
          mt={8}
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    if (benchmarkingFinanceEvaluationStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingFinanceEvaluationError}
          onRefresh={fetchBenchmarkingEvaluationData}
          maxW="7xl"
          mt={8}
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <PageHeader title={`${t('finance.evaluation.pageHeader')} ${benchmarkingCompanyInfo.year}`} />
      <p>
        {t('finance.evaluation.evaluationYear')} {benchmarkingCompanyInfo.year}.
      </p>
      <ContentWrapper>{renderFinanceEvaluation()}</ContentWrapper>
    </div>
  );
};

export default FinanceEvaluation;
