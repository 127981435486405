/*
 * Slice which provides reducer and states of the finance benchmarking evaluation state,
 * which holds the cumulated data in finance
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: {
    totalProcess: {
      totalFte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      totalCost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finAssetManagement: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      assetsMasterData: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      assetsBookedProcessed: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finChargesTravel: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      payroll: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      employees: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      incorrectPayrolls: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      travelExpenseReport: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      processingDays: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finConsolidation: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      journalEntries: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysMonthlyClosing: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysMonthlyClosingReleaseExternReporting: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysQuarterlyReport: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysQuarterlyReportReleaseExternReporting: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysAnnualReport: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      daysAnnualReportReleaseExternReporting: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberConsolidatedLegalEntities: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      fteConsolidation: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      completionDataReporting: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      costConsolidation: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      monthlyCompletion: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      quarterlyCompletion: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      annualCompletion: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      extraLoadManualActivity: [
        {
          kind: 'selection',
          high: '',
          significant: '',
          noticeable: '',
          none: '',
        },
      ],
      qualityDataReporting: [
        {
          kind: 'selection',
          bad: '',
          unsatisfactory: '',
          ok: '',
          good: '',
        },
      ],
      qualityVotingProcess: [
        {
          kind: 'selection',
          bad: '',
          unsatisfactory: '',
          ok: '',
          good: '',
        },
      ],
      integrationLegalManagement: [
        {
          kind: 'selection',
          none: '',
          little: '',
          integrated: '',
          full: '',
        },
      ],
      generationConsolidatedNumbers: [
        {
          kind: 'selection',
          one: '',
          two: '',
          moreThanTwo: '',
          noAnswer: '',
        },
      ],
      challengeGroupAccounting: [
        {
          kind: 'selection',
          ifrsStandards: '',
          euTaxanomy: '',
          moreInfo: '',
          nonFinancials: '',
          closingTimes: '',
          processAutomation: '',
          skills: '',
        },
      ],
    },
    finCreditorsProcess: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      durationProcessingCreditors: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      incorrectOrders: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      suppliersEdi: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberOrders: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberOrderPositions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automatedOrderEntry: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      electrSentOrders: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberBills: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      differenceBillsOrders: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      billWithoutOrder: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      incorrectPaymentsCreditors: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      timeSpanBillincomingPayment: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finDebitorsProcess: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      durationDays: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShare: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShareCustomer: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberBills: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShareIncorrectBills: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberBillPosition: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      timeSpanUntilBill: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShareElecBills: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShareBillsReminder: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      incomingPayments: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      timeSpanUntilClosingBusinessCase: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      incorrectPaymentAssignment: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      receivablesTurnoverTime: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      outsourcedReceivables: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finFurtherDevelopmentFinance: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finTax: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportingUnit: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      taxDeclaration: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      taxDeclarationBalance: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    finTreasury: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      avgExternPaymentTransactions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      avgExternManualPayments: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      avgInternPayments: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      employeesPaymentTransactions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      financeTransactionsConcluded: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      inventoryTreasuryFinanceTransactions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automatedProcessingTreasuryTransactions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      employeesTreasuryFinanceTransactionsCompleted: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      employeesProcessingTreasuryFinanceTransactions: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      efficiency: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      liquidityPlanningHorizonMonths: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      liquidityPlanningFrequenceMonths: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      liquidityPlanningDurationDays: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberEmployeesLiquidityPlanning: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      accuracyLiquidityPlanning: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
  },
  status: 'idle',
  error: null,
};

/**
 * Fetches the finance evaluation data
 */
export const fetchBenchmarkingFinanceEvaluation = createAsyncThunk(
  'benchmarkingFinanceEvaluation/fetchBenchmarkingFinanceEvaluation',
  async ({ companyId, benchmarkingId }) => {
    const response = await api.get(
      `/api/evaluation/finance/benchmarking/${benchmarkingId}/company/${companyId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  },
);

/**
 * The slice of the benchmarking finance evaluation state
 */
export const benchmarkingFinanceEvaluationSlice = createSlice({
  name: 'benchmarkingFinanceEvaluation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBenchmarkingFinanceEvaluation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBenchmarkingFinanceEvaluation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBenchmarkingFinanceEvaluation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select the finance evaluation data of the current benchmarking
 */
export const selectBenchmarkingFinanceEvaluationData = (state) =>
  state.benchmarkingFinanceEvaluation.data;

/**
 * Select the status of the finance benchmarking evaluation state
 */
export const selectBenchmarkingFinanceEvaluationStatus = (state) =>
  state.benchmarkingFinanceEvaluation.status;

/**
 * Select the error messages of the finance benchmarking evaluation state
 */
export const selectBenchmarkingFinanceEvaluationError = (state) =>
  state.benchmarkingFinanceEvaluation.error;

export default benchmarkingFinanceEvaluationSlice.reducer;
