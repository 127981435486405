/**
 * This component renders numerical benchmarkings and KPIs in three columns representing the
 * the first quartile, the median and the second quartile of the benchmarking values and adds
 * a marker which displays the stored value of the own company
 */
import * as React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const quartileChartTheme = {
  axis: {
    ticks: {
      line: {
        stroke: 'black',
      },
      text: {
        fill: 'black',
      },
    },
  },
  grid: {
    line: {
      stroke: 'black',
      strokeWidth: 1,
    },
  },
};

/**
 * Calculates the maximum value of all nummerical values to dynamically display the size of the chart
 * @param {Array} dataArray Contains all numerical values that are relevant to render the chart
 * @returns Largest value with a added 10% to ensure that if the companyBenchmarkingValue is the highest
 * it gets still displayed beautiful
 */
const calculateMaxValue = (dataArray) => {
  return Math.max(...dataArray);
};

/**
 * Renders the quartils of numerical benchmarkings and KPIs
 * @param {string} legendTextLeft Determines the text for the y-axis (Name of the Benchmarking or KPI)
 * @param {strig} companyName Name of the company of the logged in user
 * @param {Array} quartileData Array with one object containing the quartile data
 * @param {Number} companyBenchmarkingValue Value the benchmarkinged company has entered in a category
 */
const QuartileChart = (props) => {
  const { t } = useTranslation();
  const { legendTextLeft, companyName, quartileData, companyBenchmarkingValue } = props;

  /**
   * Splits the one data object into separate data objects to allow the correct rendering of the
   * name of the columns inside the quartile chart
   * This function is additionally necessary to allow multiple languages within the chart so the key
   * properties of the objects will be taken from a language file
   * @param {Object} dataObject Containing the quartile values
   * {firstQuartile: Number, secondQuartile: Number, thirdQuartile: Number}
   * @returns {Array} dataArray Containing the quartile values and the corresponding keys
   */
  const generateData = (dataObject) => {
    const dataArray = [
      {
        key: t('global.quartileChartDescription.firstQuartile'),
        value0: dataObject.firstQuartile,
      },
      {
        key: t('global.quartileChartDescription.median'),
        value1: dataObject.secondQuartile,
      },
      {
        key: t('global.quartileChartDescription.thirdQuartile'),
        value2: dataObject.thirdQuartile,
      },
    ];
    return dataArray;
  };

  return quartileData ? (
    <Box w="500px" h="300px" mt={5}>
      <p>{legendTextLeft}</p>
      <ResponsiveBar
        theme={quartileChartTheme}
        data={generateData(quartileData[0])}
        keys={['value0', 'value1', 'value2']}
        indexBy="key"
        margin={{ top: 25, right: 100, bottom: 70, left: 70 }}
        padding={0.1}
        innerPadding={0}
        groupMode="stacked"
        maxValue={calculateMaxValue([...Object.values(quartileData[0]), companyBenchmarkingValue])}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        isInteractive={false}
        colors={['#008CC8', '#05415A', '#4A4A4A']}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: [companyBenchmarkingValue],
          legendPosition: 'middle',
          legendOffset: -45,
        }}
        gridYValues={[0, companyBenchmarkingValue]}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={['#fff']}
        // Represents the benchmarking value of the own company as horizontal line
        markers={[
          {
            axis: 'y',
            value: companyBenchmarkingValue,
            lineStyle: { stroke: 'rgba(20, 230, 215, 1)', strokeWidth: 3 },
            legend: companyName,
            legendOrientation: 'horizontal',
            itemWidth: 100,
            legendOffsetX: -100,
            legendOffsetY: 12,
            textStyle: { fill: 'rgba(20, 230, 215, 1)', fontWeight: 'bold' },
          },
        ]}
      />
    </Box>
  ) : null;
};

export default QuartileChart;
