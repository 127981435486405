/*
 * Renders the Verify User View
 */
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Center, HStack, Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TiDelete } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import ErrorModal from '../components/ErrorModal';
import InfoModal from '../components/InfoModal';
import Button from '../components/inputs/Button';
import api from '../utils/api';

const VerifyUser = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = match.params;

  const [statusToken, setStatusToken] = useState('');

  // States for opening the info modal
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  // States for opening the error modal
  const {
    isOpen: isErrorModalOpen,
    onOpen: onErrorModalOpen,
    onClose: onErrorModalClose,
  } = useDisclosure();

  /**
   * Checks authentication to retrieve CSRF-Token
   */
  const checkAuth = () => {
    api.get('api/user/me', { withCredentials: true });
  };

  /**
   * Verifies given token in url
   */
  const verifyToken = () => {
    checkAuth();

    // API-Request to get status of token for user (exist, does not exist, expire)
    api
      .put(
        `/auth/user/verify/${token}`,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          setStatusToken('VALID TOKEN');
        }
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 404') {
          setStatusToken('NO VALID TOKEN');
        } else if (err.message === 'Request failed with status code 422') {
          setStatusToken('EXPIRED TOKEN');
        } else {
          setStatusToken('NO VALID TOKEN');
        }
      });
  };

  useEffect(() => verifyToken(), []);

  /**
   * Handles closing of the info modal
   */
  const handleInfoModalClose = () => {
    onInfoModalClose();
    history.push('/login');
  };

  /**
   * Resends the verification mail
   */
  const resendVerificationMail = () => {
    api
      .put(
        `/auth/resend-verification/${token}`,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          onInfoModalOpen();
        }
      })
      .catch(() => {
        onErrorModalOpen();
      });
  };

  const displayVerificationFeedback = () => {
    if (statusToken === 'VALID TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box paddingTop={10}>
              <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
                <Center>
                  <HStack>
                    <CheckCircleIcon color="green" boxSize={25} />
                    <Heading>{t('verifyUser.success.heading')}</Heading>
                  </HStack>
                </Center>
                <br />
                <Center>
                  <Heading size="md">{t('verifyUser.success.contentHeading')}</Heading>
                </Center>
                <Center>
                  <Box pt={2}>
                    <Text>{t('verifyUser.success.contentBody1')}</Text>
                  </Box>
                </Center>
                <Center>
                  <Box pt={5}>
                    <Text>{t('verifyUser.success.contentBody2')}</Text>
                  </Box>
                </Center>
                <br />
                <Center>
                  <Button
                    text={t('verifyUser.success.loginButton')}
                    action={() => history.push('/login')}
                  />
                </Center>
              </Box>
            </Box>
          </Stack>
        </div>
      );

    if (statusToken === 'NO VALID TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box paddingTop={10}>
              <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
                <Center>
                  <HStack>
                    <TiDelete color="red" size={35} />
                    <Heading>{t('verifyUser.noValidToken.heading')}</Heading>
                  </HStack>
                </Center>
                <Center>
                  <Box pt={2}>
                    <Text>{t('verifyUser.noValidToken.content')}</Text>
                  </Box>
                </Center>
              </Box>
            </Box>
          </Stack>
        </div>
      );

    if (statusToken === 'EXPIRED TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box paddingTop={10}>
              <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
                <Center>
                  <HStack>
                    <TiDelete color="red" size={35} />
                    <Heading>{t('verifyUser.expiredToken.heading')}</Heading>
                  </HStack>
                </Center>
                <Center>
                  <Box pt={2}>
                    <Text>{t('verifyUser.expiredToken.contentBody1')}</Text>
                  </Box>
                </Center>
                <Center>
                  <Text>{t('verifyUser.expiredToken.contentBody2')}</Text>
                </Center>
                <br />
                <Center>
                  <Button
                    text={t('verifyUser.expiredToken.resendTokenButton')}
                    action={() => resendVerificationMail()}
                  />
                </Center>
              </Box>
            </Box>
          </Stack>
          <InfoModal
            title={t('verifyUser.expiredToken.infoModal.title')}
            infoText={t('verifyUser.expiredToken.infoModal.infoText')}
            isOpen={isInfoModalOpen}
            onClose={handleInfoModalClose}
          />
          <ErrorModal
            title={t('errors.verifyUser.expiredToken.errorModal.title')}
            infoText={t('errors.verifyUser.expiredToken.errorModal.infoText')}
            isOpen={isErrorModalOpen}
            onClose={onErrorModalClose}
          />
        </div>
      );
    return null;
  };

  return <div>{displayVerificationFeedback()}</div>;
};

export default VerifyUser;
