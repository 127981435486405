/*
 * Renders the stat group component
 */
import * as React from 'react';
import { Heading, SimpleGrid } from '@chakra-ui/react';

/**
 * Displays a framework for arranging Stats
 * @returns A heading and a simple grid
 */
const StatGroup = (props) => {
  const { title, children } = props;

  return (
    <>
      <Heading as="h2" size="lg">
        {title}
      </Heading>
      <br />
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
        }}
        spacing="6"
      >
        {children}
      </SimpleGrid>
    </>
  );
};

export default StatGroup;
