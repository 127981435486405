/*
 * Slice which provides reducer and states of the admin company state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

/**
 * Fetch all Companies
 */
export const fetchAllCompanies = createAsyncThunk('adminCompany/fetchAllCompanies', async () => {
  const response = await api.get('/api/company', {
    withCredentials: true,
  });
  return response.data;
});

/**
 *  Get Company by id
 */
export const fetchCompanyById = createAsyncThunk(
  'adminCompany/fetchCompanyById',
  async (companyId) => {
    const response = await api.get(`/api/company/${companyId}`, {
      withCredentials: true,
    });
    return response.data;
  },
);

/**
 * Create Company
 */
export const createCompany = createAsyncThunk('adminCompany/createCompany', async (company) => {
  const response = await api.post('/api/company', company, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Edit Company
 */
export const editCompany = createAsyncThunk('adminCompany/editCompany', async (company) => {
  const response = await api.put(`/api/company/${company.id}`, company, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Delete Company
 */
export const deleteCompany = createAsyncThunk('adminCompany/deleteCompany', async (companyId) => {
  const response = await api.delete(`/api/company/${companyId}`, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * The slice of the company state
 */
export const adminCompanySlice = createSlice({
  name: 'adminCompany',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Insert fetched company into data
        state.data = action.payload;
      })
      .addCase(fetchAllCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createCompany.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editCompany.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteCompany.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select all companies
 */
export const selectAllCompanies = (state) => state.adminCompany.data;

/**
 * Select error of admin company
 */
export const selectAdminCompanyError = (state) => state.adminCompany.error;

/**
 * Select status of admin company
 */
export const selectAdminCompanyStatus = (state) => state.adminCompany.status;

export default adminCompanySlice.reducer;
