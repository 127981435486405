/*
 * Renders the card header component
 */
import { QuestionIcon } from '@chakra-ui/icons';
import { Flex, Center, Heading, Tooltip, Box } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Displays the Card Header
 * @returns A flex box with heading and action
 */
const CardHeader = (props) => {
  const { title, action, tooltipText, height } = props;
  return (
    <Flex
      align="center"
      bg="horvath.grey-light"
      justify="space-between"
      px="6"
      py="4"
      borderBottomWidth="1px"
    >
      <Center>
        <Heading fontSize="lg">{title}</Heading>
        <Box sx={{ pointerEvents: 'all' }} height={height}>
          {tooltipText ? (
            <Tooltip
              bg="horvath.blue-light"
              p={3}
              hasArrow="true"
              placement="right"
              label={tooltipText}
            >
              <QuestionIcon color="horvath.blue-light" ml={1} data-testid={`Tooltip: ${title}`} />
            </Tooltip>
          ) : null}
        </Box>
      </Center>
      {action}
    </Flex>
  );
};

export default CardHeader;
