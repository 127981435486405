/**
 * Renders disable component
 */
import * as React from 'react';
import { Box } from '@chakra-ui/react';

/**
 * Makes wrapped components disabled
 * @returns Box with disabled children
 */
const Disable = (props) => {
  const { children } = props;

  return (
    <Box sx={{ cursor: 'not-allowed' }}>
      <Box
        sx={{
          opacity: '0.3',
          pointerEvents: 'none',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Disable;
