/**
 * Provides functions for manipulating values
 */
const valueManipulation = {
  /**
   * Parses the numeric value to include whitespaces after 3 digits
   * @param String val The numeric value
   * @returns A parsed value which includes whitespaces
   */
  addWhitespaces: (val) => {
    return val ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
  },

  /**
   * Removes whitespaces from a value
   * @param String val Value with whitespaces
   * @returns A whitespce less value
   */
  removeWhitespaces: (val) => {
    return val.replace(' ', '');
  },

  /**
   * Removes non numeric characters from a string
   * @param String val A value with possible non numeric characters
   * @param String type The type the string should be
   * @returns A string only consisting of numeric characters
   */
  removeNonNumeric: (val, type) => {
    if (type === 'long') {
      return val ? val.toString().replace(/[^0-9]/g, '') : '';
    }
    return val ? val.toString().replace(/[^.0-9]/g, '') : '';
  },
};

export default valueManipulation;
