/**
 * Renders clickable component
 */
import * as React from 'react';
import { Box } from '@chakra-ui/react';

/**
 * Makes wrapped components clickable
 * @returns Box with clickable children
 */
const Clickable = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default Clickable;
