/**
 * This component renders the log in view containing two input fields which
 * require the email address and the password of the user
 */
import {
  Box,
  Center,
  Divider,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ErrorModal from '../components/ErrorModal';
import InfoModal from '../components/InfoModal';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import api from '../utils/api';
import validateEmail from '../utils/validation';

const Register = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();

  const [salutation, setSalutation] = React.useState(null);
  const [salutationError, setSalutationError] = React.useState(false);
  const [academicTitle, setAcademicTitle] = React.useState(null);
  const [firstName, setFirstName] = React.useState('');
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameError, setLastNameError] = React.useState(false);
  const [position, setPosition] = React.useState('');
  const [department, setDepartment] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [repeatEmail, setRepeatEmail] = React.useState('');
  const [repeatEmailError, setRepeatEmailError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [repeatPasswordError, setRepeatPasswordError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const [registerCallFinished, setRegisterCallFinished] = React.useState(false);

  const salutationOptions = [
    {
      name: t('global.appelationOptions.mr'),
      value: 'HERR',
    },
    {
      name: t('global.appelationOptions.mrs'),
      value: 'FRAU',
    },
  ];

  const academicTitleOptions = [
    {
      name: '-',
      value: '-',
    },
    {
      name: t('global.academicTitleOptions.bachelor'),
      value: 'BSC',
    },
    {
      name: t('global.academicTitleOptions.master'),
      value: 'MS',
    },
    {
      name: t('global.academicTitleOptions.diplom'),
      value: 'DIPLOM',
    },
    {
      name: t('global.academicTitleOptions.doctor'),
      value: 'DR',
    },
    {
      name: t('global.academicTitleOptions.professor'),
      value: 'PROF',
    },
  ];

  // States for opening the info modal
  const {
    isOpen: isInfoModalOpen,
    onOpen: onInfoModalOpen,
    onClose: onInfoModalClose,
  } = useDisclosure();

  // States for opening the error modal
  const {
    isOpen: isErrorModalOpen,
    onOpen: onErrorModalOpen,
    onClose: onErrorModalClose,
  } = useDisclosure();

  /**
   * Closes info modal and stay at registration view
   */
  const handleErrorModalClose = () => {
    onErrorModalClose();
    history.push('/login');
  };

  /**
   * Closes info modal and reroutes the user to login
   */
  const handleInfoModalClose = () => {
    onInfoModalClose();
    history.push('/login');
  };

  /**
   * Tries to login the user with the given credentials
   */
  const register = () => {
    if (salutation === null) {
      setSalutationError(true);
      setErrorMsg(t('errors.registration.salutation'));
      return;
    }
    setSalutationError(false);
    setErrorMsg('');

    if (firstName === '') {
      setFirstNameError(true);
      setErrorMsg(t('errors.registration.firstName'));
      return;
    }
    setFirstNameError(false);
    setErrorMsg('');

    if (lastName === '') {
      setLastNameError(true);
      setErrorMsg(t('errors.registration.lastName'));
      return;
    }
    setLastNameError(false);
    setErrorMsg('');

    if (!validateEmail(email)) {
      setEmailError(true);
      setErrorMsg(t('errors.registration.invalidEmail'));
      return;
    }
    setEmailError(false);
    setErrorMsg('');

    if (email !== repeatEmail) {
      setRepeatEmailError(true);
      setErrorMsg(t('errors.registration.emailsNotMatch'));
      return;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');

    if (password.length < 8) {
      setPasswordError(true);
      setErrorMsg(t('errors.registration.invalidPassword'));
      return;
    }
    setPasswordError(false);
    setErrorMsg('');

    if (password !== repeatPassword) {
      setRepeatPasswordError(true);
      setErrorMsg(t('errors.registration.passwordsNotMatch'));
      return;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');

    onInfoModalOpen();
    api
      .post(
        '/auth/register',
        {
          email,
          password,
          salutation: salutation.toUpperCase(),
          academicTitle: academicTitle === '-' ? null : academicTitle,
          firstName,
          lastName,
          position,
          department,
        },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          if (isInfoModalOpen) {
            setRegisterCallFinished(true);
          } else {
            setRegisterCallFinished(true);
            onInfoModalOpen();
          }
        }
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 409') {
          toast({
            title: t('errors.registration.409.title'),
            description: '',
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        } else {
          onErrorModalOpen();
        }
      });
  };

  return (
    <div>
      <Stack marginTop={20} direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
        <Stack spacing={4}>
          <Center>
            <VStack>
              <Heading>{t('registration.heading')}</Heading>
            </VStack>
          </Center>
          <Center>
            <Stack
              w={[400, 400, 800]}
              spacing={3}
              p="1rem"
              backgroundColor="horvath.grey-light"
              boxShadow="md"
            >
              <FormControl>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.salutation')}
                    formType="selectNameValue"
                    value={salutation}
                    onValueChange={setSalutation}
                    colorEvenItems
                    testName="Anrede"
                    options={salutationOptions}
                    invalid={salutationError && salutation === null}
                  />
                  <FormProperty
                    label={t('registration.academicTitle')}
                    formType="selectNameValue"
                    value={academicTitle}
                    onValueChange={setAcademicTitle}
                    colorEvenItems
                    testName="Akad. Titel"
                    options={academicTitleOptions}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.firstName')}
                    formType="input"
                    value={firstName}
                    onValueChange={setFirstName}
                    required
                    colorEvenItems
                    testName="Vorname"
                    invalid={firstNameError && firstName === ''}
                  />
                  <FormProperty
                    label={t('registration.lastName')}
                    formType="input"
                    value={lastName}
                    onValueChange={setLastName}
                    colorEvenItems
                    testName="Nachname"
                    invalid={lastNameError && lastName === ''}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.position')}
                    formType="input"
                    value={position}
                    onValueChange={setPosition}
                    colorEvenItems
                    testName="Position"
                  />
                  <FormProperty
                    label={t('registration.department')}
                    formType="input"
                    value={department}
                    onValueChange={setDepartment}
                    colorEvenItems
                    testName="Abteilung"
                  />
                </SimpleGrid>
                <Divider />
                <Box>
                  <Text fontSize="md" padding={4} pl={7} pr={7} pb={0}>
                    {t('registration.headingRegisterWithEmail')}
                  </Text>
                </Box>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.email')}
                    formType="input"
                    value={email}
                    onValueChange={setEmail}
                    testName="Email"
                    invalid={emailError && !validateEmail(email)}
                  />
                  <FormProperty
                    label={t('registration.repeatEmail')}
                    formType="input"
                    value={repeatEmail}
                    onValueChange={setRepeatEmail}
                    testName="Email wiederholen"
                    invalid={repeatEmailError && email !== repeatEmail}
                  />
                </SimpleGrid>
                <SimpleGrid minChildWidth="200px">
                  <FormProperty
                    label={t('registration.password')}
                    formType="password-input"
                    value={password}
                    onValueChange={setPassword}
                    testName="Passwort"
                    invalid={passwordError && password.length < 8}
                  />
                  <FormProperty
                    label={t('registration.repeatPassword')}
                    formType="password-input"
                    value={repeatPassword}
                    onValueChange={setRepeatPassword}
                    testName="Passwort wiederholen"
                    invalid={repeatPasswordError && password !== repeatPassword}
                  />
                </SimpleGrid>
              </FormControl>
              <Center color="red">{errorMsg}</Center>
              <SimpleGrid columns={1} spacing={4}>
                <Button
                  text={t('registration.registrationButton')}
                  color="primary"
                  action={register}
                  testId="Registrierung Button"
                />
              </SimpleGrid>
            </Stack>
          </Center>
        </Stack>
      </Stack>
      <InfoModal
        title={t('registration.infoModal.title')}
        infoText={t('registration.infoModal.infoText')}
        isOpen={isInfoModalOpen}
        onClose={handleInfoModalClose}
        isPromise
        onClosePromise={onInfoModalClose}
        promiseTitle={t('registration.infoModal.promiseTitle')}
        promiseText={t('registration.infoModal.promiseText')}
        isPromiseResolved={registerCallFinished}
      />
      <ErrorModal
        title={t('errors.registration.errorModal.title')}
        infoText={t('errors.registration.errorModal.infoText')}
        isOpen={isErrorModalOpen}
        onClose={handleErrorModalClose}
      />
    </div>
  );
};

export default Register;
