/*
 * Renders the submit button component
 */
import * as React from 'react';
import '../../../css/button.scss';

/**
 * Displays a submit button in horváth ci with an icon
 * @returns A button with an icon
 */
const SubmitButton = (props) => {
  const { text, color, iconType, style, testId, disabled } = props;
  const renderIcon = () => {
    switch (iconType) {
      case 'submit': {
        return (
          <svg height="16" viewBox="0 0 27 16" width="27" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m57.9863666 25.7049089h-22.9863666v-1.7826367h22.6135477l-4.4012433-4.4012433v-2.5210289l8 8-8 8v-2.5210289z"
              fill="#000"
              transform="translate(-35 -17)"
            />
          </svg>
        );
      }
      default: {
        return (
          <svg height="16" viewBox="0 0 27 16" width="27" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m57.9863666 25.7049089h-22.9863666v-1.7826367h22.6135477l-4.4012433-4.4012433v-2.5210289l8 8-8 8v-2.5210289z"
              fill="#000"
              transform="translate(-35 -17)"
            />
          </svg>
        );
      }
    }
  };
  return color === 'primary' ? (
    <button
      type="submit"
      className={
        disabled
          ? 'component-button component-button--primary disabled'
          : 'component-button component-button--primary color-blue-dark'
      }
      style={style}
      disabled={disabled}
      data-testid={testId}
    >
      <div className="icon">{renderIcon()}</div>
      <div className="input">{text}</div>
    </button>
  ) : (
    <button
      type="submit"
      className="component-button component-button--secondary {{modifier-component}}"
      style={style}
      disabled={disabled}
      data-testid={testId}
    >
      <div className="icon">{renderIcon()}</div>
      <div className="input">{text}</div>
    </button>
  );
};

export default SubmitButton;
