/*
 * Slice which provides reducer and states of the company state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: {
    id: null,
    name: '',
    shortName: '',
    countryCode: '',
    industryLevel1: '',
    industryLevel2: '',
    industryLevel3: '',
  },
  status: 'idle',
  error: null,
};

/**
 * Fetches the company
 */
export const fetchCompany = createAsyncThunk('company/fetchCompany', async () => {
  const response = await api.get('/api/company/me', {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Edits the company
 */
export const editCompany = createAsyncThunk('company/editCompany', async (editedCompany) => {
  return editedCompany;
});

/**
 * The slice of the company state
 */
export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCompany.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Insert fetched company into data
        state.data = action.payload;
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Directly insert edited company object into data
        state.data = action.payload;
      })
      .addCase(editCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select a company
 */
export const selectCompany = (state) => state.company.data;

/**
 * Select the name of a company
 */
export const selectCompanyName = (state) => state.company.data.shortName;

/**
 * Select the id of a company
 */
export const selectCompanyId = (state) => state.company.data.id;

/**
 * Select the status of the company state
 */
export const selectCompanyStatus = (state) => state.company.status;

/**
 * Select the error messages of the company state
 */
export const selectCompanyError = (state) => state.company.error;

export default companySlice.reducer;
