/*
 * Slice which provides reducer and states of the controlling benchmarking evaluation state,
 * which holds the cumulated data in controlling
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: {
    totalProcess: {
      totalFte: [
        {
          firstQuartile: 0,
          secondQuartile: 0,
          thirdQuartile: 0,
        },
      ],
      totalCost: [
        {
          firstQuartile: 0,
          secondQuartile: 0,
          thirdQuartile: 0,
        },
      ],
    },
    ctrlBusinessConsultingManagement: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlCorporate: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      leadershipClaim: [
        {
          kind: 'selection',
          financialLeadership: '',
          strategicLeadership: '',
          operationalLeadership: '',
          controllingRelevanceKeyFigure: '',
          keyFigureLogicAllHierarchyLevels: '',
          keyFigureCompanyLevel: '',
        },
      ],
    },
    ctrlCpra: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      varianceCtrAcc: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      forecastQuality: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planQuality: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      deviation: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlFurtherDevelopmentControlling: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlForecast: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      levelDetailMonth: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      levelDetailWeek: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      levelDetailQuarter: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      levelDetailOthers: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      accuracy: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      forecastHorizonMonths: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      typeForecast: [
        {
          kind: 'selection',
          yearEnd: '',
          rolling: '',
          partRolling: '',
        },
      ],
    },
    ctrlIt: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      structureIt: [
        {
          kind: 'selection',
          costCenter: '',
          profitCenter: '',
          serviceCenter: '',
          others: '',
        },
      ],
    },
    ctrlManagementReporting: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      amountReportsExecutiveBoard: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      amountReportsSupervisoryBoard: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      adHocReports: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      standardizedReports: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      kpisAmount: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automationPotentialDataManagement: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automationPotentialReportGeneration: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automationPotentialAnalysis: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      automationPotentialDecisionSupport: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      formOfUsePush: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      formOfUseStandard: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      formOfUseSelfService: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportShares: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      degreeDigitization: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportingClosing: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportingFlash: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportingMonthlyUncommented: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      reportingMonthlyCommented: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      integrationComment: [
        {
          kind: 'selection',
          manualComplete: '',
          manual: '',
          semiAutomatedFrontend: '',
          semiAutomatedBackend: '',
          automatedAi: '',
        },
      ],
      integrationAiMl: [
        {
          kind: 'selection',
          full: '',
          selective: '',
          exclusive: '',
        },
      ],
      integrationSustainability: [
        {
          kind: 'selection',
          notExist: '',
          separate: '',
          oneSided: '',
          full: '',
        },
      ],
      reportingDimensions: [
        {
          kind: 'selection',
          finance: '',
          financeNot: '',
          extern: '',
        },
      ],
      reportingProcess: [
        {
          kind: 'selection',
          manual: '',
          automatedDifReports: '',
          automatedIntegratedDatamodel: '',
        },
      ],
    },
    ctrlOperativePlanning: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      durationWeeks: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planningPreparation: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      targetSetting: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      decentralizedPlanning: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      consolidationCompletionOfPlanning: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planShiftApproval: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      votingRounds: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planningHorizonMonths: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planningAlignment: [
        {
          kind: 'selection',
          topDown: '',
          bottomUp: '',
          mixed: '',
        },
      ],
      typeOperativePlanning: [
        {
          kind: 'selection',
          mediumTermPlanning: '',
          annualPlanning: '',
          annualPlanningSeasonalization: '',
          annualPlanningYtef: '',
          annualPlanningRollingForecasts: '',
          rollingPlanning: '',
        },
      ],
    },
    ctrlParticipation: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlPic: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      investmentLimit: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      durationRequest: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      statusProjectsSuccessful: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      statusProjectsAborted: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      statusProjectsStopped: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      deviationProjectCosts: [
        {
          kind: 'selection',
          bad: '',
          standard: '',
          good: '',
        },
      ],
      deviationProjectTime: [
        {
          kind: 'selection',
          bad: '',
          standard: '',
          good: '',
        },
      ],
      deviationProjectPerformance: [
        {
          kind: 'selection',
          bad: '',
          standard: '',
          good: '',
        },
      ],
    },
    ctrlResearchDevelopment: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShare: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      resourcesEffort: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      projectBasicResearch: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      projectNewProductDev: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      projectFurtherDev: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlSales: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      percentageShare: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlStrategicPlanning: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      planningHorizonMonths: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      durationWeeks: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      votingRounds: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      numberParticipants: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
    },
    ctrlProduction: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      kpiKeyData: {
        deliveryPunctuality: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        idleTimeMachine: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        plantUtilizationRate: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        utilizationRate: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        manufacturingQuality: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        postProcessingRate: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        scrapRate: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        damageFrequency: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        stockBearingManufacturing: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        setupTimes: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        employment: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },
    },
    ctrlRiskManagement: {
      fte: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      cost: [
        {
          firstQuartile: '',
          secondQuartile: '',
          thirdQuartile: '',
        },
      ],
      controllingRelevance: [
        {
          kind: 'selection',
          indepControlInstance: '',
          indepMediator: '',
          realBusinessPartner: '',
        },
      ],
      frequenceRiskManagement: [
        {
          kind: 'selection',
          adHoc: '',
          daily: '',
          weekly: '',
          monthly: '',
          quarterly: '',
          halfYear: '',
          yearly: '',
          never: '',
        },
      ],
      measures: [
        {
          kind: 'selection',
          regularCentralRiskManagementFunc: '',
          rotational: '',
          regularRevisionFunc: '',
          noActiveMonitoring: '',
        },
      ],
      responsibilityRiskManagement: [
        {
          kind: 'selection',
          controlling: '',
          staffUnit: '',
          corporateManagement: '',
          intern: '',
          decentralizedEmployees: '',
        },
      ],
      typeRiskManagement: [
        {
          kind: 'selection',
          formalized: '',
          institutionalized: '',
        },
      ],
      kpiAnalysisRiskBearingCapacity: {
        noConcept: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        equityCoverage: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        liquidityReserve: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        other: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },

      kpiGoalRiskManagement: {
        fulfilledRequirement: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        improvedControllingRisks: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        companyControlling: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },

      kpiIntegrationRiskManagement: {
        strategicPlanning: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        operativePlanning: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        budgeting: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        strategicDecisionSupport: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        operativeDecisionSupport: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        noInterfaces: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },
      kpiScenarioAnalysis: {
        currentRiskDev: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        individualSingleRisks: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        topRisks: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        completeRiskPortfolio: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        strategicDecisionOptions: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },
      kpiSoftwareSupport: {
        riskRecordingRating: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        reporting: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        riskDashboards: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        simulationModeling: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
        noSoftwareSupport: [
          {
            kind: 'selection',
            percentageChecked: '',
            percentageNotChecked: '',
          },
        ],
      },
    },
  },
  status: 'idle',
  error: null,
};

/**
 * Fetches a controlling evaluation data
 */
export const fetchBenchmarkingControllingEvaluation = createAsyncThunk(
  'benchmarkingControllingEvaluation/fetchBenchmarkingControllingEvaluation',
  async ({ companyId, benchmarkingId }) => {
    const response = await api.get(
      `/api/evaluation/controlling/benchmarking/${benchmarkingId}/company/${companyId}`,
      {
        withCredentials: true,
      },
    );
    return response.data;
  },
);

/**
 * The slice of the benchmarking controlling evaluation state
 */
export const benchmarkingControllingEvaluationSlice = createSlice({
  name: 'benchmarkingControllingEvaluation',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBenchmarkingControllingEvaluation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBenchmarkingControllingEvaluation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBenchmarkingControllingEvaluation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select the controlling evaluation data of the current benchmarking
 */
export const selectBenchmarkingControllingEvaluationData = (state) =>
  state.benchmarkingControllingEvaluation.data;

/**
 * Select the status of the controlling benchmarking evaluation state
 */
export const selectBenchmarkingControllingEvaluationStatus = (state) =>
  state.benchmarkingControllingEvaluation.status;

/**
 * Select the error messages of the controlling benchmarking evaluation state
 */
export const selectBenchmarkingControllingEvaluationError = (state) =>
  state.benchmarkingControllingEvaluation.error;

export default benchmarkingControllingEvaluationSlice.reducer;
