/*
 * Slice which provides reducer and states of the admin company state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

/**
 * Fetch all Users
 */
export const fetchAllUsers = createAsyncThunk('adminUser/fetchAllUsers', async () => {
  const response = await api.get('/api/user', {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Fetch a certain User
 */
export const fetchCertainUser = createAsyncThunk('adminUser/fetchCertainUser', async (userId) => {
  const response = await api.get(`/api/user/${userId}`, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Add User
 */
export const addUser = createAsyncThunk('adminUser/addUser', async (user) => {
  const response = await api.post('/auth/register', user, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Edit User
 */
export const editUser = createAsyncThunk('adminUser/editUser', async (user) => {
  const response = await api.put(`/api/user/${user.id}`, user, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * Edit Role of certain user
 */
export const editRoleOfCertainUser = createAsyncThunk(
  'adminUser/editRoleOfCertainUser',
  async (user) => {
    const response = await api.put(`/api/user/${user.id}/role/${user.role}`, user, {
      withCredentials: true,
    });
    return response.data;
  },
);

/**
 * Delete User
 */
export const deleteUser = createAsyncThunk('adminUser/deleteUser', async (user) => {
  const response = await api.delete(`/api/user/${user.id}`, {
    withCredentials: true,
  });
  return response.data;
});

/**
 * The slice of the user state
 */
export const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Insert fetched company into data
        state.data = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select all users
 */
export const selectAllUsers = (state) => state.adminUser.data;

/**
 * Select error of admin users
 */
export const selectAdminUserError = (state) => state.adminUser.error;

/**
 * Select status of admin users
 */
export const selectAdminUserStatus = (state) => state.adminUser.status;

export default adminUserSlice.reducer;
