/*
 * Renders the not found view
 */
import * as React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a 404 error message
 * @returns A heading with 404 error message
 */
const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t('notFound.content')}</h1>
    </div>
  );
};

export default NotFound;
