/**
 * This component renders the log in view containing two input fields which
 * require the email address and the password of the user
 */
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HorvathImage from '../../assets/img/Horvath_CFO-Panel_Logo_pos_RGB.svg';
import KeyForPassword from '../../assets/img/password-svgrepo-com.svg';
import PersonForEmail from '../../assets/img/person-svgrepo-com.svg';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import SubmitButton from '../components/inputs/SubmitButton';
import { login as loginRequest } from '../slices/authSlice';
import API from '../utils/api';
import Dialog from '../components/Dialog';

const Login = () => {
  const { t } = useTranslation();
  const [emailByUser, setEmailByUser] = useState('');
  const [passwordByUser, setPasswordByUser] = useState('');

  const [failedLogin, setFailedLogin] = useState(false);
  const [showUserNotVerifiedInfo, setShowUserNotVerifiedInfo] = useState(false);
  const [showResendVerificationMailModal, setShowResendVerificationMailModal] = useState(false);
  const [emailToResendVerificationMail, setEmailToResendVerificationMail] = useState('');
  const [isSendingDone, setIsSendingDone] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();

  const emailInputByUser = (value) => {
    setEmailByUser(value);
  };

  const passwordInputByUser = (value) => {
    setPasswordByUser(value);
  };

  /**
   * Tries to login the user with the given credentials
   */
  const login = async () => {
    try {
      await dispatch(
        loginRequest({
          email: emailByUser,
          password: passwordByUser,
        }),
      ).unwrap();
    } catch (error) {
      setFailedLogin(true);
      if (error.status === 403) {
        if (error.data.errorKey === 'USER_IS_NOT_VERIFIED') {
          setShowUserNotVerifiedInfo(true);
        } else {
          toast({
            title: t('errors.login.403.title'),
            description: t('errors.login.403.description'),
            status: 'error',
            duration: 9000,
            position: 'top-right',
            isClosable: true,
          });
        }
      }
      if (error.status === 401) {
        setShowUserNotVerifiedInfo(false);
        toast({
          title: t('errors.login.401.title'),
          description: t('errors.login.401.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
    setPasswordByUser('');
  };

  const onCloseResendVerificationMailModal = () => {
    setShowResendVerificationMailModal(false);
  };

  /**
   * Resends the verification mail to the user
   */
  const resendVerificationMail = async () => {
    setIsSendingDone(false);
    API.put('/auth/resend-verification', { email: emailByUser }, { withCredentials: true })
      .then(() => {
        setIsSendingDone(true);
        setShowResendVerificationMailModal(false);
        toast({
          title: t('login.resendVerificationMailSuccess.title'),
          description: t('login.resendVerificationMailSuccess.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        setIsSendingDone(true);
        toast({
          title: t('errors.login.resendVerificationMail.title'),
          description: t('errors.login.resendVerificationMail.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  return (
    <div>
      <Stack
        marginTop={20}
        spacing={5}
        direction={{ base: 'column', xl: 'row' }}
        justifyContent="space-evenly"
      >
        <Box>
          <Stack spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
            <Center>
              <VStack>
                <Image
                  htmlWidth="450px"
                  objectFit="cover"
                  src={HorvathImage}
                  data-testid="home-btn"
                />
                <Heading>{t('login.title')}</Heading>
              </VStack>
            </Center>
            <FormControl onSubmit={handleSubmit}>
              <Grid templateRows="repeat(1, 0fr)" templateColumns="repeat(15, 1fr)">
                <GridItem colSpan={1}>
                  <Box py="32px">
                    <Image htmlHeight="30px" htmlWidth="30px" src={PersonForEmail} />
                  </Box>
                </GridItem>
                <GridItem colSpan={14}>
                  <FormProperty
                    label={t('login.email')}
                    formType="input"
                    value={emailByUser}
                    onValueChange={emailInputByUser}
                    testName="Email Benutzer"
                    invalid={failedLogin}
                  />
                </GridItem>
              </Grid>
              <Grid templateRows="repeat(1, 0fr)" templateColumns="repeat(15, 1fr)">
                <GridItem colSpan={1}>
                  <Box py="32px">
                    <Image htmlHeight="30px" htmlWidth="30px" src={KeyForPassword} />
                  </Box>
                </GridItem>
                <GridItem colSpan={14}>
                  <FormProperty
                    label={t('login.password')}
                    formType="password-input"
                    value={passwordByUser}
                    onValueChange={passwordInputByUser}
                    testName="Passwort Benutzer"
                    invalid={failedLogin}
                  />
                </GridItem>
              </Grid>
              <Box
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                pr={7}
                textAlign="right"
                onClick={() => history.push('/resetpassword')}
              >
                <Text>{t('login.resetPassword')}</Text>
              </Box>
              <br />
              {showUserNotVerifiedInfo ? (
                <SimpleGrid columns={1}>
                  <Box width="auto" maxW="400px" mx="auto">
                    <Text as="p" d="inline-block" maxWidth="100%" align="center">
                      {t('login.userNotVerifiedInfo')}
                    </Text>
                  </Box>
                  <Center my={2}>
                    <Button
                      text={t('login.resendVerificationMail')}
                      action={() => setShowResendVerificationMailModal(true)}
                      testId="Resend Verification Mail Button"
                    />
                  </Center>
                </SimpleGrid>
              ) : null}
              <SimpleGrid columns={2} spacing={4}>
                <Button
                  text={t('login.registerButton')}
                  color="primary"
                  action={() => history.push('/registration')}
                  testId="Registrierung Button"
                />
                <SubmitButton
                  text={t('login.loginButton')}
                  iconType="submit"
                  color="primary"
                  testId="Login Button"
                />
              </SimpleGrid>
            </FormControl>
          </Stack>
        </Box>
      </Stack>
      <Dialog
        title={t('login.resendVerificationMailModal.title')}
        secondaryActionName={t('login.resendVerificationMailModal.resendVerificationMailButton')}
        secondaryAction={resendVerificationMail}
        secondaryActionDisabled={emailToResendVerificationMail === ''}
        isOpen={showResendVerificationMailModal}
        onClose={onCloseResendVerificationMailModal}
        isPromise
        isPromiseResolved={isSendingDone}
      >
        <FormControl>
          <FormProperty
            label={t('login.resendVerificationMailModal.email')}
            formType="input"
            invalid={emailToResendVerificationMail === ''}
            value={emailToResendVerificationMail}
            onValueChange={setEmailToResendVerificationMail}
            colorEvenItems
          />
        </FormControl>
      </Dialog>
    </div>
  );
};

export default Login;
