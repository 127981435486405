/* eslint-disable jsx-a11y/label-has-associated-control */
/*
 * Renders the form property component
 */
import * as React from 'react';
import { Box, Flex, useColorModeValue, Image, Tooltip, Center } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import valueManipulation from '../../utils/valueManipulation';
import eyePassword from '../../../assets/img/eye-view-interface-symbol-svgrepo-com.svg';
import '../../../css/form.scss';
import '../../../css/button.scss';

/**
 * Displays the form property component
 * @returns A flex box with label and input
 */
const FormProperty = (props) => {
  const {
    label,
    tooltipText,
    value,
    formType,
    colorEvenItems,
    disabled,
    testName,
    onValueChange,
    required,
    options,
    onClick,
    invalid,
    placeholder,
    ...flexProps
  } = props;

  const [isPasswordShow, setIsPasswordShow] = React.useState(false);

  const toggleIsPasswordShowValue = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  /**
   * Handles the number change of a number input by removing currency symbols and whitespaces
   * @param String numInput The inputed number
   * @param String type The type the inputed number should have
   * @returns The number without whitespaces
   */
  const handleNumberChange = (numInput, type) => {
    if (type === 'long') {
      if (!(numInput.toString().split('.').length - 1 > 0)) {
        const number = valueManipulation.removeWhitespaces(
          valueManipulation.removeNonNumeric(numInput, 'long'),
        );
        onValueChange(number);
        return;
      }
    }
    // Only change the value of the number input if there is no more than one comma
    if (!(numInput.toString().split('.').length - 1 > 1)) {
      const number = valueManipulation.removeWhitespaces(
        valueManipulation.removeNonNumeric(numInput),
      );
      onValueChange(number);
    }
  };

  /**
   * Renders a form element
   * @returns A form element
   */
  const renderFormType = () => {
    switch (formType) {
      case 'input': {
        return (
          <div className={disabled ? 'form-element disabled' : 'form-element'}>
            <label htmlFor={`Input: ${testName}`}>
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
              <input
                className={invalid ? 'invalid' : null}
                value={value}
                onChange={onValueChange ? (e) => onValueChange(e.target.value) : null}
                data-testid={`Input: ${testName}`}
                id={`Input: ${testName}`}
                name={label}
                type="text"
                autoCorrect="off"
                autoComplete={label}
                placeholder={label}
                disabled={disabled}
                onClick={onClick}
              />
            </label>
          </div>
        );
      }
      case 'number-input': {
        return (
          <div className={disabled ? 'form-element disabled' : 'form-element'}>
            <label htmlFor={`Input: ${testName}`}>
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
              <input
                className={invalid ? 'invalid' : null}
                value={valueManipulation.addWhitespaces(value)}
                onChange={onValueChange ? (e) => handleNumberChange(e.target.value) : null}
                data-testid={`Input: ${testName}`}
                id={`Input: ${testName}`}
                name={label}
                type="text"
                autoCorrect="off"
                autoComplete={label}
                placeholder={placeholder}
                disabled={disabled}
                onClick={onClick}
              />
            </label>
          </div>
        );
      }
      case 'password-input': {
        return (
          <div className={disabled ? 'form-element disabled' : 'form-element'}>
            <label htmlFor={`Input: ${testName}`}>
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
              <input
                value={value}
                className={invalid ? 'invalid' : null}
                onChange={onValueChange ? (e) => onValueChange(e.target.value) : null}
                data-testid={`Input: ${testName}`}
                id={`Input: ${testName}`}
                name={label}
                type="password"
                autoCorrect="off"
                autoComplete={label}
                placeholder={label}
                disabled={disabled}
                onClick={onClick}
              />
            </label>
          </div>
        );
      }
      case 'password-input-hide': {
        return (
          <div className={disabled ? 'form-element disabled' : 'form-element'}>
            <label htmlFor={`Input: ${testName}`}>
              {label}:
              <div className="password-hide">
                <input
                  value={value}
                  onChange={onValueChange ? (e) => onValueChange(e.target.value) : null}
                  data-testid={`Input: ${testName}`}
                  id={`Input: ${testName}`}
                  name={label}
                  type={isPasswordShow ? 'text' : 'password'}
                  autoCorrect="off"
                  autoComplete={label}
                  placeholder={label}
                  disabled={disabled}
                  onClick={onClick}
                />
                <button type="button">
                  <Image
                    onClick={toggleIsPasswordShowValue}
                    src={eyePassword}
                    paddingLeft="5px"
                    paddingRight="5px"
                  />
                </button>
              </div>
            </label>
          </div>
        );
      }
      case 'currency-input': {
        return (
          <div className={disabled ? 'form-element disabled' : 'form-element'}>
            <label htmlFor={`Input: ${testName}`}>
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
              <input
                className="currency"
                value={valueManipulation.addWhitespaces(value)}
                onChange={onValueChange ? (e) => handleNumberChange(e.target.value, 'long') : null}
                data-testid={`Input: ${testName}`}
                id={`Input: ${testName}`}
                name={label}
                type="text"
                autoCorrect="off"
                autoComplete={label}
                placeholder={label}
                disabled={disabled}
                onClick={onClick}
              />
            </label>
          </div>
        );
      }
      case 'select': {
        return (
          <div className="form-element">
            <label htmlFor="select-1">
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
            </label>
            <select
              id="select-1"
              data-testid={`Select: ${testName}`}
              className={invalid ? 'invalid' : null}
              onChange={onValueChange ? (e) => onValueChange(e.target.value) : null}
              disabled={disabled}
            >
              {options.map((option) => {
                if (value === option) {
                  return (
                    <option selected key={`${option}`} value={option}>
                      {option}
                    </option>
                  );
                }
                return (
                  <option key={`${option}`} value={option}>
                    {option}
                  </option>
                );
              })}
              {!value || value === '-' ? (
                <option value="-" selected disabled hidden>
                  -
                </option>
              ) : null}
            </select>
          </div>
        );
      }
      case 'selectNameValue': {
        return (
          <div className="form-element">
            <label htmlFor="select-1">
              <Flex>
                <Center>
                  {label}
                  <Flex sx={{ pointerEvents: 'all' }}>
                    {tooltipText ? (
                      <Tooltip
                        bg="horvath.blue-light"
                        p={3}
                        hasArrow="true"
                        placement="right"
                        label={tooltipText}
                      >
                        <QuestionIcon color="horvath.blue-light" ml={1} />
                      </Tooltip>
                    ) : null}
                  </Flex>
                </Center>
              </Flex>
            </label>
            <select
              id="select-1"
              data-testid={`Select: ${testName}`}
              className={invalid ? 'invalid' : null}
              onChange={onValueChange ? (e) => onValueChange(e.target.value) : null}
              disabled={disabled}
            >
              {options.map((option) => {
                if (value === option.value) {
                  return (
                    <option selected key={`${option.value}`} value={option.value}>
                      {option.name}
                    </option>
                  );
                }
                return (
                  <option key={`${option.value}`} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
              {!value || value === '-' ? (
                <option value="-" selected disabled hidden>
                  -
                </option>
              ) : null}
            </select>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Flex
      as="dl"
      direction={{
        base: 'column',
        sm: 'row',
      }}
      px="6"
      py="4"
      _even={
        colorEvenItems
          ? {
              bg: useColorModeValue('horvath.grey-light'),
            }
          : null
      }
      align="center"
      {...flexProps}
    >
      <Box ml={1} as="dd" flex="1" fontWeight="semibold" w="300px">
        {renderFormType()}
      </Box>
    </Flex>
  );
};

export default FormProperty;
