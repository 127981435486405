/*
 * Contains the redux store for providing global states
 */
import { configureStore } from '@reduxjs/toolkit';
import industryLevelsReducer from './slices/industryLevelsSlice';
import userReducer from './slices/userSlice';
import companyReducer from './slices/companySlice';
import benchmarkingCompanyInfoReducer from './slices/benchmarkingCompanyInfoSlice';
import benchmarkingControllingReducer from './slices/benchmarkingControllingSlice';
import benchmarkingControllingEvaluationReducer from './slices/benchmarkingControllingEvaluationSlice';
import benchmarkingFinanceReducer from './slices/benchmarkingFinanceSlice';
import benchmarkingFinanceEvaluationReducer from './slices/benchmarkingFinanceEvaluationSlice';
import authReducer from './slices/authSlice';
import adminUserReducer from './slices/adminUserSlice';
import benchmarkingsReducer from './slices/benchmarkingsSlice';
import adminCompanyReducer from './slices/adminCompanySlice';
import warningBenchmarkingControllingReducer from './slices/warningBenchmarkingControllingSlice';
import warningBenchmarkingFinanceReducer from './slices/warningBenchmarkingFinanceSlice';
import benchmarkingForEvaluationReducer from './slices/benchmarkingForEvaluationSlice';

/**
 * Contains all reducer and is exported for easier testing
 */
export const reducer = {
  industryLevels: industryLevelsReducer,
  user: userReducer,
  company: companyReducer,
  adminUser: adminUserReducer,
  adminCompany: adminCompanyReducer,
  benchmarkingCompanyInfo: benchmarkingCompanyInfoReducer,
  benchmarkingControlling: benchmarkingControllingReducer,
  benchmarkingControllingEvaluation: benchmarkingControllingEvaluationReducer,
  benchmarkingFinance: benchmarkingFinanceReducer,
  benchmarkingFinanceEvaluation: benchmarkingFinanceEvaluationReducer,
  auth: authReducer,
  benchmarkings: benchmarkingsReducer,
  warningBenchmarkingControlling: warningBenchmarkingControllingReducer,
  warningBenchmarkingFinance: warningBenchmarkingFinanceReducer,
  benchmarkingForEvaluation: benchmarkingForEvaluationReducer,
};

/**
 * Store of the different reducers and holds additional
 * redux configurations
 */
export const store = configureStore({
  reducer,
  /*
   * NODE_ENV is a built-in environment variable which is changed
   * by running 'npm start' (-> development) or 'npm run build' (-> production)
   */
  devTools: process.env.NODE_ENV === 'development',
});
