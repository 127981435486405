/*
 * Renders the Company view
 */
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Grid,
  GridItem,
  Link,
  Spinner,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ReactDOM from 'react-router-dom';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import InfoProperty from '../components/inputs/InfoProperty';
import { logout as logoutRequest } from '../slices/authSlice';
import {
  fetchCompany,
  selectCompany,
  selectCompanyError,
  selectCompanyStatus,
} from '../slices/companySlice';
import {
  fetchIndustryLevels,
  selectIndustryLevelsError,
  selectIndustryLevelsStatus,
} from '../slices/industryLevelsSlice';
import {
  changePasswordUser,
  editUser,
  fetchUser,
  selectUser,
  selectUserError,
  selectUserStatus,
} from '../slices/userSlice';
import validateEmail from '../utils/validation';

/**
 * Displays information about the company and the logged in user
 * and provides funtionality to edit these information
 * @returns Cards with company and user information
 * with dialogs to edit these information
 */
const User = () => {
  const { t } = useTranslation();
  const history = ReactDOM.useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const company = useSelector(selectCompany);

  const userStatus = useSelector(selectUserStatus);
  const userError = useSelector(selectUserError);

  const companyStatus = useSelector(selectCompanyStatus);
  const companyError = useSelector(selectCompanyError);

  const industryLevelsStatus = useSelector(selectIndustryLevelsStatus);
  const industryLevelsError = useSelector(selectIndustryLevelsError);

  const [appelation, setAppelation] = React.useState(user.salutation);
  const [academicDegree, setAcademicDegree] = React.useState(user.academicTitle);
  const [firstName, setFirstName] = React.useState(user.firstName);
  const [lastName, setLastName] = React.useState(user.lastName);
  const [position, setPosition] = React.useState(user.position);
  const [department, setDepartment] = React.useState(user.department);
  const [eMailAddress, setEmailAddress] = React.useState(user.email);

  const [editUserRequestStatus, setEditUserRequestStatus] = React.useState('idle');

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');

  const oldPasswordInputByUser = (password) => {
    setOldPassword(password);
  };

  const newPasswordInputByUser = (password) => {
    setNewPassword(password);
  };

  const confirmNewPasswordInputByUser = (password) => {
    setConfirmNewPassword(password);
  };

  const toast = useToast();

  // Options for academic titles in dropdown
  const academicTitleOptions = [
    {
      name: t('global.academicTitleOptions.empty'),
      value: '-',
    },
    {
      name: t('global.academicTitleOptions.bachelor'),
      value: 'BSC',
    },
    {
      name: t('global.academicTitleOptions.master'),
      value: 'MS',
    },
    {
      name: t('global.academicTitleOptions.diplom'),
      value: 'DIPLOM',
    },
    {
      name: t('global.academicTitleOptions.doctor'),
      value: 'DR',
    },
    {
      name: t('global.academicTitleOptions.professor'),
      value: 'PROF',
    },
  ];

  // Options for appelation in dropdown
  const appelationOptions = [
    { name: t('global.appelationOptions.mr'), value: 'HERR' },
    { name: t('global.appelationOptions.mrs'), value: 'FRAU' },
  ];

  /**
   * Checks if the edited user can be saved
   * @returns True if the name of the user is not empty and the request status is idle
   */
  const canSaveEditedUser = () => {
    return lastName !== '' && firstName !== '' && editUserRequestStatus === 'idle';
  };

  // States for opening the change password dialog
  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure();

  const resetUserInputsPasswordsAndErrorMsg = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setErrorMsg('');
    onChangePasswordClose();
  };

  // States for opening the company dialog
  const {
    isOpen: isUserEditOpen,
    onOpen: onUserEditOpen,
    onClose: onUserEditClose,
  } = useDisclosure();

  /**
   * Callback function that dispatches an async thunk to retrieve user data
   */
  const fetchUserData = () => {
    dispatch(fetchUser())
      .then(unwrapResult)
      .then((res) => {
        setAppelation(res.salutation);
        setAcademicDegree(res.academicTitle);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setPosition(res.position);
        setDepartment(res.department);
        setEmailAddress(res.email);
      })
      .catch(() => {
        toast({
          title: t('errors.user.fetchUserDataFailed.title'),
          description: t('errors.user.fetchUserDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  React.useEffect(() => fetchUserData(), []);

  /**
   * Callback function that dispatches an async thunk to retrieve company data
   */
  const fetchCompanyData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .catch(() => {
        toast({
          title: t('errors.user.fetchCompanyDataFailed.title'),
          description: t('errors.user.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Callback function that dispatches an async thunk to retrieve industry level data
   */
  const fetchIndustryLevelsData = () => {
    // Only fetch industry levels if the status of the reducer is 'idle'
    if (industryLevelsStatus === 'idle') {
      dispatch(fetchIndustryLevels());
    }
  };

  React.useEffect(() => fetchIndustryLevelsData(), []);

  /**
   * Logs out the user by calling the logout route
   */
  const logout = () => {
    dispatch(logoutRequest())
      .then(unwrapResult)
      .then(() => {
        history.push('/login');
      })
      .catch(() => {
        toast({
          title: t('errors.user.logout.title'),
          description: t('errors.user.logout.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Saves the edited user
   */
  const saveEditedUser = () => {
    if (canSaveEditedUser()) {
      setEditUserRequestStatus('pending');
      dispatch(
        editUser({
          id: user.id,
          companyId: user.companyId,
          version: user.version,
          salutation: appelation,
          academicTitle: academicDegree === '-' ? null : academicDegree,
          firstName,
          lastName,
          position,
          department,
          email: eMailAddress,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          if (user.email !== eMailAddress) {
            toast({
              title: t('user.changeEmail.title'),
              position: 'top-right',
              description: t('user.changeEmail.description'),
              status: 'info',
              duration: 5000,
              isClosable: true,
            });
            logout();
          } else {
            setEditUserRequestStatus('idle');
            fetchUserData();
            onUserEditClose();
            toast({
              title: t('user.saveChanges.title'),
              position: 'top-right',
              description: t('user.saveChanges.description'),
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
          }
        })
        .catch(() => {
          toast({
            title: t('errors.user.saveChangesFailed.title'),
            position: 'top-right',
            description: t('errors.user.saveChangesFailed.description'),
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  };

  /**
   * Saves the changed password of the user
   */
  const saveChangedPassword = () => {
    if (oldPassword.length < 8) {
      setPasswordError(true);
      setErrorMsg(t('errors.user.changePassword.oldPasswordInvalid'));
      return;
    }
    setPasswordError(false);
    setErrorMsg('');

    if (newPassword.length < 8) {
      setPasswordError(true);
      setErrorMsg(t('errors.user.changePassword.newPasswordInvalid'));
      return;
    }
    setPasswordError(false);
    setErrorMsg('');

    if (newPassword !== confirmNewPassword) {
      setRepeatPasswordError(true);
      setErrorMsg(t('errors.user.changePassword.passwordsNotMatch'));
      return;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');

    if (newPassword === oldPassword) {
      setRepeatPasswordError(true);
      setErrorMsg(t('errors.user.changePassword.distinguishNewFromOld'));
      return;
    }
    setRepeatPasswordError(false);
    setErrorMsg('');

    dispatch(changePasswordUser({ oldPassword, newPassword }))
      .then(unwrapResult)
      .then(() => {
        toast({
          title: 'Kennwort erfolgreich geändert!',
          description: '',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onChangePasswordClose();
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      })
      .catch(() => {
        toast({
          title: t('user.changePassword.title'),
          description: t('user.changePassword.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Checks the necessary user inputs and returns true if everything check out
   * @returns true if the inputs are valid, false otherwise
   */
  const checkUserInputsForPassword = () => {
    return oldPassword !== '' && newPassword !== '' && confirmNewPassword !== '';
  };

  /**
   * Checks the necessary user inputs and returns true if everything check out
   * @returns true if the inputs are valid, false otherwise
   */
  const checkUserInputs = () => {
    return firstName !== '' && lastName !== '' && validateEmail(eMailAddress);
  };

  /**
   * Renders the company card and company dialog
   * @returns The company card and dialog, a spinner or an error message
   */
  const renderCompanySection = () => {
    if (companyStatus === 'loading' || industryLevelsStatus === 'loading') {
      return (
        <Center>
          <Spinner size="xl" />
        </Center>
      );
    }
    if (companyStatus === 'succeeded' && industryLevelsStatus === 'succeeded') {
      return (
        <Card maxW="3xl" mx="auto">
          <Box>
            <CardHeader title={t('user.companyCard.title')} height={12} />
          </Box>
          <Box>
            <InfoProperty
              label={t('user.companyCard.completeName')}
              value={company.name}
              testName="Vollständiger Name"
              colorEvenItems
            />
            <InfoProperty
              label={t('user.companyCard.shortName')}
              value={company.shortName}
              testName="Kurzname"
              colorEvenItems
            />
            <InfoProperty
              label={t('user.companyCard.country')}
              value={company.countryCode}
              testName="Land"
              colorEvenItems
            />
            <InfoProperty
              label={t('user.companyCard.industryLvl1')}
              value={company.industryLevel1}
              testName="Industrie Level 1"
              colorEvenItems
            />
            <InfoProperty
              label={t('user.companyCard.industryLvl2')}
              value={company.industryLevel2}
              testName="Industrie Level 2"
              colorEvenItems
            />
            <InfoProperty
              label={t('user.companyCard.industryLvl3')}
              value={company.industryLevel3}
              testName="Industrie Level 3"
              colorEvenItems
            />
          </Box>
        </Card>
      );
    }
    if (companyStatus === 'failed') {
      return (
        <ErrorStat errorMessage={companyError} onRefresh={fetchCompanyData} maxW="3xl" mx="auto" />
      );
    }
    if (industryLevelsStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={industryLevelsError}
          onRefresh={fetchIndustryLevelsData}
          maxW="3xl"
          mx="auto"
        />
      );
    }
    return null;
  };

  /**
   * Renders the user card and dialog
   * @returns The user card and dialog, a spinner or an error message
   */
  const renderUserSection = () => {
    if (userStatus === 'loading') {
      return (
        <Center>
          <Spinner size="xl" />
        </Center>
      );
    }
    if (userStatus === 'succeeded') {
      return (
        <>
          <Card maxW="3xl" mx="auto">
            <CardHeader
              title={t('user.userCard.title')}
              action={
                <>
                  <Button
                    iconType="edit"
                    text={t('user.userCard.editButton')}
                    action={onUserEditOpen}
                    testId="user-edit-btn"
                  />
                  <Button
                    iconType="edit"
                    text={t('user.userCard.changePasswordButton')}
                    action={onChangePasswordOpen}
                    testId="user-change-password"
                  />
                </>
              }
            />
            <Box>
              <InfoProperty
                label={t('user.userCard.salutation')}
                value={
                  // German value is received from the backend and needs to be translated "manually"
                  user.salutation === 'HERR'
                    ? t('global.appelationOptions.mr')
                    : t('global.appelationOptions.mrs')
                }
                colorEvenItems
                testName="Anrede"
              />
              <InfoProperty
                label={t('user.userCard.academicTitle')}
                colorEvenItems
                value={user.academicTitle}
                testName="Akad. Titel"
              />
              <InfoProperty
                label={t('user.userCard.firstName')}
                value={user.firstName}
                colorEvenItems
                testName="Vorname"
              />
              <InfoProperty
                label={t('user.userCard.lastName')}
                value={user.lastName}
                colorEvenItems
                testName="Nachname"
              />
              <InfoProperty
                label={t('user.userCard.position')}
                value={user.position}
                colorEvenItems
                testName="Position"
              />
              <InfoProperty
                label={t('user.userCard.department')}
                value={user.department}
                colorEvenItems
                testName="Abteilung"
              />
              <InfoProperty
                label={t('user.userCard.email')}
                value={user.email}
                colorEvenItems
                testName="E-Mail"
              />
              <Center bg="horvath.grey-light" px="6" py="4">
                <Link
                  color="horvath.blue-light"
                  href="https://success.horvath-partners.com/art_resource.php?sid=5hokm.1mid0q6"
                  isExternal
                >
                  {t('user.userCard.LinkText')}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </Center>
            </Box>
          </Card>
          <Dialog
            title={t('user.userCard.dialog.editUser')}
            secondaryActionName={t('user.userCard.dialog.save')}
            secondaryAction={saveEditedUser}
            secondaryActionDisabled={!checkUserInputs()}
            isOpen={isUserEditOpen}
            onClose={onUserEditClose}
          >
            <FormControl>
              <FormProperty
                label={t('user.userCard.salutation')}
                formType="selectNameValue"
                value={appelation}
                onValueChange={setAppelation}
                colorEvenItems
                testName="Anrede"
                options={appelationOptions}
              />
              <FormProperty
                label={t('user.userCard.academicTitle')}
                formType="selectNameValue"
                value={academicDegree}
                onValueChange={setAcademicDegree}
                colorEvenItems
                testName="Akad. Titel"
                options={academicTitleOptions}
              />
              <FormProperty
                label={t('user.userCard.firstName')}
                formType="input"
                invalid={firstName === ''}
                value={firstName}
                onValueChange={setFirstName}
                required
                colorEvenItems
                testName="Vorname"
              />
              <FormProperty
                label={t('user.userCard.lastName')}
                formType="input"
                invalid={lastName === ''}
                value={lastName}
                onValueChange={setLastName}
                colorEvenItems
                testName="Nachname"
              />
              <FormProperty
                label={t('user.userCard.position')}
                formType="input"
                value={position}
                onValueChange={setPosition}
                colorEvenItems
                testName="Position"
              />
              <FormProperty
                label={t('user.userCard.department')}
                formType="input"
                value={department}
                onValueChange={setDepartment}
                colorEvenItems
                testName="Abteilung"
              />
              <FormProperty
                label={t('user.userCard.email')}
                invalid={!validateEmail(eMailAddress)}
                formType="input"
                value={eMailAddress}
                onValueChange={(val) => {
                  setEmailAddress(val);
                }}
                colorEvenItems
                testName="E-Mail"
              />
            </FormControl>
          </Dialog>
          <Dialog
            title={t('user.userCard.dialog.changePassword')}
            secondaryActionName={t('user.userCard.dialog.confirm')}
            secondaryAction={saveChangedPassword}
            secondaryActionDisabled={!checkUserInputsForPassword()}
            isOpen={isChangePasswordOpen}
            onClose={resetUserInputsPasswordsAndErrorMsg}
          >
            <FormControl>
              <FormProperty
                label={t('user.userCard.dialog.oldPassword')}
                formType="password-input-hide"
                value={oldPassword}
                onValueChange={oldPasswordInputByUser}
                colorEvenItems
                invalid={oldPassword.length < 8}
                testName="Altes Passwort"
              />
              <FormProperty
                label={t('user.userCard.dialog.newPassword')}
                formType="password-input-hide"
                value={newPassword}
                onValueChange={newPasswordInputByUser}
                colorEvenItems
                invalid={passwordError && newPassword.length < 8}
                testName="Neues Passwort"
              />
              <FormProperty
                label={t('user.userCard.dialog.repeatNewPassword')}
                formType="password-input-hide"
                value={confirmNewPassword}
                onValueChange={confirmNewPasswordInputByUser}
                colorEvenItems
                invalid={repeatPasswordError && oldPassword !== confirmNewPassword}
                testName="Neues Passwort wiederholen"
              />
            </FormControl>
            {/* TODO: errorMsg */}
            <Center color="red">{errorMsg}</Center>
          </Dialog>
        </>
      );
    }
    if (userStatus === 'failed') {
      /* TODO: errorMsg */
      return <ErrorStat errorMessage={userError} onRefresh={fetchUserData} maxW="3xl" mx="auto" />;
    }
    return null;
  };

  return (
    <>
      <PageHeader title={t('user.userCard.pageHeader')} />
      <p>{t('user.userCard.subHeading')}</p>
      <ContentWrapper>
        <Grid templateColumns="repeat(6, 1fr)" gap={6}>
          <GridItem colSpan={3}>{renderUserSection()}</GridItem>
          <GridItem colSpan={3}>{renderCompanySection()}</GridItem>
        </Grid>
      </ContentWrapper>
    </>
  );
};

export default User;
