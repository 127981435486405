/**
 * Renders error stat component
 */
import { RepeatIcon } from '@chakra-ui/icons';
import {
  Center,
  Flex,
  IconButton,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Displays Error message and refresh button
 * @returns Stat with error message and refresh button
 */
const ErrorStat = ({ onRefresh, errorMessage, ...props }) => {
  const { t } = useTranslation();
  return (
    <Stat color="red" borderWidth={1} borderColor="red" padding={2} {...props}>
      <StatLabel>Error</StatLabel>
      <Flex>
        <StatNumber>{t('components.errorStat.errorHeading')}</StatNumber>
        <Spacer />
        <Center>
          <Text mr={1}>{t('components.errorStat.updateText')}</Text>
          <IconButton onClick={onRefresh}>
            <RepeatIcon />
          </IconButton>
        </Center>
      </Flex>
      <StatHelpText>{`"${errorMessage}"`}</StatHelpText>
    </Stat>
  );
};

export default ErrorStat;
