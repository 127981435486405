// import i18next from 'i18next';

/*
 * Initializes axios
 */
import axios from 'axios';
// import AppToaster from '../components/AppToaster';

export const getBaseURL = () => {
  if (process.env.REACT_APP_BASE_URL) {
    return process.env.REACT_APP_BASE_URL;
  }
  return 'http://localhost:8080';
};

/**
 * Configuration for the used API endpoint
 */
const API = axios.create({
  baseURL: getBaseURL(),
  responseType: 'json',
});

export default API;
