/* eslint-disable jsx-a11y/label-has-associated-control */
/*
 * Renders a container for checkboxes
 */
import * as React from 'react';
import {
  Box,
  Flex,
  useColorModeValue,
  Tooltip,
  Center,
  CheckboxGroup,
  Stack,
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import '../../../css/form.scss';
import '../../../css/button.scss';

/**
 * Displays the container for checkboxes
 * @returns A flex box with label and input
 */
const Checkboxes = (props) => {
  const {
    label,
    tooltipText,
    value,
    formType,
    colorEvenItems,
    disabled,
    testName,
    onValueChange,
    required,
    options,
    onClick,
    invalid,
    children,
    ...flexProps
  } = props;

  return (
    <Flex
      as="dl"
      direction={{
        base: 'column',
        sm: 'row',
      }}
      px="6"
      py="4"
      _even={
        colorEvenItems
          ? {
              bg: useColorModeValue('horvath.grey-light'),
            }
          : null
      }
      align="center"
      {...flexProps}
    >
      <Box ml={1} as="dd" flex="1" fontWeight="semibold" w="300px">
        <div className={disabled ? 'form-element disabled' : 'form-element'}>
          <label htmlFor={`Input: ${testName}`}>
            <Flex mb={2}>
              <Center paddingBottom={3}>
                <strong>{label}</strong>
                <Flex sx={{ pointerEvents: 'all' }}>
                  {tooltipText ? (
                    <Tooltip
                      bg="horvath.blue-light"
                      p={3}
                      hasArrow="true"
                      placement="right"
                      label={tooltipText}
                    >
                      <QuestionIcon color="horvath.blue-light" ml={1} />
                    </Tooltip>
                  ) : null}
                </Flex>
              </Center>
            </Flex>
            <CheckboxGroup defaultValue={['naruto', 'kakashi']}>
              <Stack ml={4} spacing={[1, 5]} direction={['column']}>
                {children}
              </Stack>
            </CheckboxGroup>
          </label>
        </div>
      </Box>
    </Flex>
  );
};

export default Checkboxes;
