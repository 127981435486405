import { Box, Center, Heading, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import api from '../utils/api';
import validateEmail from '../utils/validation';

/**
 * Displays the email verification view
 */
const InputEmailForPasswordReset = () => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState('');

  const toast = useToast();

  /**
   * Handles the user input for the email and validates it
   */
  const handleUserEmailValueChange = (inputValue) => {
    setUserEmail(inputValue);
    if (!validateEmail(inputValue)) {
      setEmailError(false);
      setErrorMsg(t('errors.verifyEmail.invalidEmail'));
    } else {
      setEmailError(true);
      setErrorMsg('');
    }
  };

  /**
   * Checks for correct user input mail and send a verification email to reset the password
   */
  const checkAndSendEmail = () => {
    if (!emailError) {
      toast({
        title: t('errors.verifyEmail.title'),
        description: t('errors.verifyEmail.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    api
      .post(
        '/auth/send-mail',
        { email: userEmail },
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: t('verifyEmail.success.title'),
            description: t('verifyEmail.success.description'),
            status: 'success',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 400') {
          toast({
            title: t('errors.verifyEmail.noEmailExists.title'),
            description: t('errors.verifyEmail.noEmailExists.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <Center>
        <Stack
          w="550px"
          marginTop={20}
          spacing={5}
          direction={{ base: 'column', xl: 'row' }}
          justifyContent="space-evenly"
        >
          <Box>
            <Stack spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
              <Center>
                <VStack>
                  <Heading>{t('verifyEmail.heading')}</Heading>
                  <Text textAlign="center">{t('verifyEmail.body')}</Text>
                </VStack>
              </Center>
              <FormControl>
                <FormProperty
                  label={t('verifyEmail.email')}
                  formType="input"
                  value={userEmail}
                  onValueChange={handleUserEmailValueChange}
                  invalid={!emailError}
                />
              </FormControl>
              <Text pl={7} pt={0} color="red">
                {errorMsg}
              </Text>
              <Center>
                <Button text={t('verifyEmail.submitButton')} action={checkAndSendEmail} />
              </Center>
            </Stack>
          </Box>
        </Stack>
      </Center>
    </div>
  );
};

export default InputEmailForPasswordReset;
