/**
 * Component which contains an icon button with flag icons to switch languages
 */
import * as React from 'react';
import { Image } from '@chakra-ui/react';
import i18n from '../translations/i18n';
import flagDe from '../../assets/img/FlagGermany.svg';
import flagEn from '../../assets/img/FlagUK.svg';
import Clickable from './Clickable';

const LanguageToggle = () => {
  const [language, setLanguage] = React.useState(i18n.resolvedLanguage);
  /**
   * Toggles the language context of the page between german and english
   */
  const toggleLanguage = () => {
    if (i18n.resolvedLanguage === 'en') {
      i18n.changeLanguage('de');
      setLanguage('de');
    } else {
      i18n.changeLanguage('en');
      setLanguage('en');
    }
  };
  return (
    <Clickable>
      {language === 'en' ? (
        <Image htmlWidth="40px" mr={5} pr={0} src={flagEn} onClick={() => toggleLanguage()} />
      ) : (
        <Image htmlWidth="40px" mr={5} p={0} src={flagDe} onClick={() => toggleLanguage()} />
      )}
    </Clickable>
  );
};

export default LanguageToggle;
