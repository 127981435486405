/*
 * Renders the Verify User View
 */
import {
  Box,
  Center,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TiDelete } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import { resetPasswordUser } from '../slices/userSlice';

import Button from '../components/inputs/Button';
import api from '../utils/api';

/**
 * Displays the reset password view
 */
const UserResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const toast = useToast();

  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [newPasswordError, setNewPasswordError] = React.useState(false);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = React.useState(false);
  const [errorMsgConfirmNewPassword, setErrorMsgConfirmNewPassword] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState('');
  const [errorMsgNewPassword, setErrorMsgNewPassword] = React.useState('');
  const [statusToken, setStatusToken] = useState('');

  /**
   * Handles the user input for the new password and validates it
   */
  const handleNewPasswordInputByUserValueChange = (inputValue) => {
    setNewPassword(inputValue);
    if (inputValue.length < 8) {
      setNewPasswordError(false);
      setErrorMsgNewPassword(t('errors.resetPassword.invalidPassword'));
    } else {
      setNewPasswordError(true);
      setErrorMsgNewPassword('');
    }
  };

  /**
   * Handles the user input for confirming the new password and validates it
   */
  const handleConfirmNewPasswordInputByUserValueChange = (inputValue) => {
    setConfirmNewPassword(inputValue);
    if (inputValue.length < 8) {
      setConfirmNewPasswordError(false);
      setErrorMsgConfirmNewPassword(t('errors.resetPassword.invalidPassword'));
    } else {
      setConfirmNewPasswordError(true);
      setErrorMsgConfirmNewPassword('');
    }
  };

  /**
   * Saves the password if both passwords are equal
   */
  const checkPasswordAndSave = () => {
    if (newPassword !== confirmNewPassword) {
      setConfirmNewPassword(true);
      setErrorMsg(t('errors.resetPassword.passwordsNotMatch'));
      return;
    }
    setConfirmNewPasswordError(false);
    setErrorMsg('');

    const resetPasswordData = { token, newPassword };

    dispatch(resetPasswordUser(resetPasswordData))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          toast({
            title: t('resetPassword.successResetPassword.title'),
            description: t('resetPassword.successResetPassword.description'),
            status: 'success',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
          setConfirmNewPassword('');
          setNewPassword('');
          history.push('/login');
        }
      })
      .catch(() => {
        toast({
          title: t('errors.resetPassword.errorModal.title'),
          description: t('errors.resetPassword.errorModal.description'),
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        setConfirmNewPassword('');
        setNewPassword('');
      });
  };

  /**
   * Evaluates the token and displays the correspondiong view
   */
  const evaluateTokenStatus = () => {
    // API-Request to get status of token for user (exist, does not exist, expire)
    api
      .put(
        `auth/user/reset-token/${token}`,
        {},
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          setStatusToken('VALID TOKEN');
        }
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 404') {
          setStatusToken('NO VALID TOKEN');
        } else if (err.message === 'Request failed with status code 422') {
          setStatusToken('EXPIRED TOKEN');
        } else {
          setStatusToken('NO VALID TOKEN');
        }
      });
  };

  React.useEffect(() => {
    evaluateTokenStatus();
  }, []);

  /**
   * Displays the correspondiong reset pssword view depending on the status of the reset token
   */
  const displayResetPasswordFeedback = () => {
    if (statusToken === 'VALID TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
              <Center>
                <VStack>
                  <Heading>{t('resetPassword.inputPasswordHeading')}</Heading>
                  <Text>{t('resetPassword.inputPasswordBody')}</Text>
                </VStack>
              </Center>
              <br />
              <FormControl>
                <FormProperty
                  label={t('resetPassword.newPassword')}
                  formType="password-input"
                  value={newPassword}
                  onValueChange={handleNewPasswordInputByUserValueChange}
                  invalid={newPasswordError && newPassword.length < 8}
                  testName="Neues Passwort"
                />
                <Text pl={7} pt={0} color="red">
                  {errorMsgNewPassword}
                </Text>
                <FormProperty
                  label={t('resetPassword.repeatNewPassword')}
                  formType="password-input"
                  value={confirmNewPassword}
                  onValueChange={handleConfirmNewPasswordInputByUserValueChange}
                  invalid={confirmNewPasswordError && newPassword !== confirmNewPassword}
                  testName="Neues Passwort wiederholen"
                />
                <Text pl={7} pt={0} color="red">
                  {errorMsgConfirmNewPassword}
                </Text>
              </FormControl>
              <SimpleGrid columns={1} spacing={4}>
                <Button
                  text={t('resetPassword.submitButton')}
                  color="primary"
                  action={checkPasswordAndSave}
                  testId="Reset Password Button"
                />
                <Text color="red">{errorMsg}</Text>
              </SimpleGrid>
            </Box>
          </Stack>
        </div>
      );

    if (statusToken === 'NO VALID TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box paddingTop={10}>
              <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
                <Center>
                  <VStack>
                    <Heading>{t('resetPassword.noValidToken.heading')}</Heading>
                    <Center>
                      <Box pt={2}>
                        <Text>{t('resetPassword.noValidToken.body')}</Text>
                      </Box>
                    </Center>
                  </VStack>
                </Center>
              </Box>
            </Box>
          </Stack>
        </div>
      );

    if (statusToken === 'EXPIRED TOKEN')
      return (
        <div>
          <Stack
            marginTop={20}
            spacing={5}
            direction={{ base: 'column', xl: 'row' }}
            justifyContent="space-evenly"
          >
            <Box paddingTop={10}>
              <Box spacing={3} p="1rem" bg="white" borderWidth={1} borderColor="horvath.gray">
                <Center>
                  <HStack>
                    <TiDelete color="red" size={35} />
                    <Heading>{t('resetPassword.expiredToken.heading')}</Heading>
                  </HStack>
                </Center>
                <Center>
                  <Box pt={2}>
                    <Text>{t('resetPassword.expiredToken.body1')}</Text>
                  </Box>
                </Center>
                <Center>
                  <Text>{t('resetPassword.expiredToken.body2')}</Text>
                </Center>
                <br />
                <Center>
                  <Button
                    text={t('resetPassword.expiredToken.requestNewTokenButton')}
                    action={() => history.push('/login')}
                  />
                </Center>
              </Box>
            </Box>
          </Stack>
        </div>
      );
    return null;
  };

  return <div>{displayResetPasswordFeedback()}</div>;
};

export default UserResetPassword;
