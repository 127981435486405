/*
 * Renders the form control component
 */
import * as React from 'react';

/**
 * Displays the form control component
 * @returns An article element with a nested form element which holds styling of forms
 */
const FormControl = ({ children, onSubmit }) => {
  return (
    <article className="area fractal bg-white">
      <div className="col-container">
        <div className="col-12">
          <form action="" className="form" onSubmit={onSubmit}>
            {children}
          </form>
        </div>
      </div>
    </article>
  );
};

export default FormControl;
