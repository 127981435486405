import {
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';

const DeleteUserPopover = ({ headerText, confirmationText, confirmationFunction }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  const confirmHook = () => {
    confirmationFunction();
    close();
  };

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <IconButton
          color="horvath.red"
          icon={<FiTrash2 fontSize="1.25rem" />}
          variant="ghost"
          aria-label="Delete member"
          onClick={open}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">{headerText}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{confirmationText}</PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={close}>
              {t('global.cancelButton')}
            </Button>
            <Button colorScheme="red" onClick={confirmHook}>
              {t('global.confirmButton')}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default DeleteUserPopover;
