/*
 * Renders the info modal component
 */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './inputs/Button';

/**
 * Displays a modal for relaying information to the user
 * It is not closeable by clicking outside of the component
 *
 * @returns A model with specifiable title and info text
 */
const InfoModal = (props) => {
  const {
    title,
    isOpen,
    onClose,
    type,
    infoText,
    isPromise,
    promiseText,
    promiseTitle,
    onClosePromise,
    isPromiseResolved,
  } = props;
  const { t } = useTranslation();

  /**
   * Displays the title or the title regarind a promise
   * @returns The title for the info modal
   */
  const renderModalTitle = () => {
    if (isPromise) {
      return !isPromiseResolved ? promiseTitle : title;
    }
    return title;
  };
  /**
   * Displays the info text or a text regarding a promise
   * @returns Info text or promise text
   */
  const renderModalBody = () => {
    if (isPromise) {
      return !isPromiseResolved ? (
        <ModalBody>{promiseText}</ModalBody>
      ) : (
        <ModalBody>{infoText}</ModalBody>
      );
    }
    return <ModalBody>{infoText}</ModalBody>;
  };

  /**
   * Displays the ok-button or a spinner if the promise is not resolved
   * @returns Ok-button or spinner
   */
  const renderModalFooter = () => {
    if (isPromise) {
      return !isPromiseResolved ? (
        <ModalFooter>
          <Spinner mr={9} mb={3} />
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button
            text={t('global.okButton')}
            color="primary"
            action={onClose}
            style={{ marginRight: '5px' }}
          />
        </ModalFooter>
      );
    }
    return (
      <ModalFooter>
        <Button
          text={t('global.okButton')}
          color="primary"
          action={onClose}
          style={{ marginRight: '5px' }}
        />
      </ModalFooter>
    );
  };

  const handleModalClose = () => {
    if (isPromise) {
      if (isPromiseResolved) {
        onClose();
      } else {
        onClosePromise();
      }
    } else {
      onClose();
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleModalClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={{ borderRadius: '0px' }}>
        <ModalHeader
          color={type === 'warning' ? 'white' : null}
          bg={type === 'warning' ? 'horvath.red' : null}
        >
          {renderModalTitle()}
        </ModalHeader>
        <ModalCloseButton color={type === 'warning' ? 'white' : null} />
        {renderModalBody()}
        {renderModalFooter()}
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
