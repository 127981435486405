/**
 * Slice which provides reducer for the finance input warnings
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  finProcess: {
    warningShown: false,
  },
  finConsolidation: {
    warningShown: false,
  },
  finAssetManagement: {
    warningShown: false,
  },
  finCashManagement: {
    warningShown: false,
  },
  finChargesTravel: {
    warningShown: false,
  },
  finCreditorsProcess: {
    warningShown: false,
  },
  finDebitorsProcess: {
    warningShown: false,
  },
  finGeneralAccounting: {
    warningShown: false,
  },
  finImprovement: {
    warningShown: false,
  },
  finLiquidityControl: {
    warningShown: false,
  },
  finRiskManagement: {
    warningShown: false,
  },
  finTax: {
    warningShown: false,
  },
  finWorkingCapitalManagement: {
    warningShown: false,
  },
};

/**
 * The slice of the warning finance state
 */
export const warningBenchmarkingFinanceSlice = createSlice({
  name: 'warningBenchmarkingFinance',
  initialState,
  reducers: {
    deactivateWarning: (state, action) => {
      switch (action.payload) {
        case 'finProcess': {
          state.finProcess.warningShown = true;
          break;
        }
        case 'finAssetManagement': {
          state.finAssetManagement.warningShown = true;
          break;
        }
        case 'finCashManagement': {
          state.finCashManagement.warningShown = true;
          break;
        }
        case 'finChargesTravel': {
          state.finChargesTravel.warningShown = true;
          break;
        }
        case 'finCreditorsProcess': {
          state.finCreditorsProcess.warningShown = true;
          break;
        }
        case 'finDebitorsProcess': {
          state.finDebitorsProcess.warningShown = true;
          break;
        }
        case 'finGeneralAccounting': {
          state.finGeneralAccounting.warningShown = true;
          break;
        }
        case 'finImprovement': {
          state.finImprovement.warningShown = true;
          break;
        }
        case 'finLiquidityControl': {
          state.finLiquidityControl.warningShown = true;
          break;
        }
        case 'finRiskManagement': {
          state.finRiskManagement.warningShown = true;
          break;
        }
        case 'finTax': {
          state.finTax.warningShown = true;
          break;
        }
        case 'finWorkingCapitalManagement': {
          state.finWorkingCapitalManagement.warningShown = true;
          break;
        }
        default: {
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { deactivateWarning } = warningBenchmarkingFinanceSlice.actions;

/**
 * Selects the warning benchmarking finance state
 */
export const selectWarningBenchmarkingFinance = (state) => state.warningBenchmarkingFinance;

export default warningBenchmarkingFinanceSlice.reducer;
