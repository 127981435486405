/*
 * Renders the card component
 */
import { Box, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Displays the card component
 * @returns A styled box with props
 */
const Card = (props) => {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.700')}
      borderColor="gray.300"
      borderWidth="1px"
      overflow="hidden"
      {...props}
    />
  );
};

export default Card;
