/*
 * Renders the error modal component
 */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './inputs/Button';

/**
 * Displays a modal for relaying information to the user
 * It is not closeable by clicking outside of the component
 *
 * @returns A model with specifiable title and info text
 */
const ErrorModal = (props) => {
  const { title, isOpen, onClose, type, infoText } = props;
  const { t } = useTranslation();
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent sx={{ borderRadius: '0px' }}>
        <ModalHeader
          color={type === 'error' ? 'white' : 'horvath.red'}
          bg={type === 'error' ? 'horvath.red' : null}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color={type === 'error' ? 'white' : null} />
        <ModalBody>{infoText}</ModalBody>
        <ModalFooter>
          <Button
            text={t('global.okButton')}
            color="primary"
            action={onClose}
            style={{ marginRight: '5px' }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
