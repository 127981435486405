/*
 * Renders the admin view to control companies
 */
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
  isMobile,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import AddCompanyModal from '../components/AddCompanyModal';
import AdminEditCompanyModal from '../components/AdminEditCompanyModal';
import DeletePopover from '../components/DeletePopover';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import GlobalFilteringTables from '../components/inputs/GlobalFilteringTables';
import {
  deleteCompany,
  fetchAllCompanies,
  selectAdminCompanyError,
  selectAdminCompanyStatus,
  selectAllCompanies,
} from '../slices/adminCompanySlice';

const AdminCompanyOverview = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const allCompanies = useSelector(selectAllCompanies);
  const allCompaniesStatus = useSelector(selectAdminCompanyStatus);
  const allCompaniesError = useSelector(selectAdminCompanyError);
  /**
   * Fetches data of all companies
   */
  const fetchAllCompaniesData = () => {
    dispatch(fetchAllCompanies());
  };
  useEffect(() => {
    fetchAllCompaniesData();
  }, [dispatch]);

  const data = React.useMemo(() => allCompanies);

  const toast = useToast();

  /**
   * Deletes a selected company and opens Toaster if request failed
   */
  const deleteCompanyCheck = (company) => {
    dispatch(deleteCompany(company.id))
      .then(unwrapResult)
      .then(() => {
        toast({
          title: t('adminCompanyOverview.successDeleteCompany.title'),
          description: t('adminCompanyOverview.successDeleteCompany.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
        dispatch(fetchAllCompanies());
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 422') {
          toast({
            title: t('errors.adminCompanyOverview.deleteCompanyNoUserFailed.title'),
            description: t('errors.adminCompanyOverview.deleteCompanyNoUserFailed.description'),
            status: 'error',
            duration: 8000,
            position: 'top-right',
            isClosable: true,
          });
        } else {
          toast({
            title: t('errors.adminCompanyOverview.deleteCompanyFailed.title'),
            description: t('errors.adminCompanyOverview.deleteCompanyFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        }
      });
  };

  /**
   * Displays icons for editing or deleting a company
   * @returns Icon for editing or deleting an company
   */
  const companyActionList = (company) => {
    return (
      <HStack spacing="1">
        <AdminEditCompanyModal company={company} />
        <DeletePopover
          headerText={t('adminCompanyOverview.deletePopver.headerText')}
          confirmationText={t('adminCompanyOverview.deletePopver.confirmationText')}
          confirmationFunction={() => deleteCompanyCheck(company)}
        />
      </HStack>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('adminCompanyOverview.tableHeader.shortName'),
        accessor: 'shortName',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.completeName'),
        accessor: 'name',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.industryLvl1'),
        accessor: 'industryLevel1',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.industryLvl2'),
        accessor: 'industryLevel2',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.industryLvl3'),
        accessor: 'industryLevel3',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.country'),
        accessor: 'countryCode',
      },
      {
        Header: t('adminCompanyOverview.tableHeader.subscription'),
        accessor: 'subscription',
      },
      {
        Header: ' ',
        accessor: (company) => companyActionList(company),
        disableSortBy: true,
      },
    ],
    [allCompanies, i18n.language],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
        pageIndex: 0,
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  /**
   * Renders the companies table
   */
  const renderCompanyTable = () => {
    if (allCompaniesStatus === 'succeeded') {
      return (
        <>
          <HStack>
            <Box width="30%">
              <GlobalFilteringTables
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholderInputSearch={t('adminCompanyOverview.searchBar.placeholder')}
              />
            </Box>
            <Box minWidth="16%">
              <AddCompanyModal />
            </Box>
          </HStack>
          <Box spacing={1} mt={2} pt={3}>
            <Table {...getTableProps()} variant="striped" size="sm">
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        isNumeric={column.isNumeric}
                      >
                        {column.render('Header')}
                        <chakra.span pl="4">
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box
            px={{
              base: '4',
              md: '6',
            }}
            pb="5"
          >
            <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="muted" fontSize="sm">
                  {t('global.page')} {pageIndex + 1} {t('global.of')} {pageOptions.length}
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{
                  base: 'full',
                  md: 'auto',
                }}
                variant="secondary"
              >
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {t('global.previousPageButton')}
                </Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  {t('global.nextPageButton')}
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </>
      );
    }
    return null;
  };
  return (
    <>
      <PageHeader title={t('adminCompanyOverview.pageHeader')} />
      <br />
      {allCompaniesStatus === 'loading' ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      {allCompaniesStatus === 'failed' ? (
        <ErrorStat
          errorMessage={allCompaniesError}
          onRefresh={fetchAllCompaniesData}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      ) : null}
      {renderCompanyTable()}
    </>
  );
};

export default AdminCompanyOverview;
