/*
 * Renders the benchmarking input card component
 */
import { AccordionItem, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './cards/Card';
import CardHeader from './cards/CardHeader';
import FormControl from './inputs/FormControl';
import FormProperty from './inputs/FormProperty';

/**
 * Displays a benchmarking input card, which contains inputs for fte and total costs
 * @returns An AccordionItem with a card which contains two input fields
 */
const BenchmarkingInputCard = React.memo((props) => {
  const {
    titleText,
    tooltipText,
    fteState,
    setFteState,
    fteTestName,
    fteDisabled,
    costState,
    setCostState,
    costTestName,
    costDisabled,
    onClickFteState,
    onClickCostState,
  } = props;
  const { t } = useTranslation();

  return (
    <AccordionItem p={2}>
      <Card>
        <CardHeader title={titleText} tooltipText={tooltipText} />
        <FormControl>
          <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
            <FormProperty
              label={t('global.fte')}
              formType="number-input"
              disabled={fteDisabled}
              value={fteState}
              onValueChange={setFteState}
              testName={fteTestName}
              onClick={onClickFteState}
            />
            <FormProperty
              label={t('global.costs')}
              formType="currency-input"
              value={costState}
              disabled={costDisabled}
              onValueChange={setCostState}
              testName={costTestName}
              onClick={onClickCostState}
            />
          </Stack>
        </FormControl>
      </Card>
    </AccordionItem>
  );
});

export default BenchmarkingInputCard;
