/**
 * Validates if a string has the correct email format
 * @param {String} email A string that should be an email
 * @returns true if the provided string is an email, false otherwise
 */
const validateEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export default validateEmail;
