/*
 * Slice which provides reducer and states of the industry levels state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import industryLevelData from '../_data/industryLevel_data';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

/**
 * Fetches the industryLevel
 */
export const fetchIndustryLevels = createAsyncThunk('industryLevels/fetchIndustryLevels', () => {
  return industryLevelData.industryLevels;
});

/**
 * The slice of the industryLevel state
 */
export const industryLevelsSlice = createSlice({
  name: 'industryLevels',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchIndustryLevels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIndustryLevels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Insert fetched industry levels into data
        state.data = action.payload;
      })
      .addCase(fetchIndustryLevels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select all industry levels
 */
export const selectAllIndustryLevels = (state) => state.industryLevels.data;

/**
 * Select the status of the industry levels state
 */
export const selectIndustryLevelsStatus = (state) => state.industryLevels.status;

/**
 * Select the error messages of the industry levels state
 */
export const selectIndustryLevelsError = (state) => state.industryLevels.error;

export default industryLevelsSlice.reducer;
