/**
 * Holds all industry levels as an array
 */
const industryLevelData = {
  industryLevels: [
    {
      level1: [
        {
          name: 'Automotive',
          value: 'AUTOMOTIVE',
        },
      ],
      level2: [
        {
          name: 'OEM',
          value: 'OEM',
        },
        {
          name: 'Producer',
          value: 'PRODUCER',
        },
        {
          name: 'Aftermarket',
          value: 'AFTERMARKET',
        },
        {
          name: 'Supplier',
          value: 'SUPPLIER',
        },
      ],
      level3: [
        {
          name: 'Production',
          value: 'PRODUCTION',
        },
        {
          name: 'Services',
          value: 'SERVICES',
        },
        {
          name: 'Commerce',
          value: 'COMMERCE',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Chemicals, Oil & Pharma',
          value: 'CHEMICALS_AND_PHARMA',
        },
      ],
      level2: [
        {
          name: 'Chemicals',
          value: 'CHEMICALS',
        },
        {
          name: 'Oil / Gas',
          value: 'OIL_GAS',
        },
        {
          name: 'Pharma',
          value: 'PHARMA',
        },
      ],
      level3: [
        {
          name: 'Production',
          value: 'PRODUCTION',
        },
        {
          name: 'Services',
          value: 'SERVICES',
        },
        {
          name: 'Commerce',
          value: 'COMMERCE',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Construction & Real Estate Management',
          value: 'CONSTRUCTION_AND_REALESTATE',
        },
      ],
      level2: [
        {
          name: 'Construction / Facility Management',
          value: 'CONSTRUCTION',
        },
        {
          name: 'Real Estate Management',
          value: 'REALESTATE',
        },
      ],
      level3: [
        {
          name: 'Production',
          value: 'PRODUCTION',
        },
        {
          name: 'Services',
          value: 'SERVICES',
        },
        {
          name: 'Commerce',
          value: 'COMMERCE',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Consumer & Industrial Goods',
          value: 'CONSUMER_AND_INDUSTRIAL',
        },
      ],
      level2: [
        {
          name: 'Consumer Goods',
          value: 'CONSUMER_GOODS',
        },
        {
          name: 'Industrial Goods',
          value: 'INDUSTRIAL_GOODS',
        },
      ],
      level3: [
        {
          name: 'Production',
          value: 'PRODUCTION',
        },
        {
          name: 'Services',
          value: 'SERVICES',
        },
        {
          name: 'Commerce',
          value: 'COMMERCE',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Financial Industries',
          value: 'FINANCIAL_INDUSTRIES',
        },
      ],
      level2: [
        {
          name: 'Audit',
          value: 'AUDIT',
        },
        {
          name: 'Banking',
          value: 'BANKING',
        },
        {
          name: 'Insurance',
          value: 'INSURANCE',
        },
        {
          name: 'Others',
          value: 'OTHERS',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Media & Telecom',
          value: 'MEDIA_AND_TELECOM',
        },
      ],
      level2: [
        {
          name: 'Media',
          value: 'MEDIA',
        },
        {
          name: 'Telecommunication',
          value: 'TELECOM',
        },
        {
          name: 'Others',
          value: 'OTHERS',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
        {
          name: 'Commerce',
          value: 'COMMERCE',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Public Management',
          value: 'PUBLIC_MANAGEMENT',
        },
      ],
      level2: [
        {
          name: 'Public Organization',
          value: 'PUBLIC_ORGANIZATION',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Technology & IT',
          value: 'TECHNOLOGY',
        },
      ],
      level2: [
        {
          name: 'IT/Software',
          value: 'IT',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Transportation, Travel & Logistics',
          value: 'TRANSPORT_TRAVEL_AND_LOGISTICS',
        },
      ],
      level2: [
        {
          name: 'Transportation',
          value: 'TRANSPORTATION',
        },
        {
          name: 'Travel',
          value: 'TRAVEL',
        },
        {
          name: 'Logistics',
          value: 'LOGISTICS',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
      ],
    },
    {
      level1: [
        {
          name: 'Utilities',
          value: 'UTILITIES',
        },
      ],
      level2: [
        {
          name: 'Power Supply',
          value: 'POWER_SUPPLY',
        },
        {
          name: 'Water Supply',
          value: 'WATER_SUPPLY',
        },
        {
          name: 'Waste Management',
          value: 'WASTE_MANAGEMENT',
        },
        {
          name: 'Utilities',
          value: 'UTILITIES',
        },
      ],
      level3: [
        {
          name: 'Services',
          value: 'SERVICES',
        },
      ],
    },
  ],
};

export default industryLevelData;
