/*
 * Renders the Benchmarkings view
 */
import { AddIcon } from '@chakra-ui/icons';
import { Center, Spinner, Stack, StackDivider, VStack, useToast } from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ContentWrapper from '../components/ContentWrapper';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import BenchmarkingCard from '../components/cards/BenchmarkingCard';
import InfoProperty from '../components/inputs/InfoProperty';
import { createBenchmarkingCompanyInfo } from '../slices/benchmarkingCompanyInfoSlice';
import {
  fetchBenchmarkings,
  selectBenchmarkings,
  selectBenchmarkingsError,
  selectBenchmarkingsStatus,
  selectNewestBenchmarking,
} from '../slices/benchmarkingsSlice';
import { selectCompanyId, selectCompanyStatus } from '../slices/companySlice';
import valueManipulation from '../utils/valueManipulation';

/**
 * Displays overview over all benchmarkings of the last years
 * @returns Cards with the overall information of the different benchmarkings
 */
const Benchmarkings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const benchmarkings = useSelector(selectBenchmarkings);
  const yearOfNewestBenchmarking = useSelector(selectNewestBenchmarking);

  const companyId = useSelector(selectCompanyId);

  const companyStatus = useSelector(selectCompanyStatus);
  const benchmarkingsStatus = useSelector(selectBenchmarkingsStatus);
  const benchmarkingsError = useSelector(selectBenchmarkingsError);

  const toast = useToast();

  React.useEffect(() => {
    if (companyStatus === 'succeeded') dispatch(fetchBenchmarkings({ companyId }));
  }, [companyStatus, dispatch]);

  /**
   * Refetches the benchmarking data
   */
  const refetchBenchmarkings = () => {
    dispatch(fetchBenchmarkings({ companyId }));
  };

  /**
   * Renders a benchmarking if fetching was succesful
   * @returns The benchmarking, a spinner or an error depending on the benchmarking fetch status
   */
  const renderBenchmarkings = () => {
    if (benchmarkingsStatus === 'loading') {
      return (
        <Center>
          <Spinner size="xl" />
        </Center>
      );
    }
    if (benchmarkingsStatus === 'succeeded') {
      return (
        <>
          {benchmarkings.map((benchmarking) => (
            <BenchmarkingCard
              name={`Benchmarking ${benchmarking.year}`}
              destination={`/benchmarking/${benchmarking.id}`}
              key={benchmarking.year}
            >
              <Stack
                direction={{ base: 'column', md: 'column', lg: 'column', xl: 'row' }}
                divider={<StackDivider borderColor="gray.100" />}
              >
                <InfoProperty
                  label={t('benchmarkings.labelControlling')}
                  value={
                    benchmarking.sumCostControlling
                      ? `${valueManipulation.addWhitespaces(benchmarking.sumCostControlling)} €`
                      : '- €'
                  }
                />
                <InfoProperty
                  label={t('benchmarkings.labelFinance')}
                  value={
                    benchmarking.sumCostFinance
                      ? `${valueManipulation.addWhitespaces(benchmarking.sumCostFinance)} €`
                      : '- €'
                  }
                />
              </Stack>
            </BenchmarkingCard>
          ))}
        </>
      );
    }
    if (benchmarkingsStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingsError}
          onRefresh={refetchBenchmarkings}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    return null;
  };

  /**
   * Calls the async thunk to create a new benchmarking
   */
  const createBenchmarking = () => {
    dispatch(createBenchmarkingCompanyInfo())
      .then(unwrapResult)
      .then((res) => {
        history.push(`/benchmarking/${res.id}`);
        toast({
          title: t('benchmarkings.success.title'),
          description: t('benchmarkings.success.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t('errors.benchmarkings.createBenchmarkingFailed.title'),
          description: t('errors.benchmarkings.createBenchmarkingFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Checks if there already is a benchmarking of the current year
   *
   * @returns whether a new benchmarking for the current year can be created
   */
  const canNewBenchmarkingBeCreated = () => {
    const currentYear = new Date().getFullYear();
    return yearOfNewestBenchmarking !== currentYear;
  };

  return (
    <>
      <PageHeader title={t('benchmarkings.title')} />
      <ContentWrapper>
        <VStack>
          {canNewBenchmarkingBeCreated() ? (
            <BenchmarkingCard icon={<AddIcon />} onClick={createBenchmarking}>
              <InfoProperty label={t('benchmarkings.createBenchmarkingButton')} />
            </BenchmarkingCard>
          ) : null}
          {renderBenchmarkings()}
        </VStack>
      </ContentWrapper>
    </>
  );
};

export default Benchmarkings;
