/*
 * Renders the content wrapper component
 */
import { Box } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Dsiplays a centered box
 * @returns A box, that wrapps around specified child
 * components and centers them
 */
const ContentWrapper = (props) => {
  const { width, children } = props;
  if (width === 'wide') {
    return (
      <Box as="section" p="10">
        <Box
          maxW="9xl"
          mx="auto"
          px={{
            base: '6',
            md: '8',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  }
  return (
    <Box as="section" p="10">
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: '6',
          md: '8',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentWrapper;
