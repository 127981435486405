/**
 * This component renders discreete benchmarkings and KPIs in one column representing the values of
 * the benchmarkings and KPIs and adds a marker which displays the selection of the own company
 */
import * as React from 'react';

import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@chakra-ui/react';

// Enforce the styling trough a theme since the needed properties can not be accessed directly
const selectionChartTheme = {
  annotations: {
    text: {
      fontSize: 16,
      fontWeight: 'bold',
      outlineWidth: 2,
      outlineColor: '#ffffff',
      fill: 'rgba(20, 230, 215, 1)',
    },
    // Remove the Link which was rendered as a weird line
    link: {
      strokeWidth: 0,
      outlineWidth: 0,
    },
    outline: {
      fill: 'none',
      stroke: 'rgba(20, 230, 215, 1)',
      strokeWidth: 3,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
  },
};

/**
 * Generates the keyArray so nivo can display the bar diagramm correctly
 * @param {Array[Object]} dataArray Array with one object containing the key and percentile of the
 * selections
 * @returns {Array[String]} Keys used by the chart to display diagrams
 */
const GenerateKeys = (dataArray) => {
  const displayKeys = Object.keys(dataArray[0]);
  // Ommit the first key since it is used by nivo to index the chart data
  displayKeys.shift();
  return displayKeys;
};

// The different horvath colors for the chart
const COLORS = ['#008CC8', '#05415A', '#4A4A4A'];

/**
 * Renders the percentile of discreete benchmarkings and KPIs
 * @param {string} legendTextLeft Determines the text for the y-axis
 * @param {Strig} companyName Name of the company of the logged in user
 * @param {Array} selectionData Array with one object containing the key and percentile of the
 * selections and kind [{"kind" : "selection", "key1" : Number, "key2" : Number ...}]
 * @param {String} companySelectedValue Value the surveyed company has entered in a category
 * @param {String} type Type of selection chart default is horizontal
 * @param {Array} labels Array of the labels of the selectionData that should be displayed in the legend
 */
const SelectionChart = (props) => {
  const { legendTextLeft, companyName, selectionData, companySelectedValue, type, labels } = props;
  if (type === 'vertical') {
    return (
      <Box w="900px" h="500px">
        <p>{legendTextLeft}</p>
        <ResponsiveBar
          theme={selectionChartTheme}
          data={selectionData}
          // Extract the visualization keys from the data object to allow charts with a variing
          // number of values
          keys={GenerateKeys(selectionData)}
          indexBy="kind"
          margin={{ top: 50, right: 550, bottom: 50, left: 60 }}
          padding={0.3}
          maxValue={100}
          layout="vertical"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          isInteractive={false}
          colors={COLORS}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          enableGridY={false}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: 0,
            tickRotation: 0,
            renderTick: () => '', // for whatever reason it needs to be a function
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={['#fff']}
          legends={[
            {
              dataFrom: 'keys',
              data: GenerateKeys(selectionData).map((id, index) => {
                return { color: COLORS[index % 3], id, label: labels[index] };
              }),
              anchor: 'right',
              direction: 'column',
              justify: false,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 7,
              itemWidth: 0,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              symbolSize: 15,
            },
          ]}
          // Represents the benchmarking value of the own company as horizontal line
          annotations={[
            {
              type: 'rect',
              match: { key: `${companySelectedValue}.selection` },
              noteX: 0,
              noteY: 90,
              noteWidth: 0,
              note: companyName,
              size: 40,
            },
          ]}
        />
      </Box>
    );
  }
  return (
    <Box w="900px" h="500px" mt={5}>
      <p>{legendTextLeft}</p>
      <ResponsiveBar
        theme={selectionChartTheme}
        data={selectionData}
        // Extract the visualization keys from the data object to allow charts with a variing
        // number of values
        keys={GenerateKeys(selectionData)}
        indexBy="kind"
        margin={{ top: 0, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        maxValue={100}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        isInteractive={false}
        colors={COLORS}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          renderTick: () => '', // for whatever reason it needs to be a function
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={['#fff']}
        legends={[
          {
            dataFrom: 'keys',
            data: GenerateKeys(selectionData).map((id, index) => {
              return {
                color: COLORS[index % 3],
                id,
                label: labels[index],
              };
            }),
            anchor: 'top',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 50,
            itemsSpacing: 7,
            itemWidth: 200,
            itemHeight: 15,
            itemDirection: 'left-to-right',
            symbolSize: 12,
          },
        ]}
        // Represents the benchmarking value of the own company as horizontal line
        annotations={[
          {
            type: 'rect',
            match: { key: `${companySelectedValue}.selection` },
            noteX: 0,
            noteY: 90,
            noteWidth: 0,
            note: companyName,
            size: 40,
          },
        ]}
      />
    </Box>
  );
};

export default SelectionChart;
