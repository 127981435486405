/*
 * Renders a multiple choice option
 */
import * as React from 'react';
import { Checkbox, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

/**
 * Displays a multiple choice option consisting of the option name and two checkboxes
 * @returns Two checkboxes and the option name
 */
const MultipleChoiceOption = ({ optionName, optionValue, onChange, disabled }) => {
  const { t } = useTranslation();
  const [isYes, setIsYes] = React.useState(optionValue === null ? false : optionValue);
  const [isNo, setIsNo] = React.useState(optionValue === null ? false : !optionValue);

  /**
   * Handles the value change of the yes checkbox
   * @param {boolean} value of the yes checkbox
   */
  const changeYesValue = (value) => {
    setIsYes(value);
    // If the value for yes is set to true, no is set to false and the database value is set to true
    if (value) {
      setIsNo(false);
      onChange(true);
    }
    // if after the change yes and no are both false, the database value is null
    if (!value && !isNo) {
      onChange(null);
    }
  };

  /**
   * Handles the value change of the no checkbox
   * @param {boolean} value of the no checkbox
   */
  const changeNoValue = (value) => {
    setIsNo(value);
    // If the value for no is set to true, yes is set to false and the database value is set to false
    if (value) {
      setIsYes(false);
      onChange(false);
    }
    // if after the change yes and no are both false, the database value is null
    if (!isYes && !value) {
      onChange(null);
    }
  };

  return (
    <>
      <Text>{optionName}</Text>
      <Stack pl={6} spacing={2}>
        <Checkbox
          isChecked={isYes}
          defaultChecked={optionValue}
          onChange={(e) => changeYesValue(e.target.checked)}
          isDisabled={disabled}
        >
          {t('global.multipleChoiceOptions.yes')}
        </Checkbox>
        <Checkbox
          isChecked={isNo}
          defaultChecked={optionValue}
          onChange={(e) => changeNoValue(e.target.checked)}
          isDisabled={disabled}
        >
          {t('global.multipleChoiceOptions.no')}
        </Checkbox>
      </Stack>
    </>
  );
};

export default MultipleChoiceOption;
