/*
 * Renders the FinanceBenchmarking view
 */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import BenchmarkingInputCard from '../components/BenchmarkingInputCard';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import Disable from '../components/Disable';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import SliderPropery from '../components/inputs/SliderProperty';
import {
  editBenchmarkingFinance,
  fetchBenchmarkingFinance,
  selectBenchmarkingFinanceData,
  selectBenchmarkingFinanceError,
  selectBenchmarkingFinanceStatus,
} from '../slices/benchmarkingFinanceSlice';
import {
  fetchCompany,
  // selectCompanyError,
  selectCompanyStatus,
} from '../slices/companySlice';
import {
  deactivateWarning,
  selectWarningBenchmarkingFinance,
} from '../slices/warningBenchmarkingFinanceSlice';
import i18n from '../translations/i18n';
/**
 * Displays cards for process and single processes with input fields for different finance KPIs
 * @returns Cards with input fields for different finance KPIs of the process and single processes
 */
const FinanceBenchmarking = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const companyStatus = useSelector(selectCompanyStatus);
  // const companyError = useSelector(selectCompanyError);

  const benchmarkingFinance = useSelector(selectBenchmarkingFinanceData);
  const benchmarkingStatus = useSelector(selectBenchmarkingFinanceStatus);
  const benchmarkingError = useSelector(selectBenchmarkingFinanceError);

  const warningBenchmarkingFinance = useSelector(selectWarningBenchmarkingFinance);

  const toast = useToast();

  // Extra load manual activity options
  const extraLoadManualActivityOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.veryHigh',
      ),
      value: 'HIGH',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.significant',
      ),
      value: 'SIGNIFICANT',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.noticeable',
      ),
      value: 'NOTICEABLE',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.options.none',
      ),
      value: 'NONE',
    },
  ];
  // Quality data reporting options
  const qualityDataReportingOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.veryPoor',
      ),
      value: 'BAD',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.unsatisfactory',
      ),
      value: 'UNSATISFACTORY',
    },
    {
      name: t('finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.ok'),
      value: 'OK',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityDataReporting.options.veryGood',
      ),
      value: 'GOOD',
    },
  ];
  // Quality data process options
  const qualityDataProcessOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.veryPoor',
      ),
      value: 'BAD',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.unsatisfactory',
      ),
      value: 'UNSATISFACTORY',
    },
    {
      name: t('finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.ok'),
      value: 'OK',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.options.veryGood',
      ),
      value: 'GOOD',
    },
  ];
  // Challenge group accounting options
  const integrationLegalManagementOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.none',
      ),
      value: 'NONE',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.little',
      ),
      value: 'LITTLE',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.dataModel',
      ),
      value: 'INTEGRATED',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.options.fully',
      ),
      value: 'FULL',
    },
  ];
  // Generation consolidated numbers options
  const generationConsolidatedNumbersOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.one',
      ),
      value: 'ONE',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.two',
      ),
      value: 'TWO',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.more',
      ),
      value: 'MORE_THAN_TWO',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.options.noAnswer',
      ),
      value: 'NO_ANSWER',
    },
  ];
  // Challenge group accounting options
  const challengeGroupAccountingOptions = [
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.IFRS',
      ),
      value: 'IFRS_STANDARDS',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.moreInfo',
      ),
      value: 'MORE_INFO',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.euTax',
      ),
      value: 'EU_TAXONOMY',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.closingDates',
      ),
      value: 'CLOSING_TIMES',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.processAutomation',
      ),
      value: 'PROCESS_AUTOMATION',
    },
    {
      name: t(
        'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.options.newSkills',
      ),
      value: 'SKILLS',
    },
  ];

  /**
   * State of the corresponding input fields
   */
  const [processFTE, setProcessFTE] = React.useState(benchmarkingFinance.sumFteFinance);
  const [processCosts, setProcessCosts] = React.useState(benchmarkingFinance.sumCostFinance);
  const [debtorsProcessFTE, setDebtorsProcessFTE] = React.useState(
    benchmarkingFinance.finDebitorsProcess ? benchmarkingFinance.finDebitorsProcess.fte : '',
  );
  const [debtorsProcessCosts, setDebtorsProcessCosts] = React.useState(
    benchmarkingFinance.finDebitorsProcess ? benchmarkingFinance.finDebitorsProcess.cost : '',
  );
  const [debtorsDurationDays, setDebtorsDurationDays] = React.useState(
    benchmarkingFinance.finDebtorsProcess ? benchmarkingFinance.finDebtorsProcess.durationDays : '',
  );
  const [debtorsPercentageShare, setDebtorsPercentageShare] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.percentageShare
      : '',
  );
  const [debtorsPercentageShareCustomer, setDebtorsPercentageShareCustomer] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.percentageShareCustomer
      : '',
  );
  const [debtorsNumberBill, setDebtorsNumberBill] = React.useState(
    benchmarkingFinance.finDebtorsProcess ? benchmarkingFinance.finDebtorsProcess.numberBill : '',
  );
  const [debtorsPercentageShareIncorrectBills, setDebtorsPercentageShareIncorrectBills] =
    React.useState(
      benchmarkingFinance.finDebtorsProcess
        ? benchmarkingFinance.finDebtorsProcess.debtorsPercentageShareIncorrectBills
        : '',
    );
  const [debtorsNumberBillPosition, setDebtorsNumberBillPosition] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.numberBillPosition
      : '',
  );
  const [debtorsTimeSpanUntilBill, setDebtorsTimeSpanUntilBill] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.timeSpanUntilBill
      : '',
  );
  const [debtorsPercentageShareElecBills, setDebtorsPercentageShareElecBills] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.percentageShareElecBills
      : '',
  );
  const [debtorsPercentageShareBillsReminder, setDebtorsPercentageShareBillsReminder] =
    React.useState(
      benchmarkingFinance.finDebtorsProcess
        ? benchmarkingFinance.finDebtorsProcess.percentageShareBillsReminder
        : '',
    );
  const [debtorsIncomingPayments, setDebtorsIncomingPayments] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.incomingPayments
      : '',
  );
  const [debtorsTimeSpanUntilClosingBusinessCase, setDebtorsTimeSpanUntilClosingBusinessCase] =
    React.useState(
      benchmarkingFinance.finDebtorsProcess
        ? benchmarkingFinance.finDebtorsProcess.timeSpanUntilClosingBusinessCase
        : '',
    );
  const [debtorsIncorrectPaymentAssignment, setDebtorsIncorrectPaymentAssignment] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.incorrectPaymentAssignment
      : '',
  );
  const [debtorsReceivablesTurnoverTime, setDebtorsReceivablesTurnoverTime] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.receivablesTurnoverTime
      : '',
  );
  const [debtorsOutsourcedReceivables, setDebtorsOutsourcedReceivables] = React.useState(
    benchmarkingFinance.finDebtorsProcess
      ? benchmarkingFinance.finDebtorsProcess.outsourcedReceivables
      : '',
  );
  const [consolidationProcessFTE, setConsolidationProcessFTE] = React.useState(
    benchmarkingFinance.finConsolidation ? benchmarkingFinance.finConsolidation.fte : '',
  );
  const [consolidationProcessCosts, setConsolidationProcessCosts] = React.useState(
    benchmarkingFinance.finConsolidation ? benchmarkingFinance.finConsolidation.cost : '',
  );
  const [consolJournalEntries, setConsolJournalEntries] = React.useState(
    benchmarkingFinance.finConsolidation ? benchmarkingFinance.finConsolidation.journalEntries : '',
  );
  const [consolDaysMonthlyClosing, setConsolDaysMonthlyClosing] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysMonthlyClosing
      : '',
  );
  const [
    consolDaysMonthlyClosingReleaseExternReporting,
    setConsolDaysMonthlyClosingReleaseExternReporting,
  ] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysMonthlyClosingReleaseExternReporting
      : '',
  );
  const [consolDaysQuarterlyReport, setConsolDaysQuarterlyReport] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysQuarterlyReport
      : '',
  );
  const [
    consolDaysQuarterlyReportReleaseExternReporting,
    setConsolDaysQuarterlyReportReleaseExternReporting,
  ] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysQuarterlyReportReleaseExternReporting
      : '',
  );
  const [consolDaysAnnualReport, setConsolDaysAnnualReport] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysAnnualReport
      : '',
  );
  const [
    consolDaysAnnualReportReleaseExternReporting,
    setConsolDaysAnnualReportReleaseExternReporting,
  ] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.daysAnnualReportReleaseExternReporting
      : '',
  );
  const [consolNumberConsolidatedLegalEntities, setConsolNumberConsolidatedLegalEntities] =
    React.useState(
      benchmarkingFinance.finConsolidation
        ? benchmarkingFinance.finConsolidation.numberConsolidatedLegalEntities
        : '',
    );
  const [consolFteConsolidation, setConsolFteConsolidation] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.fteConsolidation
      : '',
  );
  const [consolCostConsolidation, setConsolCostConsolidation] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.costConsolidation
      : '',
  );
  const [consolCompletionDataReporting, setConsolCompletionDataReporting] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.completionDataReporting
      : '',
  );
  const [consolMonthlyCompletion, setConsolMonthlyCompletion] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.consolMonthlyCompletion
      : '',
  );
  const [consolQuarterlyCompletion, setConsolQuarterlyCompletion] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.quarterlyCompletion
      : '',
  );
  const [consolAnnualCompletion, setConsolAnnualCompletion] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.annualCompletion
      : '',
  );
  const [consolExtraLoadManualActivity, setConsolExtraLoadManualActivity] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.extraLoadManualActivity
      : '',
  );
  const [consolQualityDataReporting, setConsolQualityDataReporting] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.qualityDataReporting
      : '',
  );
  const [consolQualityVotingProcess, setConsolQualityVotingProcess] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.qualityVotingProcess
      : '',
  );
  const [consolIntegrationLegalManagement, setConsolIntegrationLegalManagement] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.integrationLegalManagement
      : '',
  );
  const [consolGenerationConsolidatedNumbers, setConsolGenerationConsolidatedNumbers] =
    React.useState(
      benchmarkingFinance.finConsolidation
        ? benchmarkingFinance.finConsolidation.generationConsolidatedNumbers
        : '',
    );
  const [consolChallengeGroupAccounting, setConsolChallengeGroupAccounting] = React.useState(
    benchmarkingFinance.finConsolidation
      ? benchmarkingFinance.finConsolidation.challengeGroupAccounting
      : '',
  );
  const [creditorsProcessFTE, setCreditorsProcessFTE] = React.useState(
    benchmarkingFinance.finCreditorsProcess ? benchmarkingFinance.finCreditorsProcess.fte : '',
  );
  const [creditorsProcessCosts, setCreditorsProcessCosts] = React.useState(
    benchmarkingFinance.finCreditorsProcess ? benchmarkingFinance.finCreditorsProcess.cost : '',
  );
  const [creditorsDurationProcessingCreditors, setCreditorsDurationProcessingCreditors] =
    React.useState(
      benchmarkingFinance.finCreditorsProcess
        ? benchmarkingFinance.finCreditorsProcess.durationProcessingCreditors
        : '',
    );
  const [creditorsIncorrectOrders, setCreditorsIncorrectOrders] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.incorrectOrders
      : '',
  );
  const [creditorsSuppliersEdi, setCreditorsSuppliersEdi] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.suppliersEdi
      : '',
  );
  const [creditorsNumberOrders, setCreditorsNumberOrders] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.numberOrders
      : '',
  );
  const [creditorsNumberOrderPositions, setCreditorsNumberOrderPositions] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.numberOrderPositions
      : '',
  );
  const [creditorsAutomatedOrderEntry, setCreditorsAutomatedOrderEntry] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.automatedOrderEntry
      : '',
  );
  const [creditorsElectrSentOrders, setCreditorsElectrSentOrders] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.electrSentOrders
      : '',
  );
  const [creditorsNumberBills, setCreditorsNumberBills] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.numberBills
      : '',
  );
  const [creditorsDifferenceBillsOrders, setCreditorsDifferenceBillsOrders] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.differenceBillsOrders
      : '',
  );
  const [creditorsBillWithoutOrder, setCreditorsBillWithoutOrder] = React.useState(
    benchmarkingFinance.finCreditorsProcess
      ? benchmarkingFinance.finCreditorsProcess.billWithoutOrder
      : '',
  );
  const [creditorsIncorrectPaymentsCreditors, setCreditorsIncorrectPaymentsCreditors] =
    React.useState(
      benchmarkingFinance.finCreditorsProcess
        ? benchmarkingFinance.finCreditorsProcess.incorrectPaymentsCreditors
        : '',
    );
  const [creditorsTimeSpanBillincomingPayment, setCreditorsTimeSpanBillincomingPayment] =
    React.useState(
      benchmarkingFinance.finCreditorsProcess
        ? benchmarkingFinance.finCreditorsProcess.timeSpanBillincomingPayment
        : '',
    );
  const [assetMgtProcessFTE, setAssetMgtProcessFTE] = React.useState(
    benchmarkingFinance.finAssetManagement ? benchmarkingFinance.finAssetManagement.fte : '',
  );
  const [assetMgtProcessCosts, setAssetMgtProcessCosts] = React.useState(
    benchmarkingFinance.finAssetManagement ? benchmarkingFinance.finAssetManagement.cost : '',
  );
  const [assetMgtAssetsMasterData, setAssetMgtAssetMasterData] = React.useState(
    benchmarkingFinance.finAssetManagement
      ? benchmarkingFinance.finAssetManagement.assetsMasterData
      : '',
  );
  const [assetsMgtAssetsBookedProcessed, setAssetMgtAssetsBookedProcessed] = React.useState(
    benchmarkingFinance.finAssetManagement
      ? benchmarkingFinance.finAssetManagement.assetsBookedProcessed
      : '',
  );
  const [finChargesTravelFTE, setFinChargesTravelFTE] = React.useState(
    benchmarkingFinance.finChargesTravel ? benchmarkingFinance.finChargesTravel.fte : '',
  );
  const [finChargesTravelCosts, setFinChargesTravelCosts] = React.useState(
    benchmarkingFinance.finChargesTravel ? benchmarkingFinance.finChargesTravel.cost : '',
  );
  const [finChargesTravelPayroll, setFinChargesTravelPayroll] = React.useState(
    benchmarkingFinance.finChargesTravel ? benchmarkingFinance.finChargesTravel.payroll : '',
  );
  const [finChargesTravelEmployees, setFinChargesTravelEmployees] = React.useState(
    benchmarkingFinance.finChargesTravel ? benchmarkingFinance.finChargesTravel.employees : '',
  );
  const [finChargesTravelIncorrectPayrolls, setFinChargesTravelIncorrectPayrolls] = React.useState(
    benchmarkingFinance.finChargesTravel
      ? benchmarkingFinance.finChargesTravel.incorrectPayrolls
      : '',
  );
  const [finChargesTravelTravelExpenseReport, setFinChargesTravelTravelExpenseReport] =
    React.useState(
      benchmarkingFinance.finChargesTravel
        ? benchmarkingFinance.finChargesTravel.travelExpenseReport
        : '',
    );
  const [finChargesTravelProcessingDays, setFinChargesTravelProcessingDays] = React.useState(
    benchmarkingFinance.finChargesTravel ? benchmarkingFinance.finChargesTravel.processingDays : '',
  );
  const [finTaxProcessFTE, setFinTaxProcessFTE] = React.useState(
    benchmarkingFinance.finTax ? benchmarkingFinance.finTax.fte : '',
  );
  const [finTaxProcessCosts, setFinTaxProcessCosts] = React.useState(
    benchmarkingFinance.finTax ? benchmarkingFinance.finTax.cost : '',
  );
  const [finTaxReportingUnit, setFinTaxReportingUnit] = React.useState(
    benchmarkingFinance.finTax ? benchmarkingFinance.finTax.reportingUnit : '',
  );
  const [finTaxTaxDeclaration, setFinTaxTaxDeclaration] = React.useState(
    benchmarkingFinance.finTax ? benchmarkingFinance.finTax.taxDeclaration : '',
  );
  const [finTaxTaxDeclarationBalance, setFinTaxTaxDeclarationBalance] = React.useState(
    benchmarkingFinance.finTax ? benchmarkingFinance.finTax.taxDeclarationBalance : '',
  );
  const [finTreasuryProcessCosts, setFinTreasuryProcessCosts] = React.useState(
    benchmarkingFinance.finTreasury ? benchmarkingFinance.finTreasury.costs : '',
  );
  const [finTreasuryProcessFTE, setFinTreasuryProcessFTE] = React.useState(
    benchmarkingFinance.finTreasury ? benchmarkingFinance.finTreasury.fte : '',
  );
  const [treasuryAvgExternPaymentTransactions, setTreasuryAvgExternPaymentTransactions] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.avgExternPaymentTransactions
        : '',
    );
  const [treasuryAvgExternManualPayments, setTreasuryAvgExternManualPayments] = React.useState(
    benchmarkingFinance.finTreasury ? benchmarkingFinance.finTreasury.avgExternManualPayments : '',
  );
  const [treasuryAvgInternPayments, setTreasuryAvgInternPayments] = React.useState(
    benchmarkingFinance.finTreasury ? benchmarkingFinance.finTreasury.avgInternPayments : '',
  );
  const [treasuryEmployeesPaymentTransactions, setTreasuryEmployeesPaymentTransactions] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.employeesPaymentTransactions
        : '',
    );
  const [treasuryFinanceTransactionsConcluded, setTreasuryFinanceTransactionsConcluded] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.financeTransactionsConcluded
        : '',
    );
  const [
    treasuryInventoryTreasuryFinanceTransactions,
    setTreasuryInventoryTreasuryFinanceTransactions,
  ] = React.useState(
    benchmarkingFinance.finTreasury
      ? benchmarkingFinance.finTreasury.inventoryTreasuryFinanceTransactions
      : '',
  );
  const [
    treasuryAutomatedProcessingTreasuryTransactions,
    setTreasuryAutomatedProcessingTreasuryTransactions,
  ] = React.useState(
    benchmarkingFinance.finTreasury
      ? benchmarkingFinance.finTreasury.automatedProcessingTreasuryTransactions
      : '',
  );
  const [
    treasuryEmployeesTreasuryFinanceTransactionsCompleted,
    setTreasuryEmployeesTreasuryFinanceTransactionsCompleted,
  ] = React.useState(
    benchmarkingFinance.finTreasury
      ? benchmarkingFinance.finTreasury.employeesTreasuryFinanceTransactionsCompleted
      : '',
  );
  const [
    treasuryEmployeesProcessingTreasuryFinanceTransactions,
    setTreasuryEmployeesProcessingTreasuryFinanceTransactions,
  ] = React.useState(
    benchmarkingFinance.finTreasury
      ? benchmarkingFinance.finTreasury.employeesProcessingTreasuryFinanceTransactions
      : '',
  );
  const [treasuryEfficiency, setTreasuryEfficiency] = React.useState(
    benchmarkingFinance.finTreasury ? benchmarkingFinance.finTreasury.efficiency : '',
  );
  const [treasuryLiquidityPlanningHorizonMonths, setTreasuryLiquidityPlanningHorizonMonths] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.liquidityPlanningHorizonMonths
        : '',
    );
  const [treasuryLiquidityPlanningFrequenceMonths, setTreasuryLiquidityPlanningFrequenceMonths] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.liquidityPlanningFrequenceMonths
        : '',
    );
  const [treasuryLiquidityPlanningDurationDays, setTreasuryLiquidityPlanningDurationDays] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.liquidityPlanningDurationDays
        : '',
    );
  const [treasuryNumberEmployeesLiquidityPlanning, setTreasuryNumberEmployeesLiquidityPlanning] =
    React.useState(
      benchmarkingFinance.finTreasury
        ? benchmarkingFinance.finTreasury.numberConsolidatedLegalEntities
        : '',
    );
  const [treasuryAccuracyLiquidityPlanning, setTreasuryAccuracyLiquidityPlanning] = React.useState(
    benchmarkingFinance.finTreasury
      ? benchmarkingFinance.finTreasury.accuracyLiquidityPlanning
      : '',
  );
  const [finFurtherDevelopmentFinanceFTE, setFinFurtherDevelopmentFinanceFTE] = React.useState(
    benchmarkingFinance.finFurtherDevelopmentFinance
      ? benchmarkingFinance.finFurtherDevelopmentFinance.fte
      : '',
  );
  const [finFurtherDevelopmentFinanceCosts, setFinFurtherDevelopmentFinanceCosts] = React.useState(
    benchmarkingFinance.finFurtherDevelopmentFinance
      ? benchmarkingFinance.finFurtherDevelopmentFinance.cost
      : '',
  );

  const [currentProcessGranularity, setCurrentProcessGranularity] = React.useState(
    t('finance.benchmarking.singleProcesses.title'),
  );

  /*
   * UseEffect is needed because when the language changes the state currentProcessGranularity must be
   * "manually" translated to the new language (this is not automatically done)
   */
  React.useEffect(() => {
    switch (currentProcessGranularity) {
      case 'Einzelprozesse':
      case 'Single Processes': {
        setCurrentProcessGranularity(t('finance.benchmarking.singleProcesses.title'));
        break;
      }
      case 'Gesamtprozess':
      case 'Total Process': {
        setCurrentProcessGranularity(t('finance.benchmarking.totalProcess.title'));
        break;
      }
      default: {
        setCurrentProcessGranularity(t('finance.benchmarking.singleProcesses.title'));
        break;
      }
    }
  }, [i18n.language]);

  // Error messages for invalid inputs from the user
  const [errorMsgDebtorsDurationDays, setErrorMsgDebtorsDurationDays] = React.useState('');
  const [errorMsgDebtorsPercentageShare, setErrorMsgDebtorsPercentageShare] = React.useState('');
  const [errorMsgDebtorsPercentageShareCustomer, setErrorMsgDebtorsPercentageShareCustomer] =
    React.useState('');
  const [errorMsgDebtorsNumberBill, setErrorMsgDebtorsNumberBill] = React.useState('');
  const [
    errorMsgDebtorsPercentageShareIncorrectBills,
    setErrorMsgDebtorsPercentageShareIncorrectBills,
  ] = React.useState('');
  const [errorMsgDebtorsNumberBillPosition, setErrorMsgDebtorsNumberBillPosition] =
    React.useState('');
  const [errorMsgDebtorsTimeSpanUntilBill, setErrorMsgDebtorsTimeSpanUntilBill] =
    React.useState('');
  const [errorMsgDebtorsPercentageShareElecBills, setErrorMsgDebtorsPercentageShareElecBills] =
    React.useState('');
  const [
    errorMsgDebtorsPercentageShareBillsReminder,
    setErrorMsgDebtorsPercentageShareBillsReminder,
  ] = React.useState('');
  const [errorMsgDebtorsIncomingPayments, setErrorMsgDebtorsIncomingPayments] = React.useState('');
  const [
    errorMsgDebtorsTimeSpanUntilClosingBusinessCase,
    setErrorMsgDebtorsTimeSpanUntilClosingBusinessCase,
  ] = React.useState('');
  const [errorMsgDebtorsIncorrectPaymentAssignment, setErrorMsgDebtorsIncorrectPaymentAssignment] =
    React.useState('');
  const [errorMsgDebtorsReceivablesTurnoverTime, setErrorMsgDebtorsReceivablesTurnoverTime] =
    React.useState('');
  const [errorMsgDebtorsOutsourcedReceivables, setErrorMsgDebtorsOutsourcedReceivables] =
    React.useState('');
  const [
    errorMsgCreditorsDurationProcessingCreditors,
    setErrorMsgCreditorsDurationProcessingCreditors,
  ] = React.useState('');
  const [errorMsgCreditorsIncorrectOrders, setErrorMsgCreditorsIncorrectOrders] =
    React.useState('');
  const [errorMsgCreditorsSuppliersEdi, setErrorMsgCreditorsSuppliersEdi] = React.useState('');
  const [errorMsgCreditorsNumberOrders, setErrorMsgCreditorsNumberOrders] = React.useState('');
  const [errorMsgCreditorsNumberOrderPositions, setErrorMsgCreditorsNumberOrderPositions] =
    React.useState('');
  const [errorMsgCreditorsAutomatedOrderEntry, setErrorMsgCreditorsAutomatedOrderEntry] =
    React.useState('');
  const [errorMsgCreditorsElectrSentOrders, setErrorMsgCreditorsElectrSentOrders] =
    React.useState('');
  const [errorMsgCreditorsNumberBills, setErrorMsgCreditorsNumberBills] = React.useState('');
  const [errorMsgCreditorsDifferenceBillsOrders, setErrorMsgCreditorsDifferenceBillsOrders] =
    React.useState('');
  const [errorMsgCreditorsBillWithoutOrder, setErrorMsgCreditorsBillWithoutOrder] =
    React.useState('');
  const [
    errorMsgCreditorsIncorrectPaymentsCreditors,
    setErrorMsgCreditorsIncorrectPaymentsCreditors,
  ] = React.useState('');
  const [
    errorMsgCreditorsTimeSpanBillincomingPayment,
    setErrorMsgCreditorsTimeSpanBillincomingPayment,
  ] = React.useState('');
  const [errorMsgAssetMgtAssetsMasterData, setErrorMsgAssetMgtAssetsMasterData] =
    React.useState('');
  const [errorMsgAssetMgtAssetsBookedProcessed, setErrorMsgAssetMgtAssetsBookedProcessed] =
    React.useState('');
  const [errorMsgFinChargesTravelPayroll, setErrorMsgFinChargesTravelPayroll] = React.useState('');
  const [errorMsgFinChargesTravelEmployees, setErrorMsgFinChargesTravelEmployees] =
    React.useState('');
  const [errorMsgFinChargesTravelIncorrectPayrolls, setErrorMsgFinChargesTravelIncorrectPayrolls] =
    React.useState('');
  const [
    errorMsgFinChargesTravelTravelExpenseReport,
    setErrorMsgFinChargesTravelTravelExpenseReport,
  ] = React.useState('');
  const [errorMsgFinChargesTravelProcessingDays, setErrorMsgFinChargesTravelProcessingDays] =
    React.useState('');
  const [errorMsgFinTaxReportingUnit, setErrorMsgFinTaxReportingUnit] = React.useState('');
  const [errorMsgFinTaxTaxDeclaration, setErrorMsgFinTaxTaxDeclaration] = React.useState('');
  const [errorMsgFinTaxTaxDeclarationBalance, setErrorMsgFinTaxTaxDeclarationBalance] =
    React.useState('');
  const [errorMsgConsolJournalEntries, setErrorMsgConsolJournalEntries] = React.useState('');
  const [errorMsgConsolDaysMonthlyClosing, setErrorMsgConsolDaysMonthlyClosing] =
    React.useState('');
  const [
    errorMsgConsolDaysMonthlyClosingReleaseExternReporting,
    setErrorMsgConsolDaysMonthlyClosingReleaseExternReporting,
  ] = React.useState('');
  const [errorMsgConsolDaysQuarterlyReport, setErrorMsgConsolDaysQuarterlyReport] =
    React.useState('');
  const [
    errorMsgConsolDaysQuarterlyReportReleaseExternReporting,
    setErrorMsgConsolDaysQuarterlyReportReleaseExternReporting,
  ] = React.useState('');
  const [errorMsgConsolDaysAnnualReport, setErrorMsgConsolDaysAnnualReport] = React.useState('');
  const [
    errorMsgConsolDaysAnnualReportReleaseExternReporting,
    setErrorMsgConsolDaysAnnualReportReleaseExternReporting,
  ] = React.useState('');
  const [
    errorMsgConsolNumberConsolidatedLegalEntities,
    setErrorMsgConsolNumberConsolidatedLegalEntities,
  ] = React.useState('');
  const [errorMsgConsolFteConsolidation, setErrorMsgConsolFteConsolidation] = React.useState('');
  const [errorMsgConsolCostConsolidation, setErrorMsgConsolCostConsolidation] = React.useState('');
  const [errorMsgConsolCompletionDataReporting, setErrorMsgConsolCompletionDataReporting] =
    React.useState('');
  const [errorMsgConsolMonthlyCompletion, setErrorMsgConsolMonthlyCompletion] = React.useState('');
  const [errorMsgConsolQuarterlyCompletion, setErrorMsgConsolQuarterlyCompletion] =
    React.useState('');
  const [errorMsgConsolAnnualCompletion, setErrorMsgConsolAnnualCompletion] = React.useState('');
  const [
    errorMsgTreasuryAvgExternPaymentTransactions,
    setErrorMsgTreasuryAvgExternPaymentTransactions,
  ] = React.useState('');
  const [errorMsgTreasuryAvgExternManualPayments, setErrorMsgTreasuryAvgExternManualPayments] =
    React.useState('');
  const [errorMsgTreasuryAvgInternPayments, setErrorMsgTreasuryAvgInternPayments] =
    React.useState('');
  const [
    errorMsgTreasuryEmployeesPaymentTransactions,
    setErrorMsgTreasuryEmployeesPaymentTransactions,
  ] = React.useState('');
  const [
    errorMsgTreasuryFinanceTransactionsConcluded,
    setErrorMsgTreasuryFinanceTransactionsConcluded,
  ] = React.useState('');
  const [
    errorMsgTreasuryInventoryTreasuryFinanceTransactions,
    setErrorMsgTreasuryInventoryTreasuryFinanceTransactions,
  ] = React.useState('');
  const [
    errorMsgTreasuryAutomatedProcessingTreasuryTransactions,
    setErrorMsgTreasuryAutomatedProcessingTreasuryTransactions,
  ] = React.useState('');
  const [
    errorMsgTreasuryEmployeesTreasuryFinanceTransactionsCompleted,
    setErrorMsgTreasuryEmployeesTreasuryFinanceTransactionsCompleted,
  ] = React.useState('');
  const [
    errorMsgTreasuryEmployeesProcessingTreasuryFinanceTransactions,
    setErrorMsgTreasuryEmployeesProcessingTreasuryFinanceTransactions,
  ] = React.useState('');
  const [errorMsgTreasuryEfficiency, setErrorMsgTreasuryEfficiency] = React.useState('');
  const [
    errorMsgTreasuryLiquidityPlanningHorizonMonths,
    setErrorMsgTreasuryLiquidityPlanningHorizonMonths,
  ] = React.useState('');
  const [
    errorMsgTreasuryLiquidityPlanningFrequenceMonths,
    setErrorMsgTreasuryLiquidityPlanningFrequenceMonths,
  ] = React.useState('');
  const [
    errorMsgTreasuryLiquidityPlanningDurationDays,
    setErrorMsgTreasuryLiquidityPlanningDurationDays,
  ] = React.useState('');
  const [
    errorMsgTreasuryNumberEmployeesLiquidityPlanning,
    setErrorMsgTreasuryNumberEmployeesLiquidityPlanning,
  ] = React.useState('');
  const [errorMsgTreasuryAccuracyLiquidityPlanning, setErrorMsgTreasuryAccuracyLiquidityPlanning] =
    React.useState('');

  // States for input validation to save user inputs of benchmarks
  const [validateDebtorsDurationDays, setValidateDebtorsDurationDays] = React.useState(true);
  const [validateDebtorsPercentageShare, setValidateDebtorsPercentageShare] = React.useState(true);
  const [validateDebtorsPercentageShareCustomer, setValidateDebtorsPercentageShareCustomer] =
    React.useState(true);
  const [validateDebtorsNumberBill, setValidateDebtorsNumberBill] = React.useState(true);
  const [
    validateDebtorsPercentageShareIncorrectBills,
    setValidateDebtorsPercentageShareIncorrectBills,
  ] = React.useState(true);
  const [validateDebtorsNumberBillPosition, setValidateDebtorsNumberBillPosition] =
    React.useState(true);
  const [validateDebtorsTimeSpanUntilBill, setValidateDebtorsTimeSpanUntilBill] =
    React.useState(true);
  const [validateDebtorsPercentageShareElecBills, setValidateDebtorsPercentageShareElecBills] =
    React.useState(true);
  const [
    validateDebtorsPercentageShareBillsReminder,
    setValidateDebtorsPercentageShareBillsReminder,
  ] = React.useState(true);
  const [validateDebtorsIncomingPayments, setValidateDebtorsIncomingPayments] =
    React.useState(true);
  const [
    validateDebtorsTimeSpanUntilClosingBusinessCase,
    setValidateDebtorsTimeSpanUntilClosingBusinessCase,
  ] = React.useState(true);
  const [validateDebtorsIncorrectPaymentAssignment, setValidateDebtorsIncorrectPaymentAssignment] =
    React.useState(true);
  const [validateDebtorsReceivablesTurnoverTime, setValidateDebtorsReceivablesTurnoverTime] =
    React.useState(true);
  const [validateDebtorsOutsourcedReceivables, setValidateDebtorsOutsourcedReceivables] =
    React.useState(true);
  const [
    validateCreditorsDurationProcessingCreditors,
    setValidateCreditorsDurationProcessingCreditors,
  ] = React.useState(true);
  const [validateCreditorsIncorrectOrders, setValidateCreditorsIncorrectOrders] =
    React.useState(true);
  const [validateCreditorsSuppliersEdi, setValidateCreditorsSuppliersEdi] = React.useState(true);
  const [validateCreditorsNumberOrders, setValidateCreditorsNumberOrders] = React.useState(true);
  const [validateCreditorsNumberOrderPositions, setValidateCreditorsNumberOrderPositions] =
    React.useState(true);
  const [validateCreditorsAutomatedOrderEntry, setValidateCreditorsAutomatedOrderEntry] =
    React.useState(true);
  const [validateCreditorsElectrSentOrders, setValidateCreditorsElectrSentOrders] =
    React.useState(true);
  const [validateCreditorsNumberBills, setValidateCreditorsNumberBills] = React.useState(true);
  const [validateCreditorsDifferenceBillsOrders, setValidateCreditorsDifferenceBillsOrders] =
    React.useState(true);
  const [validateCreditorsBillWithoutOrder, setValidateCreditorsBillWithoutOrder] =
    React.useState(true);
  const [
    validateCreditorsIncorrectPaymentsCreditors,
    setValidateCreditorsIncorrectPaymentsCreditors,
  ] = React.useState(true);
  const [
    validateCreditorsTimeSpanBillincomingPayment,
    setValidateCreditorsTimeSpanBillincomingPayment,
  ] = React.useState(true);
  const [validateAssetMgtAssetsMasterData, setValidateAssetMgtAssetsMasterData] =
    React.useState(true);
  const [validateAssetMgtAssetsBookedProcessed, setValidateAssetMgtAssetsBookedProcessed] =
    React.useState(true);
  const [validateFinChargesTravelPayroll, setValidateFinChargesTravelPayroll] =
    React.useState(true);
  const [validateFinChargesTravelEmployees, setValidateFinChargesTravelEmployees] =
    React.useState(true);
  const [validateFinChargesTravelIncorrectPayrolls, setValidateFinChargesTravelIncorrectPayrolls] =
    React.useState(true);
  const [
    validateFinChargesTravelTravelExpenseReport,
    setValidateFinChargesTravelTravelExpenseReport,
  ] = React.useState(true);
  const [validateFinChargesTravelProcessingDays, setValidateFinChargesTravelProcessingDays] =
    React.useState(true);
  const [validateFinTaxReportingUnit, setValidateFinTaxReportingUnit] = React.useState(true);
  const [validateFinTaxTaxDeclaration, setValidateFinTaxTaxDeclaration] = React.useState(true);
  const [validateFinTaxTaxDeclarationBalance, setValidateFinTaxTaxDeclarationBalance] =
    React.useState(true);
  const [validateConsolJournalEntries, setValidateConsolJournalEntries] = React.useState(true);
  const [validateConsolDaysMonthlyClosing, setValidateConsolDaysMonthlyClosing] =
    React.useState(true);
  const [
    validateConsolDaysMonthlyClosingReleaseExternReporting,
    setValidateConsolDaysMonthlyClosingReleaseExternReporting,
  ] = React.useState(true);
  const [validateConsolDaysQuarterlyReport, setValidateConsolDaysQuarterlyReport] =
    React.useState(true);
  const [
    validateConsolDaysQuarterlyReportReleaseExternReporting,
    setValidateConsolDaysQuarterlyReportReleaseExternReporting,
  ] = React.useState(true);
  const [validateConsolDaysAnnualReport, setValidateConsolDaysAnnualReport] = React.useState(true);
  const [
    validateConsolDaysAnnualReportReleaseExternReporting,
    setValidateConsolDaysAnnualReportReleaseExternReporting,
  ] = React.useState(true);
  const [
    validateConsolNumberConsolidatedLegalEntities,
    setValidateConsolNumberConsolidatedLegalEntities,
  ] = React.useState(true);
  const [validateConsolFteConsolidation, setValidateConsolFteConsolidation] = React.useState(true);
  const [validateConsolCostConsolidation, setValidateConsolCostConsolidation] =
    React.useState(true);
  const [validateConsolCompletionDataReporting, setValidateConsolCompletionDataReporting] =
    React.useState(true);
  const [validateConsolMonthlyCompletion, setValidateConsolMonthlyCompletion] =
    React.useState(true);
  const [validateConsolQuarterlyCompletion, setValidateConsolQuarterlyCompletion] =
    React.useState(true);
  const [validateConsolAnnualCompletion, setValidateConsolAnnualCompletion] = React.useState(true);
  const [
    validateTreasuryAvgExternPaymentTransactions,
    setValidateTreasuryAvgExternPaymentTransactions,
  ] = React.useState(true);
  const [validateTreasuryAvgExternManualPayments, setValidateTreasuryAvgExternManualPayments] =
    React.useState(true);
  const [validateTreasuryAvgInternPayments, setValidateTreasuryAvgInternPayments] =
    React.useState(true);
  const [
    validateTreasuryEmployeesPaymentTransactions,
    setValidateTreasuryEmployeesPaymentTransactions,
  ] = React.useState(true);
  const [
    validateTreasuryFinanceTransactionsConcluded,
    setValidateTreasuryFinanceTransactionsConcluded,
  ] = React.useState(true);
  const [
    validateTreasuryInventoryTreasuryFinanceTransactions,
    setValidateTreasuryInventoryTreasuryFinanceTransactions,
  ] = React.useState(true);
  const [
    validateTreasuryAutomatedProcessingTreasuryTransactions,
    setValidateTreasuryAutomatedProcessingTreasuryTransactions,
  ] = React.useState(true);
  const [
    validateTreasuryEmployeesTreasuryFinanceTransactionsCompleted,
    setValidateTreasuryEmployeesTreasuryFinanceTransactionsCompleted,
  ] = React.useState(true);
  const [
    validateTreasuryEmployeesProcessingTreasuryFinanceTransactions,
    setValidateTreasuryEmployeesProcessingTreasuryFinanceTransactions,
  ] = React.useState(true);
  const [validateTreasuryEfficiency, setValidateTreasuryEfficiency] = React.useState(true);
  const [
    validateTreasuryLiquidityPlanningHorizonMonths,
    setValidateTreasuryLiquidityPlanningHorizonMonths,
  ] = React.useState(true);
  const [
    validateTreasuryLiquidityPlanningFrequenceMonths,
    setValidateTreasuryLiquidityPlanningFrequenceMonths,
  ] = React.useState(true);
  const [
    validateTreasuryLiquidityPlanningDurationDays,
    setValidateTreasuryLiquidityPlanningDurationDays,
  ] = React.useState(true);
  const [
    validateTreasuryNumberEmployeesLiquidityPlanning,
    setValidateTreasuryNumberEmployeesLiquidityPlanning,
  ] = React.useState(true);
  const [validateTreasuryAccuracyLiquidityPlanning, setValidateTreasuryAccuracyLiquidityPlanning] =
    React.useState(true);

  const [totalProcessCostActive, setTotalProcessCostActive] = React.useState(true);
  const [totalProcessFteActive, setTotalProcessFteActive] = React.useState(true);

  // States for opening the alert dialog
  const { isOpen: isAlertShown, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();

  /**
   * Sets all single process / kpi fte and costs states to zero
   */
  const clearProcessInputs = () => {
    setConsolidationProcessFTE(null);
    setConsolidationProcessCosts(null);
    setConsolJournalEntries(null);
    setConsolDaysMonthlyClosing(null);
    setConsolDaysMonthlyClosingReleaseExternReporting(null);
    setConsolDaysQuarterlyReport(null);
    setConsolDaysQuarterlyReportReleaseExternReporting(null);
    setConsolDaysAnnualReport(null);
    setConsolDaysAnnualReportReleaseExternReporting(null);
    setConsolNumberConsolidatedLegalEntities(null);
    setConsolFteConsolidation(null);
    setConsolCostConsolidation(null);
    setConsolCompletionDataReporting(null);
    setConsolMonthlyCompletion(null);
    setConsolQuarterlyCompletion(null);
    setConsolAnnualCompletion(null);
    setConsolExtraLoadManualActivity(null);
    setConsolQualityDataReporting(null);
    setConsolQualityVotingProcess(null);
    setConsolIntegrationLegalManagement(null);
    setConsolGenerationConsolidatedNumbers(null);
    setConsolChallengeGroupAccounting(null);
    setCreditorsProcessFTE(null);
    setCreditorsProcessCosts(null);
    setCreditorsDurationProcessingCreditors(null);
    setCreditorsIncorrectOrders(null);
    setCreditorsSuppliersEdi(null);
    setCreditorsNumberOrders(null);
    setCreditorsNumberOrderPositions(null);
    setCreditorsAutomatedOrderEntry(null);
    setCreditorsElectrSentOrders(null);
    setCreditorsNumberBills(null);
    setCreditorsDifferenceBillsOrders(null);
    setCreditorsBillWithoutOrder(null);
    setCreditorsIncorrectPaymentsCreditors(null);
    setCreditorsTimeSpanBillincomingPayment(null);
    setDebtorsProcessCosts(null);
    setDebtorsProcessFTE(null);
    setDebtorsDurationDays(null);
    setDebtorsPercentageShare(null);
    setDebtorsPercentageShareCustomer(null);
    setDebtorsNumberBill(null);
    setDebtorsPercentageShareIncorrectBills(null);
    setDebtorsNumberBillPosition(null);
    setDebtorsTimeSpanUntilBill(null);
    setDebtorsPercentageShareElecBills(null);
    setDebtorsPercentageShareBillsReminder(null);
    setDebtorsIncomingPayments(null);
    setDebtorsTimeSpanUntilClosingBusinessCase(null);
    setDebtorsIncorrectPaymentAssignment(null);
    setDebtorsReceivablesTurnoverTime(null);
    setDebtorsOutsourcedReceivables(null);
    setCreditorsProcessCosts(null);
    setFinChargesTravelFTE(null);
    setFinChargesTravelCosts(null);
    setFinChargesTravelPayroll(null);
    setFinChargesTravelEmployees(null);
    setFinChargesTravelIncorrectPayrolls(null);
    setFinChargesTravelTravelExpenseReport(null);
    setFinChargesTravelProcessingDays(null);
    setFinTaxProcessFTE(null);
    setFinTaxProcessCosts(null);
    setFinTaxReportingUnit(null);
    setFinTaxTaxDeclaration(null);
    setFinTaxTaxDeclarationBalance(null);
    setCreditorsProcessFTE(null);
    setFinTreasuryProcessCosts(null);
    setFinTreasuryProcessFTE(null);
    setTreasuryAvgExternPaymentTransactions(null);
    setTreasuryAvgExternManualPayments(null);
    setTreasuryAvgInternPayments(null);
    setTreasuryEmployeesPaymentTransactions(null);
    setTreasuryFinanceTransactionsConcluded(null);
    setTreasuryInventoryTreasuryFinanceTransactions(null);
    setTreasuryAutomatedProcessingTreasuryTransactions(null);
    setTreasuryEmployeesTreasuryFinanceTransactionsCompleted(null);
    setTreasuryEmployeesProcessingTreasuryFinanceTransactions(null);
    setTreasuryEfficiency(null);
    setTreasuryLiquidityPlanningHorizonMonths(null);
    setTreasuryLiquidityPlanningFrequenceMonths(null);
    setTreasuryLiquidityPlanningDurationDays(null);
    setTreasuryNumberEmployeesLiquidityPlanning(null);
    setTreasuryAccuracyLiquidityPlanning(null);
    setAssetMgtProcessCosts(null);
    setAssetMgtProcessFTE(null);
    setFinFurtherDevelopmentFinanceCosts(null);
    setFinFurtherDevelopmentFinanceFTE(null);
    setAssetMgtAssetMasterData(null);
    setAssetMgtAssetsBookedProcessed(null);
  };

  /**
   * Handles the change of single process fte states
   */
  const handleFteValueChange = (inputValue, oldValue, setState) => {
    const value = Number.isNaN(parseFloat(inputValue)) ? 0 : parseFloat(inputValue);
    const state = Number.isNaN(parseFloat(oldValue)) ? 0 : parseFloat(oldValue);
    const dif = value - state;
    setState(inputValue);
    if (totalProcessFteActive) {
      setProcessFTE(parseFloat(dif).toFixed(2));
      setTotalProcessFteActive(false);
    } else {
      setProcessFTE(
        processFTE
          ? (parseFloat(processFTE) + parseFloat(dif)).toFixed(2)
          : parseFloat(dif).toFixed(2),
      );
    }
  };

  /**
   * Handles the change of single process cost states
   */
  const handleCostValueChange = (inputValue, oldValue, setState) => {
    const value = Number.isNaN(parseFloat(inputValue)) ? 0 : parseFloat(inputValue);
    const state = Number.isNaN(parseFloat(oldValue)) ? 0 : parseFloat(oldValue);
    const dif = value - state;
    setState(inputValue);
    if (totalProcessCostActive) {
      setProcessCosts(parseFloat(dif).toFixed(2));
      setTotalProcessCostActive(false);
    } else {
      setProcessCosts(
        processCosts
          ? (parseFloat(processCosts) + parseFloat(dif)).toFixed(2)
          : parseFloat(dif).toFixed(2),
      );
    }
  };

  /**
   * Handles the change of the total process states
   */
  const handleProcessFteValueChange = (inputValue) => {
    setTotalProcessFteActive(true);
    setProcessFTE(inputValue);
    clearProcessInputs();
  };

  /**
   * Handles the change of the total process cost state
   */
  const handleProcessCostValueChange = (inputValue) => {
    setTotalProcessCostActive(true);
    setProcessCosts(inputValue);
    clearProcessInputs();
  };

  /**
   * Handles the change and validation of the kpi journal entries state
   */
  const handleConsolJournalEntriesValueChange = (inputValue) => {
    setConsolJournalEntries(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgConsolJournalEntries(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateConsolJournalEntries(false);
    } else {
      setErrorMsgConsolJournalEntries('');
      setValidateConsolJournalEntries(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days monthly closing state
   */
  const handleConsolDaysMonthlyClosingValueChange = (inputValue) => {
    setConsolDaysMonthlyClosing(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysMonthlyClosing(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysMonthlyClosing(false);
    } else {
      setErrorMsgConsolDaysMonthlyClosing('');
      setValidateConsolDaysMonthlyClosing(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days monthly closing release extern reporting state
   */
  const handleConsolDaysMonthlyClosingReleaseExternReportingValueChange = (inputValue) => {
    setConsolDaysMonthlyClosingReleaseExternReporting(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysMonthlyClosingReleaseExternReporting(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysMonthlyClosingReleaseExternReporting(false);
    } else {
      setErrorMsgConsolDaysMonthlyClosingReleaseExternReporting('');
      setValidateConsolDaysMonthlyClosingReleaseExternReporting(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days quarterly report state
   */
  const handleConsolDaysQuarterReportValueChange = (inputValue) => {
    setConsolDaysQuarterlyReport(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysQuarterlyReport(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysQuarterlyReport(false);
    } else {
      setErrorMsgConsolDaysQuarterlyReport('');
      setValidateConsolDaysQuarterlyReport(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days quarterly report release extern reporting state
   */
  const handleConsolDaysQuarterlyReportReleaseExternReportingValueChange = (inputValue) => {
    setConsolDaysQuarterlyReportReleaseExternReporting(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysQuarterlyReportReleaseExternReporting(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysQuarterlyReportReleaseExternReporting(false);
    } else {
      setErrorMsgConsolDaysQuarterlyReportReleaseExternReporting('');
      setValidateConsolDaysQuarterlyReportReleaseExternReporting(true);
    }
  };

  /**
   * Handles the change and validation of the kpi duration days state
   */
  const handleDebtorsDurationDaysValueChange = (inputValue) => {
    setDebtorsDurationDays(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgDebtorsDurationDays(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateDebtorsDurationDays(false);
    } else {
      setErrorMsgDebtorsDurationDays('');
      setValidateDebtorsDurationDays(true);
    }
  };

  /**
   * Handles the change and validation of the kpi percentage share state
   */
  const handleDebtorsPercentageShareValueChange = (inputValue) => {
    setDebtorsPercentageShare(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsPercentageShare(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsPercentageShare(false);
    } else {
      setErrorMsgDebtorsPercentageShare('');
      setValidateDebtorsPercentageShare(true);
    }
  };

  /**
   * Handles the change and validation of the kpi percentage share customer state
   */
  const handleDebtorsPercentageShareCustomerValueChange = (inputValue) => {
    setDebtorsPercentageShareCustomer(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsPercentageShareCustomer(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsPercentageShareCustomer(false);
    } else {
      setErrorMsgDebtorsPercentageShareCustomer('');
      setValidateDebtorsPercentageShareCustomer(true);
    }
  };

  /**
   * Handles the change and validation of the kpi number bill state
   */
  const handleDebtorsNumberBillValueChange = (inputValue) => {
    setDebtorsNumberBill(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgDebtorsNumberBill(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateDebtorsNumberBill(false);
    } else {
      setErrorMsgDebtorsNumberBill('');
      setValidateDebtorsNumberBill(true);
    }
  };

  /**
   * Handles the change and validation of the kpi percentage share incorrect bills state
   */
  const handleDebtorsPercentageShareIncorrectBillsValueChange = (inputValue) => {
    setDebtorsPercentageShareIncorrectBills(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsPercentageShareIncorrectBills(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsPercentageShareIncorrectBills(false);
    } else {
      setErrorMsgDebtorsPercentageShareIncorrectBills('');
      setValidateDebtorsPercentageShareIncorrectBills(true);
    }
  };

  /**
   * Handles the change and validation of the kpi number bill position state
   */
  const handleDebtorsNumberBillPositionValueChange = (inputValue) => {
    setDebtorsNumberBillPosition(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgDebtorsNumberBillPosition(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateDebtorsNumberBillPosition(false);
    } else {
      setErrorMsgDebtorsNumberBillPosition('');
      setValidateDebtorsNumberBillPosition(true);
    }
  };

  /**
   * Handles the change and validation of the kpi time span until bill state
   */
  const handleDebtorsTimeSpanUntilBillValueChange = (inputValue) => {
    setDebtorsTimeSpanUntilBill(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgDebtorsTimeSpanUntilBill(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateDebtorsTimeSpanUntilBill(false);
    } else {
      setErrorMsgDebtorsTimeSpanUntilBill('');
      setValidateDebtorsTimeSpanUntilBill(true);
    }
  };

  /**
   * Handles the change and validation of the kpi percentage share elec bills state
   */
  const handleDebtorsPercentageShareElecBillsValueChange = (inputValue) => {
    setDebtorsPercentageShareElecBills(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsPercentageShareElecBills(
        t('errors.benchmarking.valueBetweenpercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsPercentageShareElecBills(false);
    } else {
      setErrorMsgDebtorsPercentageShareElecBills('');
      setValidateDebtorsPercentageShareElecBills(true);
    }
  };

  /**
   * Handles the change and validation of the kpi percentage share bills reminder state
   */
  const handleDebtorsPercentageShareBillsReminderValueChange = (inputValue) => {
    setDebtorsPercentageShareBillsReminder(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsPercentageShareBillsReminder(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsPercentageShareBillsReminder(false);
    } else {
      setErrorMsgDebtorsPercentageShareBillsReminder('');
      setValidateDebtorsPercentageShareBillsReminder(true);
    }
  };

  /**
   * Handles the change and validation of the kpi incoming payments state
   */
  const handleDebtorsIncomingPaymentsValueChange = (inputValue) => {
    setDebtorsIncomingPayments(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgDebtorsIncomingPayments(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateDebtorsIncomingPayments(false);
    } else {
      setErrorMsgDebtorsIncomingPayments('');
      setValidateDebtorsIncomingPayments(true);
    }
  };

  /**
   * Handles the change and validation of the kpi time span until closing business case state
   */
  const handleDebtorsTimeSpanUntilClosingBusinessCaseValueChange = (inputValue) => {
    setDebtorsTimeSpanUntilClosingBusinessCase(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgDebtorsTimeSpanUntilClosingBusinessCase(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateDebtorsTimeSpanUntilClosingBusinessCase(false);
    } else {
      setErrorMsgDebtorsTimeSpanUntilClosingBusinessCase('');
      setValidateDebtorsTimeSpanUntilClosingBusinessCase(true);
    }
  };

  /**
   * Handles the change and validation of the kpi incorrect payment assignment state
   */
  const handleDebtorsIncorrectPaymentAssigmentValueChange = (inputValue) => {
    setDebtorsIncorrectPaymentAssignment(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsIncorrectPaymentAssignment(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsIncorrectPaymentAssignment(false);
    } else {
      setErrorMsgDebtorsIncorrectPaymentAssignment('');
      setValidateDebtorsIncorrectPaymentAssignment(true);
    }
  };

  /**
   * Handles the change and validation of the kpi receivables turnover time state
   */
  const handleDebtorsReceivablesTurnoverTimeValueChange = (inputValue) => {
    setDebtorsReceivablesTurnoverTime(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgDebtorsReceivablesTurnoverTime(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateDebtorsReceivablesTurnoverTime(false);
    } else {
      setErrorMsgDebtorsReceivablesTurnoverTime('');
      setValidateDebtorsReceivablesTurnoverTime(true);
    }
  };

  /**
   * Handles the change and validation of the kpi outsourced receivables state
   */
  const handleDebtorsOutsourcedReceivablesValueChange = (inputValue) => {
    setDebtorsOutsourcedReceivables(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgDebtorsOutsourcedReceivables(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateDebtorsOutsourcedReceivables(false);
    } else {
      setErrorMsgDebtorsOutsourcedReceivables('');
      setValidateDebtorsOutsourcedReceivables(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days annual report state
   */
  const handleConsoleDaysAnnualReportValueChange = (inputValue) => {
    setConsolDaysAnnualReport(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysAnnualReport(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysAnnualReport(false);
    } else {
      setErrorMsgConsolDaysAnnualReport('');
      setValidateConsolDaysAnnualReport(true);
    }
  };

  /**
   * Handles the change and validation of the kpi days annual report release extern reporting state
   */
  const handleConsoleDaysAnnualReportReleaseExternReportingValueChange = (inputValue) => {
    setConsolDaysAnnualReportReleaseExternReporting(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolDaysAnnualReportReleaseExternReporting(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolDaysAnnualReportReleaseExternReporting(false);
    } else {
      setErrorMsgConsolDaysAnnualReportReleaseExternReporting('');
      setValidateConsolDaysAnnualReportReleaseExternReporting(true);
    }
  };

  /**
   * Handles the change and validation of the kpi number consolidated legal entities state
   */
  const handleConsoleNumberConsolidatedLegalEntitiesValueChange = (inputValue) => {
    setConsolNumberConsolidatedLegalEntities(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgConsolNumberConsolidatedLegalEntities(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateConsolNumberConsolidatedLegalEntities(false);
    } else {
      setErrorMsgConsolNumberConsolidatedLegalEntities('');
      setValidateConsolNumberConsolidatedLegalEntities(true);
    }
  };

  /**
   * Handles the change and validation of the kpi fte consolidation state
   */
  const handleConsoleFteConsolidationValueChange = (inputValue) => {
    setConsolFteConsolidation(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgConsolFteConsolidation(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateConsolFteConsolidation(false);
    } else {
      setErrorMsgConsolFteConsolidation('');
      setValidateConsolFteConsolidation(true);
    }
  };

  /**
   * Handles the change and validation of the kpi cost consolidation state
   */
  const handleConsolCostConsolidationValueChange = (inputValue) => {
    setConsolCostConsolidation(inputValue);
    if (inputValue > 10000000 || inputValue < 0) {
      setErrorMsgConsolCostConsolidation(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateConsolCostConsolidation(false);
    } else {
      setErrorMsgConsolCostConsolidation('');
      setValidateConsolCostConsolidation(true);
    }
  };

  /**
   * Handles the change and validation of the kpi completion data reporting state
   */
  const handleConsolCompletionDataReportingValueChange = (inputValue) => {
    setConsolCompletionDataReporting(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolCompletionDataReporting(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolCompletionDataReporting(false);
    } else {
      setErrorMsgConsolCompletionDataReporting('');
      setValidateConsolCompletionDataReporting(true);
    }
  };

  /**
   * Handles the change and validation of the kpi month completion state
   */
  const handleConsolMonthlyCompletionValueChange = (inputValue) => {
    setConsolMonthlyCompletion(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolMonthlyCompletion(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolMonthlyCompletion(false);
    } else {
      setErrorMsgConsolMonthlyCompletion('');
      setValidateConsolMonthlyCompletion(true);
    }
  };

  /**
   * Handles the change and validation of the kpi quarter completion state
   */
  const handleConsolQuarterlyCompletionValueChange = (inputValue) => {
    setConsolQuarterlyCompletion(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolQuarterlyCompletion(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolQuarterlyCompletion(false);
    } else {
      setErrorMsgConsolQuarterlyCompletion('');
      setValidateConsolQuarterlyCompletion(true);
    }
  };

  /**
   * Handles the change and validation of the kpi annual completion state
   */
  const handleConsolAnnualCompletionValueChange = (inputValue) => {
    setConsolAnnualCompletion(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgConsolAnnualCompletion(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateConsolAnnualCompletion(false);
    } else {
      setErrorMsgConsolAnnualCompletion('');
      setValidateConsolAnnualCompletion(true);
    }
  };

  /**
   * Handles the change and validation of the kpi duration processing creditors state
   */
  const handleCreditorsDurationProcessingCreditorsValueChange = (inputValue) => {
    setCreditorsDurationProcessingCreditors(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgCreditorsDurationProcessingCreditors(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateCreditorsDurationProcessingCreditors(false);
    } else {
      setErrorMsgCreditorsDurationProcessingCreditors('');
      setValidateCreditorsDurationProcessingCreditors(true);
    }
  };
  /*
   * Handles the change and validation of the kpi incorrect orders state
   */
  const handleCreditorsIncorrectOrdersValueChange = (inputValue) => {
    setCreditorsIncorrectOrders(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsIncorrectOrders(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsIncorrectOrders(false);
    } else {
      setErrorMsgCreditorsIncorrectOrders('');
      setValidateCreditorsIncorrectOrders(true);
    }
  };
  /**
   * Handles the change and validation of the kpi suppliers edi time state
   */
  const handleCreditorsSuppliersEdiValueChange = (inputValue) => {
    setCreditorsSuppliersEdi(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsSuppliersEdi(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsSuppliersEdi(false);
    } else {
      setErrorMsgCreditorsSuppliersEdi('');
      setValidateCreditorsSuppliersEdi(true);
    }
  };
  /**
   * Handles the change and validation of the kpi number orders state
   */
  const handleCreditorsNumberOrdersValueChange = (inputValue) => {
    setCreditorsNumberOrders(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgCreditorsNumberOrders(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateCreditorsNumberOrders(false);
    } else {
      setErrorMsgCreditorsNumberOrders('');
      setValidateCreditorsNumberOrders(true);
    }
  };
  /**
   * Handles the change and validation of the kpi number order positioins state
   */
  const handleCreditorsNumberOrderPositionsValueChange = (inputValue) => {
    setCreditorsNumberOrderPositions(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgCreditorsNumberOrderPositions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateCreditorsNumberOrderPositions(false);
    } else {
      setErrorMsgCreditorsNumberOrderPositions('');
      setValidateCreditorsNumberOrderPositions(true);
    }
  };
  /**
   * Handles the change and validation of the kpi automated order entry state
   */
  const handleCreditorsAutomatedOrderEntryValueChange = (inputValue) => {
    setCreditorsAutomatedOrderEntry(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsDurationProcessingCreditors(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsAutomatedOrderEntry(false);
    } else {
      setErrorMsgCreditorsAutomatedOrderEntry('');
      setValidateCreditorsAutomatedOrderEntry(true);
    }
  };
  /**
   * Handles the change and validation of the kpi electr sent orders state
   */
  const handleCreditorsElectrSentOrdersValueChange = (inputValue) => {
    setCreditorsElectrSentOrders(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsElectrSentOrders(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsElectrSentOrders(false);
    } else {
      setErrorMsgCreditorsElectrSentOrders('');
      setValidateCreditorsElectrSentOrders(true);
    }
  };
  /**
   * Handles the change and validation of the kpi number bills state
   */
  const handleCreditorsNumberBillsValueChange = (inputValue) => {
    setCreditorsNumberBills(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgCreditorsNumberBills(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateCreditorsNumberBills(false);
    } else {
      setErrorMsgCreditorsNumberBills('');
      setValidateCreditorsNumberBills(true);
    }
  };
  /**
   * Handles the change and validation of the kpi difference bills orders state
   */
  const handleCreditorsDifferenceBillsOrdersValueChange = (inputValue) => {
    setCreditorsDifferenceBillsOrders(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsDifferenceBillsOrders(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsDifferenceBillsOrders(false);
    } else {
      setErrorMsgCreditorsDifferenceBillsOrders('');
      setValidateCreditorsDifferenceBillsOrders(true);
    }
  };
  /**
   * Handles the change and validation of the kpi bill without order state
   */
  const handleCreditorsBillWithoutOrderValueChange = (inputValue) => {
    setCreditorsBillWithoutOrder(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsBillWithoutOrder(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsBillWithoutOrder(false);
    } else {
      setErrorMsgCreditorsBillWithoutOrder('');
      setValidateCreditorsBillWithoutOrder(true);
    }
  };
  /**
   * Handles the change and validation of the kpi incorrect payments creditors state
   */
  const handleCreditorsIncorrectPaymentsCreditorsValueChange = (inputValue) => {
    setCreditorsIncorrectPaymentsCreditors(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgCreditorsIncorrectPaymentsCreditors(
        t('errors.benchmarking.valueBetweenPercentError', { min: '0', max: '100' }),
      );
      setValidateCreditorsIncorrectPaymentsCreditors(false);
    } else {
      setErrorMsgCreditorsIncorrectPaymentsCreditors('');
      setValidateCreditorsIncorrectPaymentsCreditors(true);
    }
  };

  /**
   * Handles the change and validation of the kpi time span billincoming payment state
   */
  const handleCreditorsTimeSpanBillincomingPaymentValueChange = (inputValue) => {
    setCreditorsTimeSpanBillincomingPayment(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgCreditorsTimeSpanBillincomingPayment(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365¥' }),
      );
      setValidateCreditorsTimeSpanBillincomingPayment(false);
    } else {
      setErrorMsgCreditorsTimeSpanBillincomingPayment('');
      setValidateCreditorsTimeSpanBillincomingPayment(true);
    }
  };

  /**
   * Handles the change and validation of the kpi asset master data state
   */
  const handleAssetMgtAssetsMasterDataValueChange = (inputValue) => {
    setAssetMgtAssetMasterData(inputValue);
    if (inputValue > 1000000 || inputValue < 0) {
      setErrorMsgAssetMgtAssetsMasterData(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1 000 000' }),
      );
      setValidateAssetMgtAssetsMasterData(false);
    } else {
      setErrorMsgAssetMgtAssetsMasterData('');
      setValidateAssetMgtAssetsMasterData(true);
    }
  };

  /**
   * Handles the change and validation of the kpi asset booked processed state
   */
  const handleAssetMgtAssetsBookedProcessedValueChange = (inputValue) => {
    setAssetMgtAssetsBookedProcessed(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgAssetMgtAssetsBookedProcessed(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateAssetMgtAssetsBookedProcessed(false);
    } else {
      setErrorMsgAssetMgtAssetsBookedProcessed('');
      setValidateAssetMgtAssetsBookedProcessed(true);
    }
  };

  /*
   * Handles the change and validation of the kpi reporting unit state
   */
  const handleFinTaxReportingUnitValueChange = (inputValue) => {
    setFinTaxReportingUnit(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgFinTaxReportingUnit(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateFinTaxReportingUnit(false);
    } else {
      setErrorMsgFinTaxReportingUnit('');
      setValidateFinTaxReportingUnit(true);
    }
  };

  /*
   * Handles the change and validation of the kpi tax declaration state
   */
  const handleFinTaxTaxDeclarationValueChange = (inputValue) => {
    setFinTaxTaxDeclaration(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgFinTaxTaxDeclaration(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateFinTaxTaxDeclaration(false);
    } else {
      setErrorMsgFinTaxTaxDeclaration('');
      setValidateFinTaxTaxDeclaration(true);
    }
  };

  /**
   * Handles the change and validation of the kpi tax declaration balance state
   */
  const handleFinTaxTaxDeclarationBalanceValueChange = (inputValue) => {
    setFinTaxTaxDeclarationBalance(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgFinTaxTaxDeclarationBalance(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateFinTaxTaxDeclarationBalance(false);
    } else {
      setErrorMsgFinTaxTaxDeclarationBalance('');
      setValidateFinTaxTaxDeclarationBalance(true);
    }
  };

  /**
   * Handles the change and validation of the kpi fin charges travel employees state
   */
  const handleFinChargesTravelEmployeesValueChange = (inputValue) => {
    setFinChargesTravelEmployees(inputValue);
    if (inputValue > 1000000 || inputValue < 0) {
      setErrorMsgFinChargesTravelEmployees(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1 000 000' }),
      );
      setValidateFinChargesTravelEmployees(false);
    } else {
      setErrorMsgFinChargesTravelEmployees('');
      setValidateFinChargesTravelEmployees(true);
    }
  };

  /**
   * Handles the change and validation of the kpi fin charged travel payroll state
   */
  const handleFinChargesTravelPayrollValueChange = (inputValue) => {
    setFinChargesTravelPayroll(inputValue);
    if (inputValue > 1000000 || inputValue < 0) {
      setErrorMsgFinChargesTravelPayroll(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1 000 000' }),
      );
      setValidateFinChargesTravelPayroll(false);
    } else {
      setErrorMsgFinChargesTravelPayroll('');
      setValidateFinChargesTravelPayroll(true);
    }
  };

  /**
   * Handles the change and validation of the kpi fin charged travel incorrect payrolls state
   */
  const handleFinChargesTravelIncorrectPayrollsValueChange = (inputValue) => {
    setFinChargesTravelIncorrectPayrolls(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgFinChargesTravelIncorrectPayrolls(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateFinChargesTravelIncorrectPayrolls(false);
    } else {
      setErrorMsgFinChargesTravelIncorrectPayrolls('');
      setValidateFinChargesTravelIncorrectPayrolls(true);
    }
  };

  /**
   * Handles the change and validation of the kpi fin charged travel travel expense report state
   */
  const handleFinChargesTravelTravelExpenseReportValueChange = (inputValue) => {
    setFinChargesTravelTravelExpenseReport(inputValue);
    if (inputValue > 1000000 || inputValue < 0) {
      setErrorMsgFinChargesTravelTravelExpenseReport(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1 000 000' }),
      );
      setValidateFinChargesTravelTravelExpenseReport(false);
    } else {
      setErrorMsgFinChargesTravelTravelExpenseReport('');
      setValidateFinChargesTravelTravelExpenseReport(true);
    }
  };

  /**
   * Handles the change and validation of the kpi asset booked processed state
   */
  const handleFinChargesTravelProcessingDaysValueChange = (inputValue) => {
    setFinChargesTravelProcessingDays(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgFinChargesTravelProcessingDays(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateFinChargesTravelProcessingDays(false);
    } else {
      setErrorMsgFinChargesTravelProcessingDays('');
      setValidateFinChargesTravelProcessingDays(true);
    }
  };

  /**
   * Handles the change and validation of the kpi average extern payment transactions state
   */
  const handleTreasuryAvgExternPaymentTransactionsValueChange = (inputValue) => {
    setTreasuryAvgExternPaymentTransactions(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgTreasuryAvgExternPaymentTransactions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateTreasuryAvgExternPaymentTransactions(false);
    } else {
      setErrorMsgTreasuryAvgExternPaymentTransactions('');
      setValidateTreasuryAvgExternPaymentTransactions(true);
    }
  };

  /**
   * Handles the change and validation of the kpi average extern manual payments state
   */
  const handleTreasuryAvgExternManualPaymentsValueChange = (inputValue) => {
    setTreasuryAvgExternManualPayments(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgTreasuryAvgExternManualPayments(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateTreasuryAvgExternManualPayments(false);
    } else {
      setErrorMsgTreasuryAvgExternManualPayments('');
      setValidateTreasuryAvgExternManualPayments(true);
    }
  };

  /**
   * Handles the change and validation of the kpi average extern payment transactions state
   */
  const handleTreasuryAvgInternPaymentsValueChange = (inputValue) => {
    setTreasuryAvgInternPayments(inputValue);
    if (inputValue > 100000000 || inputValue < 0) {
      setErrorMsgTreasuryAvgInternPayments(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100 000 000' }),
      );
      setValidateTreasuryAvgInternPayments(false);
    } else {
      setErrorMsgTreasuryAvgInternPayments('');
      setValidateTreasuryAvgInternPayments(true);
    }
  };

  /**
   * Handles the change and validation of the kpi employees payment transactions state
   */
  const handleTreasuryEmployeesPaymentTransactionsValueChange = (inputValue) => {
    setTreasuryEmployeesPaymentTransactions(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgTreasuryEmployeesPaymentTransactions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateTreasuryEmployeesPaymentTransactions(false);
    } else {
      setErrorMsgTreasuryEmployeesPaymentTransactions('');
      setValidateTreasuryEmployeesPaymentTransactions(true);
    }
  };

  /**
   * Handles the change and validation of the kpi finance transactions concluded state
   */
  const handleTreasuryFinanceTransactionsConcludedValueChange = (inputValue) => {
    setTreasuryFinanceTransactionsConcluded(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgTreasuryFinanceTransactionsConcluded(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateTreasuryFinanceTransactionsConcluded(false);
    } else {
      setErrorMsgTreasuryFinanceTransactionsConcluded('');
      setValidateTreasuryFinanceTransactionsConcluded(true);
    }
  };

  /**
   * Handles the change and validation of the kpi inventory treasury finance transactions state
   */
  const handleTreasuryInventoryTreasuryFinanceTransactionsValueChange = (inputValue) => {
    setTreasuryInventoryTreasuryFinanceTransactions(inputValue);
    if (inputValue > 10000 || inputValue < 0) {
      setErrorMsgTreasuryInventoryTreasuryFinanceTransactions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '10 000' }),
      );
      setValidateTreasuryInventoryTreasuryFinanceTransactions(false);
    } else {
      setErrorMsgTreasuryInventoryTreasuryFinanceTransactions('');
      setValidateTreasuryInventoryTreasuryFinanceTransactions(true);
    }
  };

  /**
   * Handles the change and validation of the kpi automated processing treasury transactions state
   */
  const handleTreasuryAutomatedProcessingTreasuryTransactionsValueChange = (inputValue) => {
    setTreasuryAutomatedProcessingTreasuryTransactions(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgTreasuryAutomatedProcessingTreasuryTransactions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateTreasuryAutomatedProcessingTreasuryTransactions(false);
    } else {
      setErrorMsgTreasuryAutomatedProcessingTreasuryTransactions('');
      setValidateTreasuryAutomatedProcessingTreasuryTransactions(true);
    }
  };

  /**
   * Handles the change and validation of the kpi employees treasury finance transactions completed state
   */
  const handleTreasuryEmployeesTreasuryFinanceTransactionsCompletedValueChange = (inputValue) => {
    setTreasuryEmployeesTreasuryFinanceTransactionsCompleted(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgTreasuryEmployeesTreasuryFinanceTransactionsCompleted(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateTreasuryEmployeesTreasuryFinanceTransactionsCompleted(false);
    } else {
      setErrorMsgTreasuryEmployeesTreasuryFinanceTransactionsCompleted('');
      setValidateTreasuryEmployeesTreasuryFinanceTransactionsCompleted(true);
    }
  };

  /**
   * Handles the change and validation of the kpi employees processing treasury finance transactions state
   */
  const handleTreasuryEmployeesProcessingTreasuryFinanceTransactionsValueChange = (inputValue) => {
    setTreasuryEmployeesProcessingTreasuryFinanceTransactions(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgTreasuryEmployeesProcessingTreasuryFinanceTransactions(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateTreasuryEmployeesProcessingTreasuryFinanceTransactions(false);
    } else {
      setErrorMsgTreasuryEmployeesProcessingTreasuryFinanceTransactions('');
      setValidateTreasuryEmployeesProcessingTreasuryFinanceTransactions(true);
    }
  };

  /**
   * Handles the change and validation of the kpi efficiency state
   */
  const handleTreasuryEfficiencyValueChange = (inputValue) => {
    setTreasuryEfficiency(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgTreasuryEfficiency(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateTreasuryEfficiency(false);
    } else {
      setErrorMsgTreasuryEfficiency('');
      setValidateTreasuryEfficiency(true);
    }
  };

  /**
   * Handles the change and validation of the kpi liquidity planning horizon months state
   */
  const handleTreasuryLiquidityPlanningHorizonMonthsValueChange = (inputValue) => {
    setTreasuryLiquidityPlanningHorizonMonths(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgTreasuryLiquidityPlanningHorizonMonths(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateTreasuryLiquidityPlanningHorizonMonths(false);
    } else {
      setErrorMsgTreasuryLiquidityPlanningHorizonMonths('');
      setValidateTreasuryLiquidityPlanningHorizonMonths(true);
    }
  };

  /**
   * Handles the change and validation of the kpi liquidity planning frequence months state
   */
  const handleTreasuryLiquidityPlanningFrequenceMonthsValueChange = (inputValue) => {
    setTreasuryLiquidityPlanningFrequenceMonths(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgTreasuryLiquidityPlanningFrequenceMonths(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateTreasuryLiquidityPlanningFrequenceMonths(false);
    } else {
      setErrorMsgTreasuryLiquidityPlanningFrequenceMonths('');
      setValidateTreasuryLiquidityPlanningFrequenceMonths(true);
    }
  };

  /**
   * Handles the change and validation of the kpi liquidity planning duration days state
   */
  const handleTreasuryLiquidityPlanningDurationDaysValueChange = (inputValue) => {
    setTreasuryLiquidityPlanningDurationDays(inputValue);
    if (inputValue > 365 || inputValue < 0) {
      setErrorMsgTreasuryLiquidityPlanningDurationDays(
        t('errors.benchmarking.valueBetweenDaysError', { min: '0', max: '365' }),
      );
      setValidateTreasuryLiquidityPlanningDurationDays(false);
    } else {
      setErrorMsgTreasuryLiquidityPlanningDurationDays('');
      setValidateTreasuryLiquidityPlanningDurationDays(true);
    }
  };

  /**
   * Handles the change and validation of the kpi number employees liquidity planning state
   */
  const handleTreasuryNumberEmployeesLiquidityPlanningValueChange = (inputValue) => {
    setTreasuryNumberEmployeesLiquidityPlanning(inputValue);
    if (inputValue > 1000 || inputValue < 0) {
      setErrorMsgTreasuryNumberEmployeesLiquidityPlanning(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '1000' }),
      );
      setValidateTreasuryNumberEmployeesLiquidityPlanning(false);
    } else {
      setErrorMsgTreasuryNumberEmployeesLiquidityPlanning('');
      setValidateTreasuryNumberEmployeesLiquidityPlanning(true);
    }
  };

  /**
   * Handles the change and validation of the kpi accuracy liquidity planning state
   */
  const handleTreasuryAccuracyLiquidityPlanningValueChange = (inputValue) => {
    setTreasuryAccuracyLiquidityPlanning(inputValue);
    if (inputValue > 100 || inputValue < 0) {
      setErrorMsgTreasuryAccuracyLiquidityPlanning(
        t('errors.benchmarking.valueBetweenError', { min: '0', max: '100' }),
      );
      setValidateTreasuryAccuracyLiquidityPlanning(false);
    } else {
      setErrorMsgTreasuryAccuracyLiquidityPlanning('');
      setValidateTreasuryAccuracyLiquidityPlanning(true);
    }
  };

  /**
   * Fetches the data for the finance benchmarking
   */
  const fetchData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(fetchBenchmarkingFinance({ companyId: company.id, benchmarkingId: id }))
          .then(unwrapResult)
          .then((res) => {
            setProcessFTE(res.sumFteFinance);
            setProcessCosts(res.sumCostFinance);
            if (res.finDebitorsProcess) {
              setDebtorsProcessFTE(res.finDebitorsProcess.fte);
              if (res.finDebitorsProcess.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setDebtorsProcessFTE('');
            }
            if (res.finDebitorsProcess) {
              setDebtorsProcessCosts(res.finDebitorsProcess.cost);
              if (res.finDebitorsProcess.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setDebtorsProcessCosts('');
            }
            setDebtorsDurationDays(
              res.finDebitorsProcess ? res.finDebitorsProcess.durationDays : '',
            );
            setDebtorsPercentageShare(
              res.finDebitorsProcess ? res.finDebitorsProcess.percentageShare : '',
            );
            setDebtorsPercentageShareCustomer(
              res.finDebitorsProcess ? res.finDebitorsProcess.percentageShareCustomer : '',
            );
            setDebtorsNumberBill(res.finDebitorsProcess ? res.finDebitorsProcess.numberBills : '');
            setDebtorsPercentageShareIncorrectBills(
              res.finDebitorsProcess ? res.finDebitorsProcess.percentageShareIncorrectBills : '',
            );
            setDebtorsNumberBillPosition(
              res.finDebitorsProcess ? res.finDebitorsProcess.numberBillPosition : '',
            );
            setDebtorsTimeSpanUntilBill(
              res.finDebitorsProcess ? res.finDebitorsProcess.timeSpanUntilBill : '',
            );
            setDebtorsPercentageShareElecBills(
              res.finDebitorsProcess ? res.finDebitorsProcess.percentageShareElecBills : '',
            );
            setDebtorsPercentageShareBillsReminder(
              res.finDebitorsProcess ? res.finDebitorsProcess.percentageShareBillsReminder : '',
            );
            setDebtorsIncomingPayments(
              res.finDebitorsProcess ? res.finDebitorsProcess.incomingPayments : '',
            );
            setDebtorsTimeSpanUntilClosingBusinessCase(
              res.finDebitorsProcess ? res.finDebitorsProcess.timeSpanUntilClosingBusinessCase : '',
            );
            setDebtorsIncorrectPaymentAssignment(
              res.finDebitorsProcess ? res.finDebitorsProcess.incorrectPaymentAssignment : '',
            );
            setDebtorsReceivablesTurnoverTime(
              res.finDebitorsProcess ? res.finDebitorsProcess.receivablesTurnoverTime : '',
            );
            setDebtorsOutsourcedReceivables(
              res.finDebitorsProcess ? res.finDebitorsProcess.outsourcedReceivables : '',
            );
            if (res.finConsolidation) {
              setConsolidationProcessFTE(res.finConsolidation.fte);
              if (res.finCreditorsProcess.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setConsolidationProcessFTE('');
            }
            if (res.finConsolidation) {
              setConsolidationProcessCosts(res.finConsolidation.cost);
              if (res.finConsolidation.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setConsolidationProcessCosts('');
            }
            setConsolJournalEntries(
              res.finConsolidation ? res.finConsolidation.journalEntries : '',
            );
            setConsolDaysMonthlyClosing(
              res.finConsolidation ? res.finConsolidation.daysMonthlyClosing : '',
            );
            setConsolDaysMonthlyClosingReleaseExternReporting(
              res.finConsolidation
                ? res.finConsolidation.daysMonthlyClosingReleaseExternReporting
                : '',
            );
            setConsolDaysQuarterlyReport(
              res.finConsolidation ? res.finConsolidation.daysQuarterlyReport : '',
            );
            setConsolDaysQuarterlyReportReleaseExternReporting(
              res.finConsolidation
                ? res.finConsolidation.daysQuarterlyReportReleaseExternReporting
                : '',
            );
            setConsolDaysAnnualReport(
              res.finConsolidation ? res.finConsolidation.daysAnnualReport : '',
            );
            setConsolDaysAnnualReportReleaseExternReporting(
              res.finConsolidation
                ? res.finConsolidation.daysAnnualReportReleaseExternReporting
                : '',
            );
            setConsolNumberConsolidatedLegalEntities(
              res.finConsolidation ? res.finConsolidation.numberConsolidatedLegalEntities : '',
            );
            setConsolFteConsolidation(
              res.finConsolidation ? res.finConsolidation.fteConsolidation : '',
            );
            setConsolCostConsolidation(
              res.finConsolidation ? res.finConsolidation.costConsolidation : '',
            );
            setConsolCompletionDataReporting(
              res.finConsolidation ? res.finConsolidation.completionDataReporting : '',
            );
            setConsolMonthlyCompletion(
              res.finConsolidation ? res.finConsolidation.monthlyCompletion : '',
            );
            setConsolQuarterlyCompletion(
              res.finConsolidation ? res.finConsolidation.quarterlyCompletion : '',
            );
            setConsolAnnualCompletion(
              res.finConsolidation ? res.finConsolidation.annualCompletion : '',
            );
            setConsolExtraLoadManualActivity(
              res.finConsolidation ? res.finConsolidation.extraLoadManualActivity : null,
            );
            setConsolQualityDataReporting(
              res.finConsolidation ? res.finConsolidation.qualityDataReporting : null,
            );
            setConsolQualityVotingProcess(
              res.finConsolidation ? res.finConsolidation.qualityVotingProcess : null,
            );
            setConsolIntegrationLegalManagement(
              res.finConsolidation ? res.finConsolidation.integrationLegalManagement : null,
            );
            setConsolGenerationConsolidatedNumbers(
              res.finConsolidation ? res.finConsolidation.generationConsolidatedNumbers : null,
            );
            setConsolIntegrationLegalManagement(
              res.finConsolidation ? res.finConsolidation.integrationLegalManagement : null,
            );
            setConsolChallengeGroupAccounting(
              res.finConsolidation ? res.finConsolidation.challengeGroupAccounting : null,
            );
            if (res.finCreditorsProcess) {
              setCreditorsProcessFTE(res.finCreditorsProcess.fte);
              if (res.finCreditorsProcess.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCreditorsProcessFTE('');
            }
            if (res.finCreditorsProcess) {
              setCreditorsProcessCosts(res.finCreditorsProcess.cost);
              if (res.finCreditorsProcess.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setCreditorsProcessCosts('');
            }
            setCreditorsDurationProcessingCreditors(
              res.finCreditorsProcess ? res.finCreditorsProcess.durationProcessingCreditors : '',
            );
            setCreditorsIncorrectOrders(
              res.finCreditorsProcess ? res.finCreditorsProcess.incorrectOrders : '',
            );
            setCreditorsSuppliersEdi(
              res.finCreditorsProcess ? res.finCreditorsProcess.suppliersEdi : '',
            );
            setCreditorsNumberOrders(
              res.finCreditorsProcess ? res.finCreditorsProcess.numberOrders : '',
            );
            setCreditorsNumberOrderPositions(
              res.finCreditorsProcess ? res.finCreditorsProcess.numberOrderPositions : '',
            );
            setCreditorsAutomatedOrderEntry(
              res.finCreditorsProcess ? res.finCreditorsProcess.automatedOrderEntry : '',
            );
            setCreditorsElectrSentOrders(
              res.finCreditorsProcess ? res.finCreditorsProcess.electrSentOrders : '',
            );
            setCreditorsNumberBills(
              res.finCreditorsProcess ? res.finCreditorsProcess.numberBills : '',
            );
            setCreditorsDifferenceBillsOrders(
              res.finCreditorsProcess ? res.finCreditorsProcess.differenceBillsOrders : '',
            );
            setCreditorsBillWithoutOrder(
              res.finCreditorsProcess ? res.finCreditorsProcess.billWithoutOrder : '',
            );
            setCreditorsIncorrectPaymentsCreditors(
              res.finCreditorsProcess ? res.finCreditorsProcess.incorrectPaymentsCreditors : '',
            );
            setCreditorsTimeSpanBillincomingPayment(
              res.finCreditorsProcess ? res.finCreditorsProcess.timeSpanBillincomingPayment : '',
            );
            if (res.finAssetManagement) {
              setAssetMgtProcessFTE(res.finAssetManagement.fte);
              if (res.finAssetManagement.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setAssetMgtProcessFTE('');
            }
            if (res.finAssetManagement) {
              setAssetMgtProcessCosts(res.finAssetManagement.cost);
              if (res.finAssetManagement.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setAssetMgtProcessCosts('');
            }
            setAssetMgtAssetMasterData(
              res.finAssetManagement ? res.finAssetManagement.assetsMasterData : '',
            );
            setAssetMgtAssetsBookedProcessed(
              res.finAssetManagement ? res.finAssetManagement.assetsBookedProcessed : '',
            );
            if (res.finTax) {
              setFinTaxProcessCosts(res.finTax.cost);
              if (res.finTax.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinTaxProcessCosts('');
            }
            if (res.finTax) {
              setFinTaxProcessFTE(res.finTax.fte);
              if (res.finTax.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinTaxProcessFTE('');
            }
            setFinTaxReportingUnit(res.finTax ? res.finTax.reportingUnit : '');
            setFinTaxTaxDeclaration(res.finTax ? res.finTax.taxDeclaration : '');
            setFinTaxTaxDeclarationBalance(res.finTax ? res.finTax.taxDeclarationBalance : '');
            if (res.finChargesTravel) {
              setFinChargesTravelFTE(res.finChargesTravel.fte);
              if (res.finChargesTravel.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinChargesTravelFTE('');
            }
            if (res.finChargesTravel) {
              setFinChargesTravelCosts(res.finChargesTravel.cost);
              if (res.finChargesTravel.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinChargesTravelCosts('');
            }
            setFinChargesTravelPayroll(res.finChargesTravel ? res.finChargesTravel.payroll : '');
            setFinChargesTravelEmployees(
              res.finChargesTravel ? res.finChargesTravel.employees : '',
            );
            setFinChargesTravelIncorrectPayrolls(
              res.finChargesTravel ? res.finChargesTravel.incorrectPayrolls : '',
            );
            setFinChargesTravelTravelExpenseReport(
              res.finChargesTravel ? res.finChargesTravel.travelExpenseReport : '',
            );
            setFinChargesTravelProcessingDays(
              res.finChargesTravel ? res.finChargesTravel.processingDays : '',
            );
            if (res.finTreasury) {
              setFinTreasuryProcessFTE(res.finTreasury.fte);
              if (res.finTreasury.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinTreasuryProcessFTE('');
            }
            if (res.finTreasury) {
              setFinTreasuryProcessCosts(res.finTreasury.cost);
              if (res.finTreasury.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinTreasuryProcessCosts('');
            }
            setTreasuryAvgExternPaymentTransactions(
              res.finTreasury ? res.finTreasury.avgExternPaymentTransactions : '',
            );
            setTreasuryAvgExternManualPayments(
              res.finTreasury ? res.finTreasury.avgExternManualPayments : '',
            );
            setTreasuryAvgInternPayments(res.finTreasury ? res.finTreasury.avgInternPayments : '');
            setTreasuryEmployeesPaymentTransactions(
              res.finTreasury ? res.finTreasury.employeesPaymentTransactions : '',
            );
            setTreasuryFinanceTransactionsConcluded(
              res.finTreasury ? res.finTreasury.financeTransactionsConcluded : '',
            );
            setTreasuryInventoryTreasuryFinanceTransactions(
              res.finTreasury ? res.finTreasury.inventoryTreasuryFinanceTransactions : '',
            );
            setTreasuryAutomatedProcessingTreasuryTransactions(
              res.finTreasury ? res.finTreasury.automatedProcessingTreasuryTransactions : '',
            );
            setTreasuryEmployeesTreasuryFinanceTransactionsCompleted(
              res.finTreasury ? res.finTreasury.employeesTreasuryFinanceTransactionsCompleted : '',
            );
            setTreasuryEmployeesProcessingTreasuryFinanceTransactions(
              res.finTreasury ? res.finTreasury.employeesProcessingTreasuryFinanceTransactions : '',
            );
            setTreasuryEfficiency(res.finTreasury ? res.finTreasury.efficiency : '');
            setTreasuryLiquidityPlanningHorizonMonths(
              res.finTreasury ? res.finTreasury.liquidityPlanningHorizonMonths : '',
            );
            setTreasuryLiquidityPlanningFrequenceMonths(
              res.finTreasury ? res.finTreasury.liquidityPlanningFrequenceMonths : '',
            );
            setTreasuryLiquidityPlanningDurationDays(
              res.finTreasury ? res.finTreasury.liquidityPlanningDurationDays : '',
            );
            setTreasuryNumberEmployeesLiquidityPlanning(
              res.finTreasury ? res.finTreasury.numberEmployeesLiquidityPlanning : '',
            );
            setTreasuryAccuracyLiquidityPlanning(
              res.finTreasury ? res.finTreasury.accuracyLiquidityPlanning : '',
            );
            if (res.finFurtherDevelopmentFinance) {
              setFinFurtherDevelopmentFinanceFTE(res.finFurtherDevelopmentFinance.fte);
              if (res.finFurtherDevelopmentFinance.fte !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinFurtherDevelopmentFinanceFTE('');
            }
            if (res.finFurtherDevelopmentFinance) {
              setFinFurtherDevelopmentFinanceCosts(res.finFurtherDevelopmentFinance.cost);
              if (res.finFurtherDevelopmentFinance.cost !== 0) {
                setTotalProcessCostActive(false);
                setTotalProcessFteActive(false);
              }
            } else {
              setFinFurtherDevelopmentFinanceCosts('');
            }
          })
          .catch(() => {
            toast({
              title: t('benchmarking.finance.fetchFinanceDataFailed.title'),
              description: t('benchmarking.finance.fetchFinanceDataFailed.description'),
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: t('benchmarking.finance.fetchCompanyDataFailed.title'),
          description: '',
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  React.useEffect(() => {
    // Only fetch company data if the status of the reducer is 'idle'
    if (companyStatus === 'idle') {
      dispatch(fetchCompany());
    }
  }, []);

  /**
   * Fetch benchmarking data
   */
  React.useEffect(() => {
    fetchData();
  }, []);

  /**
   * Save Benchmarking Data and navigate to overview
   */
  const saveBenchmarkingData = () => {
    if (
      !validateDebtorsDurationDays ||
      !validateDebtorsPercentageShare ||
      !validateDebtorsPercentageShareCustomer ||
      !validateDebtorsNumberBill ||
      !validateDebtorsPercentageShareIncorrectBills ||
      !validateDebtorsNumberBillPosition ||
      !validateDebtorsTimeSpanUntilBill ||
      !validateDebtorsPercentageShareElecBills ||
      !validateDebtorsPercentageShareBillsReminder ||
      !validateDebtorsIncomingPayments ||
      !validateDebtorsTimeSpanUntilClosingBusinessCase ||
      !validateDebtorsIncorrectPaymentAssignment ||
      !validateDebtorsReceivablesTurnoverTime ||
      !validateDebtorsOutsourcedReceivables ||
      !validateCreditorsDurationProcessingCreditors ||
      !validateCreditorsIncorrectOrders ||
      !validateCreditorsSuppliersEdi ||
      !validateCreditorsNumberOrders ||
      !validateCreditorsNumberOrderPositions ||
      !validateCreditorsAutomatedOrderEntry ||
      !validateCreditorsElectrSentOrders ||
      !validateCreditorsNumberBills ||
      !validateCreditorsDifferenceBillsOrders ||
      !validateCreditorsBillWithoutOrder ||
      !validateCreditorsIncorrectPaymentsCreditors ||
      !validateCreditorsTimeSpanBillincomingPayment ||
      !validateFinChargesTravelPayroll ||
      !validateFinChargesTravelEmployees ||
      !validateFinChargesTravelIncorrectPayrolls ||
      !validateFinChargesTravelProcessingDays ||
      !validateFinChargesTravelTravelExpenseReport ||
      !validateFinTaxReportingUnit ||
      !validateFinTaxTaxDeclaration ||
      !validateFinTaxTaxDeclarationBalance ||
      !validateAssetMgtAssetsMasterData ||
      !validateAssetMgtAssetsBookedProcessed ||
      !validateConsolJournalEntries ||
      !validateConsolDaysMonthlyClosing ||
      !validateConsolDaysMonthlyClosingReleaseExternReporting ||
      !validateConsolDaysQuarterlyReport ||
      !validateConsolDaysQuarterlyReportReleaseExternReporting ||
      !validateConsolDaysAnnualReport ||
      !validateConsolDaysAnnualReportReleaseExternReporting ||
      !validateConsolNumberConsolidatedLegalEntities ||
      !validateConsolFteConsolidation ||
      !validateConsolCostConsolidation ||
      !validateConsolCompletionDataReporting ||
      !validateConsolMonthlyCompletion ||
      !validateConsolQuarterlyCompletion ||
      !validateConsolAnnualCompletion ||
      !validateTreasuryAvgExternPaymentTransactions ||
      !validateTreasuryAvgExternManualPayments ||
      !validateTreasuryAvgInternPayments ||
      !validateTreasuryEmployeesPaymentTransactions ||
      !validateTreasuryFinanceTransactionsConcluded ||
      !validateTreasuryInventoryTreasuryFinanceTransactions ||
      !validateTreasuryAutomatedProcessingTreasuryTransactions ||
      !validateTreasuryEmployeesTreasuryFinanceTransactionsCompleted ||
      !validateTreasuryEmployeesProcessingTreasuryFinanceTransactions ||
      !validateTreasuryEfficiency ||
      !validateTreasuryLiquidityPlanningHorizonMonths ||
      !validateTreasuryLiquidityPlanningFrequenceMonths ||
      !validateTreasuryLiquidityPlanningDurationDays ||
      !validateTreasuryNumberEmployeesLiquidityPlanning ||
      !validateTreasuryAccuracyLiquidityPlanning
    ) {
      toast({
        title: t('errors.benchmarking.finance.saveDataFailedCheckInput.title'),
        description: t('errors.benchmarking.finance.saveDataFailedCheckInput.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      return;
    }
    dispatch(
      editBenchmarkingFinance({
        ...benchmarkingFinance,
        sumFteFinance: parseFloat(processFTE, 10),
        sumCostFinance: parseInt(processCosts, 10),
        finAssetManagement: {
          ...benchmarkingFinance.finAssetManagement,
          fte: parseFloat(assetMgtProcessFTE, 10),
          cost: parseInt(assetMgtProcessCosts, 10),
          assetsMasterData: parseInt(assetMgtAssetsMasterData, 10),
          assetsBookedProcessed: parseInt(assetsMgtAssetsBookedProcessed, 10),
        },
        finChargesTravel: {
          ...benchmarkingFinance.finChargesTravel,
          fte: parseFloat(finChargesTravelFTE, 10),
          cost: parseInt(finChargesTravelCosts, 10),
          payroll: parseInt(finChargesTravelPayroll, 10),
          employees: parseInt(finChargesTravelEmployees, 10),
          incorrectPayrolls: parseInt(finChargesTravelIncorrectPayrolls, 10),
          travelExpenseReport: parseInt(finChargesTravelTravelExpenseReport, 10),
          processingDays: parseInt(finChargesTravelProcessingDays, 10),
        },
        finConsolidation: {
          ...benchmarkingFinance.finConsolidation,
          fte: parseFloat(consolidationProcessFTE, 10),
          cost: parseInt(consolidationProcessCosts, 10),
          journalEntries: parseInt(consolJournalEntries, 10),
          daysMonthlyClosing: parseInt(consolDaysMonthlyClosing, 10),
          daysMonthlyClosingReleaseExternReporting: parseInt(
            consolDaysMonthlyClosingReleaseExternReporting,
            10,
          ),
          daysQuarterlyReport: parseInt(consolDaysQuarterlyReport, 10),
          daysQuarterlyReportReleaseExternReporting: parseInt(
            consolDaysQuarterlyReportReleaseExternReporting,
            10,
          ),
          daysAnnualReport: parseInt(consolDaysAnnualReport, 10),
          daysAnnualReportReleaseExternReporting: parseInt(
            consolDaysAnnualReportReleaseExternReporting,
            10,
          ),
          numberConsolidatedLegalEntities: parseInt(consolNumberConsolidatedLegalEntities, 10),
          fteConsolidation: parseInt(consolFteConsolidation, 10),
          costConsolidation: parseInt(consolCostConsolidation, 10),
          completionDataReporting: parseInt(consolCompletionDataReporting, 10),
          monthlyCompletion: parseInt(consolMonthlyCompletion, 10),
          quarterlyCompletion: parseInt(consolQuarterlyCompletion, 10),
          annualCompletion: parseInt(consolAnnualCompletion, 10),
          extraLoadManualActivity: consolExtraLoadManualActivity,
          qualityDataReporting: consolQualityDataReporting,
          qualityVotingProcess: consolQualityVotingProcess,
          integrationLegalManagement: consolIntegrationLegalManagement,
          generationConsolidatedNumbers: consolGenerationConsolidatedNumbers,
          challengeGroupAccounting: consolChallengeGroupAccounting,
        },
        finCreditorsProcess: {
          ...benchmarkingFinance.finCreditorsProcess,
          fte: parseFloat(creditorsProcessFTE, 10),
          cost: parseInt(creditorsProcessCosts, 10),
          durationProcessingCreditors: parseInt(creditorsDurationProcessingCreditors, 10),
          incorrectOrders: parseInt(creditorsIncorrectOrders, 10),
          suppliersEdi: parseInt(creditorsSuppliersEdi, 10),
          numberOrders: parseInt(creditorsNumberOrders, 10),
          numberOrderPositions: parseInt(creditorsNumberOrderPositions, 10),
          automatedOrderEntry: parseInt(creditorsAutomatedOrderEntry, 10),
          electrSentOrders: parseInt(creditorsElectrSentOrders, 10),
          numberBills: parseInt(creditorsNumberBills, 10),
          differenceBillsOrders: parseInt(creditorsDifferenceBillsOrders, 10),
          billWithoutOrder: parseInt(creditorsBillWithoutOrder, 10),
          incorrectPaymentsCreditors: parseInt(creditorsIncorrectPaymentsCreditors, 10),
          timeSpanBillincomingPayment: parseInt(creditorsTimeSpanBillincomingPayment, 10),
        },
        finDebitorsProcess: {
          ...benchmarkingFinance.finDebitorsProcess,
          fte: parseFloat(debtorsProcessFTE, 10),
          cost: parseInt(debtorsProcessCosts, 10),
          durationDays: parseInt(debtorsDurationDays, 10),
          percentageShare: parseInt(debtorsPercentageShare, 10),
          percentageShareCustomer: parseInt(debtorsPercentageShareCustomer, 10),
          numberBills: parseInt(debtorsNumberBill, 10),
          percentageShareIncorrectBills: parseInt(debtorsPercentageShareIncorrectBills, 10),
          numberBillPosition: parseInt(debtorsNumberBillPosition, 10),
          timeSpanUntilBill: parseInt(debtorsTimeSpanUntilBill, 10),
          percentageShareElecBills: parseInt(debtorsPercentageShareElecBills, 10),
          percentageShareBillsReminder: parseInt(debtorsPercentageShareBillsReminder, 10),
          incomingPayments: parseInt(debtorsIncomingPayments, 10),
          timeSpanUntilClosingBusinessCase: parseInt(debtorsTimeSpanUntilClosingBusinessCase, 10),
          incorrectPaymentAssignment: parseInt(debtorsIncorrectPaymentAssignment, 10),
          receivablesTurnoverTime: parseInt(debtorsReceivablesTurnoverTime, 10),
          outsourcedReceivables: parseInt(debtorsOutsourcedReceivables, 10),
        },
        finTax: {
          ...benchmarkingFinance.finTax,
          fte: parseFloat(finTaxProcessFTE, 10),
          cost: parseInt(finTaxProcessCosts, 10),
          reportingUnit: parseInt(finTaxReportingUnit, 10),
          taxDeclaration: parseInt(finTaxTaxDeclaration, 10),
          taxDeclarationBalance: parseInt(finTaxTaxDeclarationBalance, 10),
        },
        finTreasury: {
          ...benchmarkingFinance.finTreasury,
          fte: parseFloat(finTreasuryProcessFTE, 10),
          cost: parseInt(finTreasuryProcessCosts, 10),
          avgExternPaymentTransactions: parseFloat(treasuryAvgExternPaymentTransactions, 10),
          avgExternManualPayments: parseFloat(treasuryAvgExternManualPayments, 10),
          avgInternPayments: parseFloat(treasuryAvgInternPayments, 10),
          employeesPaymentTransactions: parseFloat(treasuryEmployeesPaymentTransactions, 10),
          financeTransactionsConcluded: parseFloat(treasuryFinanceTransactionsConcluded, 10),
          inventoryTreasuryFinanceTransactions: parseFloat(
            treasuryInventoryTreasuryFinanceTransactions,
            10,
          ),
          automatedProcessingTreasuryTransactions: parseFloat(
            treasuryAutomatedProcessingTreasuryTransactions,
            10,
          ),
          employeesTreasuryFinanceTransactionsCompleted: parseFloat(
            treasuryEmployeesTreasuryFinanceTransactionsCompleted,
            10,
          ),
          employeesProcessingTreasuryFinanceTransactions: parseFloat(
            treasuryEmployeesProcessingTreasuryFinanceTransactions,
            10,
          ),
          efficiency: parseFloat(treasuryEfficiency, 10),
          liquidityPlanningHorizonMonths: parseFloat(treasuryLiquidityPlanningHorizonMonths, 10),
          liquidityPlanningFrequenceMonths: parseFloat(
            treasuryLiquidityPlanningFrequenceMonths,
            10,
          ),
          liquidityPlanningDurationDays: parseFloat(treasuryLiquidityPlanningDurationDays, 10),
          numberEmployeesLiquidityPlanning: parseFloat(
            treasuryNumberEmployeesLiquidityPlanning,
            10,
          ),
          accuracyLiquidityPlanning: parseFloat(treasuryAccuracyLiquidityPlanning, 10),
        },
        finFurtherDevelopmentFinance: {
          ...benchmarkingFinance.finFurtherDevelopmentFinance,
          fte: parseFloat(finFurtherDevelopmentFinanceFTE, 10),
          cost: parseInt(finFurtherDevelopmentFinanceCosts, 10),
        },
      }),
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchBenchmarkingFinance());
        history.push(`/benchmarking/${benchmarkingFinance.id}`);
        toast({
          title: t('finance.benchmarking.saveSuccessMessage.title'),
          description: t('finance.benchmarking.saveSuccessMessage.description'),
          status: 'success',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: t('errors.benchmarking.finance.saveDataFailed.title'),
          description: t('errors.benchmarking.finance.saveDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  /**
   * Handles the click Input by showing a warning
   */
  const handleInputClick = (process, name) => {
    if (!process.warningShown) {
      dispatch(deactivateWarning(name));
      onAlertOpen();
    }
  };

  const overallProcess = () => (
    <Card>
      <CardHeader
        title={t('finance.benchmarking.totalProcess.title')}
        tooltipText={t('finance.benchmarking.totalProcess.tooltip')}
      />
      <FormControl>
        <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
          <FormProperty
            label={t('global.fte')}
            formType="number-input"
            value={processFTE}
            onValueChange={(val) => handleProcessFteValueChange(val)}
            onClick={() => {
              handleInputClick(warningBenchmarkingFinance.finProcess, 'finProcess');
            }}
            testName="Company FTE"
          />
          <FormProperty
            label={t('global.costs')}
            formType="currency-input"
            value={processCosts}
            onValueChange={(val) => handleProcessCostValueChange(val)}
            onClick={() => {
              handleInputClick(warningBenchmarkingFinance.finProcess, 'finProcess');
            }}
            testName="Company Gesamtkosten"
          />
        </Stack>
      </FormControl>
    </Card>
  );

  const singleProcesses = (isDisabled) => (
    <Card>
      <CardHeader
        title={t('finance.benchmarking.singleProcesses.title')}
        tooltipText={t('finance.benchmarking.singleProcesses.tooltip')}
      />
      <Accordion allowToggle>
        <AccordionItem p={2} isDisabled={isDisabled}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                  background: 'horvath.grey-light',
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.debtorsProcess.title')}
                  tooltipText={t('finance.benchmarking.debtorsProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={debtorsProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, debtorsProcessFTE, setDebtorsProcessFTE)
                    }
                    testName="DP FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={debtorsProcessCosts}
                    onValueChange={(val) =>
                      handleCostValueChange(val, debtorsProcessCosts, setDebtorsProcessCosts)
                    }
                    testName="DP Gesamtkosten"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>

              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsDurationDays.title')}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsDurationDays.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsDurationDays}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsDurationDaysValueChange}
                    testName="Dauer DebitorenProzess"
                    invalid={!validateDebtorsDurationDays}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsDurationDays}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsPercentageShare.title')}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShare.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsPercentageShare}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsPercentageShareValueChange}
                    testName="Anteil Shared-Service-Center"
                    invalid={!validateDebtorsPercentageShare}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsPercentageShare}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareCustomer.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareCustomer.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsPercentageShareCustomer}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsPercentageShareCustomerValueChange}
                    testName="Anteil der EDI angebundenen Kunden"
                    invalid={!validateDebtorsPercentageShareCustomer}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsPercentageShareCustomer}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsNumberBill.title')}
                    tooltipText={t('finance.benchmarking.debtorsProcess.debtorsNumberBill.tooltip')}
                    formType="number-input"
                    value={debtorsNumberBill}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsNumberBillValueChange}
                    testName="Anzahl Kundenrechnungen pro Jahr"
                    invalid={!validateDebtorsNumberBill}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsNumberBill}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareIncorrectBills.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareIncorrectBills.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsPercentageShareIncorrectBills}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsPercentageShareIncorrectBillsValueChange}
                    testName="Anteil fehlerhafter Rechnungen"
                    invalid={!validateDebtorsPercentageShareIncorrectBills}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsPercentageShareIncorrectBills}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsNumberBillPosition.title')}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsNumberBillPosition.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsNumberBillPosition}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsNumberBillPositionValueChange}
                    testName="Anzahl Rechnungspositionen"
                    invalid={!validateDebtorsNumberBillPosition}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsNumberBillPosition}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilBill.title')}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilBill.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsTimeSpanUntilBill}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsTimeSpanUntilBillValueChange}
                    testName="Anzahl Tage bis zur Erstellung der Rechnung"
                    invalid={!validateDebtorsTimeSpanUntilBill}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsTimeSpanUntilBill}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareElecBills.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareElecBills.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsPercentageShareElecBills}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsPercentageShareElecBillsValueChange}
                    testName="Anzahl der elektronisch versandten Rechnungen"
                    invalid={!validateDebtorsPercentageShareElecBills}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsPercentageShareElecBills}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareBillsReminder.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsPercentageShareBillsReminder.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsPercentageShareBillsReminder}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsPercentageShareBillsReminderValueChange}
                    testName="Anzahl der elektronisch versandten Rechnungen mit anschließendem Mahnverfahren"
                    invalid={!validateDebtorsPercentageShareBillsReminder}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsPercentageShareBillsReminder}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.debtorsProcess.debtorsIncomingPayments.title')}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsIncomingPayments.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsIncomingPayments}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsIncomingPaymentsValueChange}
                    testName="Anzahl der Zahlungseingänge pro Jahr"
                    invalid={!validateDebtorsIncomingPayments}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsIncomingPayments}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilClosingBusinessCase.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsTimeSpanUntilClosingBusinessCase.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsTimeSpanUntilClosingBusinessCase}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsTimeSpanUntilClosingBusinessCaseValueChange}
                    testName="Anzahl der Tage zwischen Zahlungseingang und Abschluss des Geschäftsfalles"
                    invalid={!validateDebtorsTimeSpanUntilClosingBusinessCase}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsTimeSpanUntilClosingBusinessCase}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsIncorrectPaymentAssignment.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsIncorrectPaymentAssignment.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsIncorrectPaymentAssignment}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsIncorrectPaymentAssigmentValueChange}
                    testName="Anteil fehlerhafter elektronischer Zahlungszuordnungen"
                    invalid={!validateDebtorsIncorrectPaymentAssignment}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsIncorrectPaymentAssignment}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsReceivablesTurnoverTime.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsReceivablesTurnoverTime.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsReceivablesTurnoverTime}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsReceivablesTurnoverTimeValueChange}
                    testName="Höhe der durchschnittlichen Forderungsumschlagsdauer"
                    invalid={!validateDebtorsReceivablesTurnoverTime}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsReceivablesTurnoverTime}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.debtorsProcess.debtorsOutsourcedReceivables.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.debtorsProcess.debtorsOutsourcedReceivables.tooltip',
                    )}
                    formType="number-input"
                    value={debtorsOutsourcedReceivables}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleDebtorsOutsourcedReceivablesValueChange}
                    testName="Anteil der ausgelagerten Forderungen"
                    invalid={!validateDebtorsOutsourcedReceivables}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgDebtorsOutsourcedReceivables}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.creditorsProcess.title')}
                  tooltipText={t('finance.benchmarking.creditorsProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={creditorsProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, creditorsProcessFTE, setCreditorsProcessFTE)
                    }
                    testName="KP FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={creditorsProcessCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, creditorsProcessCosts, setCreditorsProcessCosts);
                    }}
                    testName="KP Gesamtkosten"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsDurationProcessingCreditors.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsDurationProcessingCreditors.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsDurationProcessingCreditors}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsDurationProcessingCreditorsValueChange}
                    testName="Dauer Kreditorenprozess"
                    invalid={!validateCreditorsDurationProcessingCreditors}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsDurationProcessingCreditors}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsIncorrectOrders.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsIncorrectOrders.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsIncorrectOrders}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsIncorrectOrdersValueChange}
                    testName="Anteil Shared-Service-Center"
                    invalid={!validateCreditorsIncorrectOrders}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsIncorrectOrders}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.creditorsProcess.creditorsSuppliersEdi.title')}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsSuppliersEdi.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsSuppliersEdi}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsSuppliersEdiValueChange}
                    testName="Anteil der EDI angebundenen Kunden"
                    invalid={!validateCreditorsSuppliersEdi}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsSuppliersEdi}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.creditorsProcess.creditorsNumberOrders.title')}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsNumberOrders.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsNumberOrders}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsNumberOrdersValueChange}
                    testName="Anzahl Kundenrechnungen pro Jahr"
                    invalid={!validateCreditorsNumberOrders}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsNumberOrders}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsNumberOrderPositions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsNumberOrderPositions.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsNumberOrderPositions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsNumberOrderPositionsValueChange}
                    testName="Anteil fehlerhafter Rechnungen"
                    invalid={!validateCreditorsNumberOrderPositions}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsNumberOrderPositions}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsAutomatedOrderEntry.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsAutomatedOrderEntry.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsAutomatedOrderEntry}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsAutomatedOrderEntryValueChange}
                    testName="Anzahl Rechnungspositionen"
                    invalid={!validateCreditorsAutomatedOrderEntry}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsAutomatedOrderEntry}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsElectrSentOrders.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsElectrSentOrders.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsElectrSentOrders}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsElectrSentOrdersValueChange}
                    testName="Anzahl Tage bis zur Erstellung der Rechnung"
                    invalid={!validateCreditorsElectrSentOrders}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsElectrSentOrders}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.creditorsProcess.creditorsNumberBills.title')}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsNumberBills.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsNumberBills}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsNumberBillsValueChange}
                    testName="Anzahl der elektronisch versandten Rechnungen"
                    invalid={!validateCreditorsNumberBills}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsNumberBills}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsDifferenceBillsOrders.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsDifferenceBillsOrders.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsDifferenceBillsOrders}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsDifferenceBillsOrdersValueChange}
                    testName="Anzahl der elektronisch versandten Rechnungen mit anschließendem Mahnverfahren"
                    invalid={!validateCreditorsDifferenceBillsOrders}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsDifferenceBillsOrders}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsBillWithoutOrder.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsBillWithoutOrder.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsBillWithoutOrder}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsBillWithoutOrderValueChange}
                    testName="Anzahl der Zahlungseingänge pro Jahr"
                    invalid={!validateCreditorsBillWithoutOrder}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsBillWithoutOrder}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsIncorrectPaymentsCreditors.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsIncorrectPaymentsCreditors.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsIncorrectPaymentsCreditors}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsIncorrectPaymentsCreditorsValueChange}
                    testName="Anzahl der Zahlungseingänge pro Jahr"
                    invalid={!validateCreditorsIncorrectPaymentsCreditors}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsIncorrectPaymentsCreditors}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.creditorsProcess.creditorsTimeSpanBillincomingPayment.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.creditorsProcess.creditorsTimeSpanBillincomingPayment.tooltip',
                    )}
                    formType="number-input"
                    value={creditorsTimeSpanBillincomingPayment}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleCreditorsTimeSpanBillincomingPaymentValueChange}
                    testName="Anzahl der Zahlungseingänge pro Jahr"
                    invalid={!validateCreditorsTimeSpanBillincomingPayment}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgCreditorsTimeSpanBillincomingPayment}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.finChargesTravel.title')}
                  tooltipText={t('finance.benchmarking.finChargesTravel.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={finChargesTravelFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, finChargesTravelFTE, setFinChargesTravelFTE)
                    }
                    testName="Entgelt- und Reisekostenabrechnung FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={finChargesTravelCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, finChargesTravelCosts, setFinChargesTravelCosts);
                    }}
                    testName="Entgelt- und Reisekostenabrechnung Gesamtkosten"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('finance.benchmarking.finChargesTravel.finChargesTravelPayroll.title')}
                    tooltipText={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelPayroll.tooltip',
                    )}
                    formType="number-input"
                    value={finChargesTravelPayroll}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinChargesTravelPayrollValueChange}
                    testName="Anzahl der Entgeltabrechnungen"
                    invalid={!validateFinChargesTravelPayroll}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinChargesTravelPayroll}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelEmployees.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelEmployees.tooltip',
                    )}
                    formType="number-input"
                    value={finChargesTravelEmployees}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinChargesTravelEmployeesValueChange}
                    testName="Anzahl Mitarbeiter für Entgeltabrechnung"
                    invalid={!validateFinChargesTravelEmployees}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinChargesTravelEmployees}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelIncorrectPayrolls.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelIncorrectPayrolls.tooltip',
                    )}
                    formType="number-input"
                    value={finChargesTravelIncorrectPayrolls}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinChargesTravelIncorrectPayrollsValueChange}
                    testName="Anzahl fehlerhafter Entgeltabrechnungen"
                    invalid={!validateFinChargesTravelIncorrectPayrolls}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinChargesTravelIncorrectPayrolls}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelTravelExpenseReport.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelTravelExpenseReport.tooltip',
                    )}
                    formType="number-input"
                    value={finChargesTravelTravelExpenseReport}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinChargesTravelTravelExpenseReportValueChange}
                    testName="Anzahl der Reisekostenabrechnungen"
                    invalid={!validateFinChargesTravelTravelExpenseReport}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinChargesTravelTravelExpenseReport}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelProcessingDays.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finChargesTravel.finChargesTravelProcessingDays.tooltip',
                    )}
                    formType="number-input"
                    value={finChargesTravelProcessingDays}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinChargesTravelProcessingDaysValueChange}
                    testName="Anzahl AT für Reisekostenabrechnung"
                    invalid={!validateFinChargesTravelProcessingDays}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinChargesTravelProcessingDays}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.assetMgtProcess.title')}
                  tooltipText={t('finance.benchmarking.assetMgtProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={assetMgtProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, assetMgtProcessFTE, setAssetMgtProcessFTE)
                    }
                    testName="Asset Management FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={assetMgtProcessCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, assetMgtProcessCosts, setAssetMgtProcessCosts);
                    }}
                    testName="Asset Management Gesamtkosten"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('finance.benchmarking.assetMgtProcess.assetMgtAssetsMasterData.title')}
                    tooltipText={t(
                      'finance.benchmarking.assetMgtProcess.assetMgtAssetsMasterData.tooltip',
                    )}
                    formType="number-input"
                    value={assetMgtAssetsMasterData}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleAssetMgtAssetsMasterDataValueChange}
                    testName="Anzahl Anlagen mit Stammdaten"
                    invalid={!validateAssetMgtAssetsMasterData}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgAssetMgtAssetsMasterData}</Text>
                  </Box>
                </FormControl>
                <FormControl>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.assetMgtProcess.assetsMgtAssetsBookedProcessed.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.assetMgtProcess.assetsMgtAssetsBookedProcessed.tooltip',
                    )}
                    formType="number-input"
                    value={assetsMgtAssetsBookedProcessed}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleAssetMgtAssetsBookedProcessedValueChange}
                    testName="Dauer Kreditorenprozess"
                    invalid={!validateAssetMgtAssetsBookedProcessed}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgAssetMgtAssetsBookedProcessed}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>

        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.consolidationProcess.title')}
                  tooltipText={t('finance.benchmarking.consolidationProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={consolidationProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, consolidationProcessFTE, setConsolidationProcessFTE)
                    }
                    testName="Consolidation FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={consolidationProcessCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(
                        val,
                        consolidationProcessCosts,
                        setConsolidationProcessCosts,
                      );
                    }}
                    testName="Consolidation Costs"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolJournalEntries.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolJournalEntries.tooltip',
                    )}
                    formType="number-input"
                    value={consolJournalEntries}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolJournalEntriesValueChange}
                    invalid={!validateConsolJournalEntries}
                    testName="Anzahl Journaleintragungen"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolJournalEntries}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosing.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosing.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysMonthlyClosing}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolDaysMonthlyClosingValueChange}
                    testName="Arbeitstage bis Monatsabschluss"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolDaysMonthlyClosing}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosingReleaseExternReporting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysMonthlyClosingReleaseExternReporting.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysMonthlyClosingReleaseExternReporting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolDaysMonthlyClosingReleaseExternReportingValueChange}
                    testName="Arbeitstage Monatsabschluss bis Reportings"
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgConsolDaysMonthlyClosingReleaseExternReporting}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReport.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReport.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysQuarterlyReport}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolDaysQuarterReportValueChange}
                    testName="Arbeitstage bis Quartalabschluss"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolDaysQuarterlyReport}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReportReleaseExternReporting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysQuarterlyReportReleaseExternReporting.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysQuarterlyReportReleaseExternReporting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolDaysQuarterlyReportReleaseExternReportingValueChange}
                    testName="Arbeitstage Quartalabschluss bis Reportings"
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgConsolDaysQuarterlyReportReleaseExternReporting}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysAnnualReport.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysAnnualReport.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysAnnualReport}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsoleDaysAnnualReportValueChange}
                    testName="Arbeitstage bis Jahresschluss"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolDaysAnnualReport}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolDaysAnnualReportReleaseExternReporting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolDaysAnnualReportReleaseExternReporting.tooltip',
                    )}
                    formType="number-input"
                    value={consolDaysAnnualReportReleaseExternReporting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsoleDaysAnnualReportReleaseExternReportingValueChange}
                    testName="Arbeitstage Jahresabschluss bis Reportings"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolDaysAnnualReportReleaseExternReporting}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolNumberConsolidatedLegalEntities.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolNumberConsolidatedLegalEntities.tooltip',
                    )}
                    formType="number-input"
                    value={consolNumberConsolidatedLegalEntities}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsoleNumberConsolidatedLegalEntitiesValueChange}
                    testName="Anzahl Legaleinheiten"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolNumberConsolidatedLegalEntities}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolFteConsolidation.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolFteConsolidation.tooltip',
                    )}
                    formType="number-input"
                    value={consolFteConsolidation}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsoleFteConsolidationValueChange}
                    testName="FTE Konsolidierung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolFteConsolidation}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolCostConsolidation.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolCostConsolidation.tooltip',
                    )}
                    formType="number-input"
                    value={consolCostConsolidation}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolCostConsolidationValueChange}
                    testName="Kosten Konsolidierung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolCostConsolidation}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolCompletionDataReporting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolCompletionDataReporting.tooltip',
                    )}
                    formType="number-input"
                    value={consolCompletionDataReporting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolCompletionDataReportingValueChange}
                    testName="Abschluss Datenmeldung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolCompletionDataReporting}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolAnnualCompletion.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolAnnualCompletion.tooltip',
                    )}
                    formType="number-input"
                    value={consolAnnualCompletion}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolAnnualCompletionValueChange}
                    testName="Fertigstellung Abschlüsse"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolAnnualCompletion}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolQuarterlyCompletion.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolQuarterlyCompletion.tooltip',
                    )}
                    formType="number-input"
                    value={consolQuarterlyCompletion}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolQuarterlyCompletionValueChange}
                    testName="Fertigstellung Abschlüsse"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolQuarterlyCompletion}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolMonthlyCompletion.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolMonthlyCompletion.tooltip',
                    )}
                    formType="number-input"
                    value={consolMonthlyCompletion}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleConsolMonthlyCompletionValueChange}
                    testName="Fertigstellung Abschlüsse"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgConsolMonthlyCompletion}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolExtraLoadManualActivity.tooltip',
                    )}
                    formType="selectNameValue"
                    value={consolExtraLoadManualActivity}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolExtraLoadManualActivity}
                    options={extraLoadManualActivityOptions}
                    testName="Mehrbelastung"
                  />
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolQualityDataReporting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolQualityDataReporting.tooltip',
                    )}
                    formType="selectNameValue"
                    value={consolQualityDataReporting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolQualityDataReporting}
                    options={qualityDataReportingOptions}
                    testName="Qualität Datenmeldung"
                  />
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolQualityVotingProcess.tooltip',
                    )}
                    value={consolQualityVotingProcess}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolQualityVotingProcess}
                    options={qualityDataProcessOptions}
                    testName="Qualität Abstimmungsprozess"
                  />
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolIntegrationLegalManagement.tooltip',
                    )}
                    formType="selectNameValue"
                    value={consolIntegrationLegalManagement}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolIntegrationLegalManagement}
                    options={integrationLegalManagementOptions}
                    testName="Integration der Sichten"
                  />
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolGenerationConsolidatedNumbers.tooltip',
                    )}
                    formType="selectNameValue"
                    value={consolGenerationConsolidatedNumbers}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolGenerationConsolidatedNumbers}
                    options={generationConsolidatedNumbersOptions}
                    testName="Erzeugung konsolidierter Zahlen"
                  />
                  <FormProperty
                    label={t(
                      'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.consolidationProcess.consolChallengeGroupAccounting.tooltip',
                    )}
                    formType="selectNameValue"
                    value={consolChallengeGroupAccounting}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={setConsolChallengeGroupAccounting}
                    options={challengeGroupAccountingOptions}
                    testName="Herausforderungen"
                  />
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.finTaxProcess.title')}
                  tooltipText={t('finance.benchmarking.finTaxProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={finTaxProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, finTaxProcessFTE, setFinTaxProcessFTE)
                    }
                    testName="Tax FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={finTaxProcessCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(val, finTaxProcessCosts, setFinTaxProcessCosts);
                    }}
                    testName="Tax Gesamtkosten"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t('finance.benchmarking.finTaxProcess.finTaxReportingUnit.title')}
                    tooltipText={t(
                      'finance.benchmarking.finTaxProcess.finTaxReportingUnit.tooltip',
                    )}
                    formType="number-input"
                    value={finTaxReportingUnit}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinTaxReportingUnitValueChange}
                    testName="Anzahl Meldeeinheiten"
                    invalid={!validateFinTaxReportingUnit}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinTaxReportingUnit}</Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.finTaxProcess.finTaxTaxDeclaration.title')}
                    tooltipText={t(
                      'finance.benchmarking.finTaxProcess.finTaxTaxDeclaration.tooltip',
                    )}
                    formType="number-input"
                    value={finTaxTaxDeclaration}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinTaxTaxDeclarationValueChange}
                    testName="Anzahl AT für Steuererklärung"
                    invalid={!validateFinTaxTaxDeclaration}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinTaxTaxDeclaration}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTaxProcess.finTaxTaxDeclarationBalance.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTaxProcess.finTaxTaxDeclarationBalance.tooltip',
                    )}
                    formType="number-input"
                    value={finTaxTaxDeclarationBalance}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleFinTaxTaxDeclarationBalanceValueChange}
                    testName="Anzahl AT für E-Bilanz"
                    invalid={!validateFinTaxTaxDeclarationBalance}
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgFinTaxTaxDeclarationBalance}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <AccordionItem p={2}>
          {({ isExpanded }) => (
            <Card>
              <AccordionButton
                sx={{
                  padding: '0',
                  display: 'block',
                }}
                _disabled={{ color: 'horvath.black' }}
              >
                <CardHeader
                  title={t('finance.benchmarking.finTreasuryProcess.title')}
                  tooltipText={t('finance.benchmarking.finTreasuryProcess.tooltip')}
                  action={
                    isExpanded ? (
                      <ChevronUpIcon fontSize="30px" />
                    ) : (
                      <ChevronDownIcon fontSize="30px" />
                    )
                  }
                />
              </AccordionButton>
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <FormProperty
                    label={t('global.fte')}
                    formType="number-input"
                    value={finTreasuryProcessFTE}
                    onValueChange={(val) =>
                      handleFteValueChange(val, finTreasuryProcessFTE, setFinTreasuryProcessFTE)
                    }
                    testName="Treasury FTE"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                  <FormProperty
                    label={t('global.costs')}
                    formType="currency-input"
                    value={finTreasuryProcessCosts}
                    onValueChange={(val) => {
                      handleCostValueChange(
                        val,
                        finTreasuryProcessCosts,
                        setFinTreasuryProcessCosts,
                      );
                    }}
                    testName="Treasury Costs"
                    disabled={benchmarkingFinance.submitDate !== null}
                  />
                </Stack>
              </FormControl>
              <AccordionPanel>
                <Divider mb={1} />
                <FormControl>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgExternPaymentTransactions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgExternPaymentTransactions.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryAvgExternPaymentTransactions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryAvgExternPaymentTransactionsValueChange}
                    invalid={!validateTreasuryAvgExternPaymentTransactions}
                    testName="Anzahl Zahlungs-Transaktionen"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryAvgExternPaymentTransactions}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgExternManualPayments.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgExternManualPayments.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryAvgExternManualPayments}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryAvgExternManualPaymentsValueChange}
                    invalid={!validateTreasuryAvgExternManualPayments}
                    testName="Anzahl manueller Zahlungen"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryAvgExternManualPayments}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgInternPayments.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAvgInternPayments.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryAvgInternPayments}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryAvgInternPaymentsValueChange}
                    invalid={!validateTreasuryAvgInternPayments}
                    testName="Anzahl interne Zahlungen"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryAvgInternPayments}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesPaymentTransactions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesPaymentTransactions.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryEmployeesPaymentTransactions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryEmployeesPaymentTransactionsValueChange}
                    invalid={!validateTreasuryEmployeesPaymentTransactions}
                    testName="Anzahl Mitarbeiter für den Zahlungsverkehr"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryEmployeesPaymentTransactions}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryFinanceTransactionsConcluded.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryFinanceTransactionsConcluded.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryFinanceTransactionsConcluded}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryFinanceTransactionsConcludedValueChange}
                    invalid={!validateTreasuryFinanceTransactionsConcluded}
                    testName="Anzahl abgeschlossener Treasury-Finanzgeschäfte"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryFinanceTransactionsConcluded}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryInventoryTreasuryFinanceTransactions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryInventoryTreasuryFinanceTransactions.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryInventoryTreasuryFinanceTransactions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryInventoryTreasuryFinanceTransactionsValueChange}
                    invalid={!validateTreasuryInventoryTreasuryFinanceTransactions}
                    testName="Bestand Treasury-Finanzgeschäfte"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryInventoryTreasuryFinanceTransactions}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAutomatedProcessingTreasuryTransactions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAutomatedProcessingTreasuryTransactions.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryAutomatedProcessingTreasuryTransactions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryAutomatedProcessingTreasuryTransactionsValueChange}
                    invalid={!validateTreasuryAutomatedProcessingTreasuryTransactions}
                    testName="Abwicklung der Treasury-Finanzgeschäfte"
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgTreasuryAutomatedProcessingTreasuryTransactions}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesTreasuryFinanceTransactionsCompleted.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesTreasuryFinanceTransactionsCompleted.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryEmployeesTreasuryFinanceTransactionsCompleted}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={
                      handleTreasuryEmployeesTreasuryFinanceTransactionsCompletedValueChange
                    }
                    invalid={!validateTreasuryEmployeesTreasuryFinanceTransactionsCompleted}
                    testName="Anzahl Mitarbeiter für Treasury-Finanzgeschäfte"
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgTreasuryEmployeesTreasuryFinanceTransactionsCompleted}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesProcessingTreasuryFinanceTransactions.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEmployeesProcessingTreasuryFinanceTransactions.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryEmployeesProcessingTreasuryFinanceTransactions}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={
                      handleTreasuryEmployeesProcessingTreasuryFinanceTransactionsValueChange
                    }
                    invalid={!validateTreasuryEmployeesProcessingTreasuryFinanceTransactions}
                    testName="Anzahl Mitarbeiter für die Abwicklung von Treasury-Finanzgeschäfte"
                  />
                  <Box pl={7}>
                    <Text color="red">
                      {errorMsgTreasuryEmployeesProcessingTreasuryFinanceTransactions}
                    </Text>
                  </Box>
                  <FormProperty
                    label={t('finance.benchmarking.finTreasuryProcess.treasuryEfficiency.title')}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryEfficiency.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryEfficiency}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryEfficiencyValueChange}
                    invalid={!validateTreasuryEfficiency}
                    testName="Effizienz Kontoauszugsverarbeitung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryEfficiency}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningHorizonMonths.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningHorizonMonths.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryLiquidityPlanningHorizonMonths}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryLiquidityPlanningHorizonMonthsValueChange}
                    invalid={!validateTreasuryLiquidityPlanningHorizonMonths}
                    testName="Planungshorizont Liquiditätsplanung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryLiquidityPlanningHorizonMonths}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningFrequenceMonths.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningFrequenceMonths.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryLiquidityPlanningFrequenceMonths}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryLiquidityPlanningFrequenceMonthsValueChange}
                    invalid={!validateTreasuryLiquidityPlanningFrequenceMonths}
                    testName="Planungsfrequenz Liquiditätsplanung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryLiquidityPlanningFrequenceMonths}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningDurationDays.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryLiquidityPlanningDurationDays.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryLiquidityPlanningDurationDays}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryLiquidityPlanningDurationDaysValueChange}
                    invalid={!validateTreasuryLiquidityPlanningDurationDays}
                    testName="Planungsdauer Liquiditätsplanung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryLiquidityPlanningDurationDays}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryNumberEmployeesLiquidityPlanning.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryNumberEmployeesLiquidityPlanning.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryNumberEmployeesLiquidityPlanning}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryNumberEmployeesLiquidityPlanningValueChange}
                    invalid={!validateTreasuryNumberEmployeesLiquidityPlanning}
                    testName="Anzahl an Liquiditätsplanung beteiligter Mitarbeiter"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryNumberEmployeesLiquidityPlanning}</Text>
                  </Box>
                  <FormProperty
                    label={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAccuracyLiquidityPlanning.title',
                    )}
                    tooltipText={t(
                      'finance.benchmarking.finTreasuryProcess.treasuryAccuracyLiquidityPlanning.tooltip',
                    )}
                    formType="number-input"
                    value={treasuryAccuracyLiquidityPlanning}
                    disabled={benchmarkingFinance.submitDate !== null}
                    onValueChange={handleTreasuryAccuracyLiquidityPlanningValueChange}
                    invalid={!validateTreasuryAccuracyLiquidityPlanning}
                    testName="Genauigkeit Liquiditätsplanung"
                  />
                  <Box pl={7}>
                    <Text color="red">{errorMsgTreasuryAccuracyLiquidityPlanning}</Text>
                  </Box>
                </FormControl>
              </AccordionPanel>
            </Card>
          )}
        </AccordionItem>
        <BenchmarkingInputCard
          titleText={t('finance.benchmarking.finFurtherDevelopmentFinance.title')}
          tooltipText={t('finance.benchmarking.finFurtherDevelopmentFinance.tooltip')}
          fteState={finFurtherDevelopmentFinanceFTE}
          setFteState={(val) =>
            handleFteValueChange(
              val,
              finFurtherDevelopmentFinanceFTE,
              setFinFurtherDevelopmentFinanceFTE,
            )
          }
          fteTestName="Further Development Finance FTE"
          fteDisabled={benchmarkingFinance.submitDate !== null}
          costState={finFurtherDevelopmentFinanceCosts}
          setCostState={(val) =>
            handleCostValueChange(
              val,
              finFurtherDevelopmentFinanceCosts,
              setFinFurtherDevelopmentFinanceCosts,
            )
          }
          costTestName="Further Development Finance Gesamtkosten"
          costDisabled={benchmarkingFinance.submitDate !== null}
        />
      </Accordion>
    </Card>
  );

  /**
   * Renders the Benchmarkings
   */
  const renderFinanceKPIs = () => {
    if (benchmarkingStatus === 'loading' || companyStatus === 'loading') {
      return (
        <>
          <Center mt={8}>
            <Spinner />
          </Center>
          <Center>
            <Text>{t('finance.benchmarking.loading')}</Text>
          </Center>
        </>
      );
    }
    if (benchmarkingStatus === 'succeeded' && companyStatus === 'succeeded') {
      return (
        <>
          <PageHeader title={`Benchmarking Finance ${benchmarkingFinance.year}`} />
          <p>
            {t('finance.benchmarking.info')} {benchmarkingFinance.year}.
          </p>
          <ContentWrapper>
            <SliderPropery
              label=""
              marks={[
                t('finance.benchmarking.sliderOptions.singleProcesses'),
                t('finance.benchmarking.sliderOptions.totalProcess'),
              ]}
              marksPositions={['-50', '-50']}
              marksWidth={100}
              value={currentProcessGranularity}
              onValueChange={setCurrentProcessGranularity}
              testName="Gesamtprozess vs Einzelprozess"
            />
            {currentProcessGranularity === t('finance.benchmarking.singleProcesses.title') ? (
              <>
                <Disable>
                  <p>{overallProcess()}</p>
                </Disable>
                <br />
                <p>{singleProcesses(false)}</p>
              </>
            ) : (
              <>
                <p>{overallProcess()}</p>
                <br />
                <Disable>
                  <p>{singleProcesses(true)}</p>
                </Disable>
              </>
            )}
            <Center p={3}>
              <Button
                color="primary"
                text={t('finance.benchmarking.saveButtonText')}
                action={() => {
                  saveBenchmarkingData();
                }}
                disabled={benchmarkingFinance.submitDate !== null}
                testId="Save"
              />
            </Center>
          </ContentWrapper>
        </>
      );
    }
    if (benchmarkingStatus === 'failed') {
      return (
        <ErrorStat
          errorMessage={benchmarkingError}
          onRefresh={fetchData}
          mt={8}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    return null;
  };
  return (
    <div>
      {renderFinanceKPIs()}
      <Dialog title="Warnung!" type="warning" isOpen={isAlertShown} onClose={onAlertClose}>
        <Box p={3}>{t('finance.benchmarking.changeInfoDialogText')}</Box>
      </Dialog>
    </div>
  );
};

export default FinanceBenchmarking;
