/*
 * Renders the page shell component
 */
import {
  Avatar,
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  toast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BiBriefcase } from 'react-icons/bi';
import { FiLogOut, FiSettings, FiUsers } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import * as ReactDOM from 'react-router-dom';
import packageJson from '../../../package.json';
import HorvathImage from '../../assets/img/Horvath_CFO-Panel_Logo_neg_RGB.svg';
import { authSelector, logout as logoutRequest } from '../slices/authSlice';
import { fetchCompany, selectCompanyName } from '../slices/companySlice';
import { fetchUser, isUserAdmin, selectUserName } from '../slices/userSlice';
import Clickable from './Clickable';
import LanguageToggle from './LanguageToggle';

/**
 * Displays the page shell
 * @returns The navigation bar as the shell of the application
 */
const PageShell = ({ children }) => {
  const { t } = useTranslation();
  const history = ReactDOM.useHistory();

  const auth = useSelector(authSelector);
  const isAdmin = useSelector(isUserAdmin);

  const [checkRole, setCheckRole] = React.useState(false);

  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);
  const companyName = useSelector(selectCompanyName);

  /**
   * Displays the application version if user role is ADMIN
   * @returns true, if user role is ADMIN
   */
  const checkForAdminRole = () => {
    if (checkRole === 'ADMIN') {
      return true;
    }
    return false;
  };

  // Commented out for the time being
  // const userStatus = useSelector((state) => {
  //   return state.user.status;
  // });

  // const companyStatus = useSelector((state) => {
  //   return state.company.status;
  // });

  // Is executed every time page shell is rendered
  React.useEffect(() => {
    if (auth) {
      dispatch(fetchUser())
        .then(unwrapResult)
        .then((res) => {
          setCheckRole(res.role);
        });
    }
  });

  // Is executed every time page shell is rendered
  React.useEffect(() => {
    if (auth) {
      dispatch(fetchCompany());
    }
  });

  /**
   * Navigates to the company
   */
  const navigateToCompanyView = () => {
    history.push('/user');
  };

  /**
   * Logs out the user by calling the logout route
   */
  const logout = () => {
    dispatch(logoutRequest())
      .then(unwrapResult)
      .then(() => {
        history.push('/login');
      })
      .catch(() => {
        toast({
          title: t('errors.logout.logoutFailed.title'),
          description: t('errors.logout.logoutFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box>
        <Box
          bgGradient="linear(to-r, rgb(16, 42, 58), rgb(137, 193, 222), rgb(84,143,169))"
          w="100%"
          p={3}
          color="black"
          sx={{ position: 'fixed', 'z-index': 100 }}
        >
          <Flex>
            <Center>
              <Clickable>
                <Image
                  htmlWidth="250px"
                  objectFit="cover"
                  src={HorvathImage}
                  onClick={() => history.push('/')}
                  data-testid="home-btn"
                />
              </Clickable>
              {checkForAdminRole() ? (
                <Box>
                  <Text color="white">
                    {t('version')}: {packageJson.version}
                  </Text>
                </Box>
              ) : null}
            </Center>
            {auth ? (
              <>
                <Spacer />
                <Center>
                  <Flex>
                    <Clickable>
                      <Avatar onClick={navigateToCompanyView} data-testid="company-btn" />
                    </Clickable>
                    <Box ml={2} mr={5}>
                      <Clickable>
                        <Text color="#ffffff" onClick={navigateToCompanyView} fontSize="md">
                          {companyName}
                        </Text>
                      </Clickable>
                      <Clickable>
                        <Text color="#ffffff" onClick={navigateToCompanyView} fontSize="md">
                          {userName}
                        </Text>
                      </Clickable>
                    </Box>
                    <Center>
                      {/* <Clickable >
                        <svg
                          onClick={navigateToCompanyView}
                          height="22"
                          viewBox="0 -1 401.52289 401"
                          width="37.125"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ fill: '#ffffff' }}
                        >
                          <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
                          <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
                        </svg>
                      </Clickable> */}
                      {isAdmin ? (
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            aria-label="Settings"
                            icon={<FiSettings fontSize="1.5rem" color="#ffffff" />}
                            variant=""
                          />
                          <MenuList>
                            <MenuItem
                              icon={<FiUsers />}
                              onClick={() => history.push('/admin/user')}
                            >
                              {t('adminSettings.userButton')}
                            </MenuItem>
                            <MenuItem
                              icon={<BiBriefcase />}
                              onClick={() => history.push('/admin/company')}
                            >
                              {t('adminSettings.companyButton')}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      ) : null}
                      <IconButton
                        variant="ghost"
                        icon={<FiLogOut />}
                        onClick={logout}
                        color="white"
                        fontSize="22"
                        mr={5}
                      />
                      {/* <Divider orientation="vertical" ml /> */}
                      <LanguageToggle />
                    </Center>
                  </Flex>
                </Center>
              </>
            ) : (
              <>
                <Spacer />
                <Center>
                  <LanguageToggle />
                </Center>
              </>
            )}
          </Flex>
        </Box>
      </Box>

      <Box pt={20} pl={3} pr={3}>
        {children}
      </Box>
    </>
  );
};

export default PageShell;
