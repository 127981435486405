/*
 * Slice which provides reducer and states of the benchmarking company info state
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: {
    id: null,
    companyId: null,
    year: '',
    sumFteControlling: '',
    sumCostControlling: '',
    sumFteFinance: '',
    sumCostFinance: '',
    submitDate: '',
    ctrlBcm: {
      fte: '',
      cost: '',
    },
    ctrlCpra: {
      fte: '',
      cost: '',
      varianceCtrAcc: '',
      forecastQuality: '',
      planQuality: '',
      deviation: '',
    },
    ctrlForecast: {
      fte: '',
      cost: '',
      typeForecast: 'YEAR_END',
      levelDetailWeek: '',
      levelDetailMonth: '',
      levelDetailQuarter: '',
      levelDetailOthers: '',
      accuracy: '',
      forecastHorizonMonths: '',
    },
    ctrlFunctional: {
      fte: '',
      cost: '',
    },
    ctrlImprovement: {
      fte: '',
      cost: '',
    },
    ctrlManagementReporting: {
      fte: '',
      cost: '',
      amountReportsExecutiveBoard: '',
      amountReportsSupervisoryBoard: '',
      adHocReports: '',
      standardizedReports: '',
      kpisAmount: '',
      automationPotentialDataManagement: '',
      automationPotentialReportGeneration: '',
      automationPotentialAnalysis: '',
      automationPotentialDecisionSupport: '',
      formOfUsePush: '',
      formOfUseStandard: '',
      formOfUseSelfService: '',
      reportShares: '',
      degreeDigitization: '',
      reportingDimensions: '',
      integrationAiMl: '',
      integrationComment: '',
      integrationSustainability: '',
      reportingProcess: '',
      reportingClosing: '',
      reportingFlash: '',
      reportingMonthlyUncommented: '',
      reportingMonthlyCommented: '',
    },
    ctrlOperativePlanning: {
      fte: '',
      cost: '',
      durationWeeks: '',
      planningPreparation: '',
      targetSetting: '',
      decentralizedPlanning: '',
      consolidationCompletionOfPlanning: '',
      planShiftApproval: '',
      votingRounds: '',
      planningAlignment: 'TOP_DOWN',
      planningHorizonMonths: '',
      typeOperativePlanning: 'MEDIUM_TERM_PLANNING',
    },
    ctrlParticipation: {
      fte: '',
      cost: '',
    },
    ctrlPic: {
      fte: '',
      cost: '',
      investmentLimit: '',
      durationRequest: '',
      statusProjectsSuccessful: '',
      statusProjectsAborted: '',
      statusProjectsStopped: '',
      deviationProjectCosts: 'BAD',
      deviationProjectTime: 'BAD',
      deviationProjectPerformance: 'BAD',
    },
    ctrlProduction: {
      fte: '',
      cost: '',
      kpiKeyData: {
        deliveryPunctuality: null,
        idleTimeMachine: null,
        plantUtilizationRate: null,
        utilizationRate: null,
        manufacturingQuality: null,
        postProcessingRate: null,
        scrapRate: null,
        damageFrequency: null,
        stockBearingManufacturing: null,
        setupTimes: null,
        employment: null,
      },
    },
    ctrlResearchDevelopment: {
      fte: '',
      cost: '',
      percentageShare: '',
      resourcesEffort: '',
      projectBasicResearch: '',
      projectNewProductDev: '',
      projectFurtherDev: '',
    },
    ctrlRiskManagement: {
      fte: '',
      cost: '',
      typeRiskManagement: '',
      responsibilityRiskManagement: '',
      frequenceRiskManagement: '',
      controllingRelevance: '',
      measures: '',
      kpiGoalRiskManagement: {
        fulfilledRequirement: null,
        improvedControllingRisks: null,
        companyControlling: null,
      },
      kpiIntegrationRiskManagement: {
        strategicPlanning: null,
        operativePlanning: null,
        budgeting: null,
        strategicDecisionSupport: null,
        operativeDecisionSupport: null,
        noInterfaces: null,
      },
      kpiAnalysisRiskBearingCapacity: {
        noConcept: null,
        equityCoverage: null,
        liquidityReserve: null,
        other: null,
      },
      kpiSoftwareSupport: {
        riskRecordingRating: null,
        reporting: null,
        riskDashboards: null,
        simulationModeling: null,
        noSoftwareSupport: null,
      },
      kpiScenarioAnalysis: {
        currentRiskDev: null,
        individualSingleRisks: null,
        topRisks: null,
        completeRiskPortfolio: null,
        strategicDecisionOptions: null,
      },
    },
    ctrlSales: {
      fte: '',
      cost: '',
      percentageShare: '',
    },
    ctrlStrategicPlanning: {
      fte: '',
      cost: '',
      planningHorizonMonths: '',
      durationWeeks: '',
      votingRounds: '',
      numberParticipants: '',
    },
    ctrlIt: {
      fte: '',
      cost: '',
      structureIt: 'COST-CENTER',
    },
    ctrlCorporate: {
      fte: '',
      cost: '',
      leadershipClaim: 'FINANCIAL_LEADERSHIP',
    },
    ctrlBusinessConsultingManagement: {
      fte: '',
      cost: '',
    },
    ctrlFurtherDevelopmentControlling: {
      fte: '',
      cost: '',
    },
    finConsolidation: {
      fte: '',
      cost: '',
      journalEntries: '',
      daysMonthlyClosing: '',
      daysMonthlyClosingReleaseExternReporting: '',
      daysQuarterlyReport: '',
      daysQuarterlyReportReleaseExternReporting: '',
      daysAnnualReport: '',
      daysAnnualReportReleaseExternReporting: '',
      numberConsolidatedLegalEntities: '',
      fteConsolidation: '',
      costConsolidation: '',
      completionDataReporting: '',
      monthlyCompletion: '',
      quarterlyCompletion: '',
      annualCompletion: '',
      extraLoadManualActivity: 'HIGH',
      qualityDataReporting: 'BAD',
      qualityVotingProcess: 'BAD',
      integrationLegalManagement: 'NONE',
      generationConsolidatedNumbers: 'ONE',
      challengeGroupAccounting: 'IFRS_STANDARDS',
    },
    finAssetManagement: {
      fte: '',
      cost: '',
      assetsMasterData: '',
      assetsBookedProcessed: '',
    },
    finChargesTravel: {
      fte: '',
      cost: '',
      payroll: '',
      employees: '',
      incorrectPayrolls: '',
      travelExpenseReport: '',
      processingDays: '',
    },
    finCreditorsProcess: {
      fte: '',
      cost: '',
      durationProcessingCreditors: '',
      incorrectOrders: '',
      suppliersEdi: '',
      numberOrders: '',
      numberOrderPositions: '',
      automatedOrderEntry: '',
      electrSentOrders: '',
      numberBills: '',
      differenceBillsOrders: '',
      billWithoutOrder: '',
      incorrectPaymentsCreditors: '',
      timeSpanBillincomingPayment: '',
    },
    finDebitorsProcess: {
      fte: '',
      cost: '',
      durationDays: '',
      percentageShare: '',
      percentageShareCustomer: '',
      numberBills: '',
      percentageShareIncorrectBills: '',
      numberBillPosition: '',
      timeSpanUntilBill: '',
      percentageShareElecBills: '',
      percentageShareBillsReminder: '',
      incomingPayments: '',
      timeSpanUntilClosingBusinessCase: '',
      incorrectPaymentAssignment: '',
      receivablesTurnoverTime: '',
      outsourcedReceivables: '',
    },
    finTax: {
      fte: '',
      cost: '',
      reportingUnit: '',
      taxDeclaration: '',
      taxDeclarationBalance: '',
    },
    finTreasury: {
      fte: '',
      cost: '',
      avgExternPaymentTransactions: '',
      avgExternManualPayments: '',
      avgInternPayments: '',
      employeesPaymentTransactions: '',
      financeTransactionsConcluded: '',
      inventoryTreasuryFinanceTransactions: '',
      automatedProcessingTreasuryTransactions: '',
      employeesTreasuryFinanceTransactionsCompleted: '',
      employeesProcessingTreasuryFinanceTransactions: '',
      efficiency: '',
      liquidityPlanningHorizonMonths: '',
      liquidityPlanningFrequenceMonths: '',
      liquidityPlanningDurationDays: '',
      numberEmployeesLiquidityPlanning: '',
      accuracyLiquidityPlanning: '',
    },
    finFurtherDevelopmentFinance: {
      fte: '',
      cost: '',
    },
  },
  status: 'idle',
  error: null,
};

/**
 * Fetches the company info of a benchmarking
 */
export const fetchBenchmarkingCompanyInfo = createAsyncThunk(
  'benchmarkingCompanyInfo/fetchBenchmarkingCompanyInfo',
  async ({ companyId, benchmarkingId }) => {
    const response = await api.get(`/api/benchmarking/${benchmarkingId}/company/${companyId}`, {
      withCredentials: true,
    });
    return response.data;
  },
);

/**
 * Create new (empty) benchmarking
 */
export const createBenchmarkingCompanyInfo = createAsyncThunk(
  'benchmarkingCompanyInfo/createBenchmarkingCompanyInfo',
  async () => {
    const response = await api.post(
      `/api/benchmarking`,
      {},
      {
        withCredentials: true,
      },
    );
    return response.data;
  },
);

/**
 * Edit the benchmarking
 */
export const editBenchmarkingCompanyInfo = createAsyncThunk(
  'benchmarkingCompanyInfo/editBenchmarkingCompanyInfo',
  async (editedBenchmarkingCompanyInfo) => {
    const response = await api.put(`/api/benchmarking`, editedBenchmarkingCompanyInfo, {
      withCredentials: true,
    });
    return response.data;
  },
);

/**
 * Submits the benachmarking
 */
export const submitBenchmarkingCompanyInfo = createAsyncThunk(
  'benchmarkingCompanyInfo/submitBenchmarking',
  async ({ companyId, benchmarkingId }) => {
    const response = await api.put(
      `/api/benchmarking/${benchmarkingId}/company/${companyId}/submit`,
      {},
      {
        withCredentials: true,
      },
    );
    return response.data;
  },
);

/**
 * The slice of the benchmarking state
 */
export const benchmarkingCompanyInfoSlice = createSlice({
  name: 'benchmarkingCompanyInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBenchmarkingCompanyInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBenchmarkingCompanyInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBenchmarkingCompanyInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editBenchmarkingCompanyInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(editBenchmarkingCompanyInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(submitBenchmarkingCompanyInfo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(submitBenchmarkingCompanyInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(submitBenchmarkingCompanyInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select the company info of a benchmarking
 */
export const selectBenchmarkingCompanyInfo = (state) => state.benchmarkingCompanyInfo.data;

/**
 * Select the status of the company info state
 */
export const selectCompanyInfoStatus = (state) => state.benchmarkingCompanyInfo.status;

/**
 * Select the error of the company info state
 */
export const selectCompanyInfoError = (state) => state.benchmarkingCompanyInfo.error;

export default benchmarkingCompanyInfoSlice.reducer;
