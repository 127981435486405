/*
 * Renders icons depending if a user is verified or not
 */
import React, { useState, useEffect } from 'react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

const IsUserVerified = ({ user }) => {
  const [isEmailVerified, setIsEmailVerified] = useState(user.isEmailVerified);

  const clearStates = () => {
    setIsEmailVerified(isEmailVerified);
  };

  useEffect(() => {
    clearStates();
  }, [user]);

  return isEmailVerified === true ? (
    <CheckIcon color="green.400" fontSize="1.25rem" />
  ) : (
    <CloseIcon color="horvath.red" fontSize="1.25rem" />
  );
};

export default IsUserVerified;
