/*
 * Renders the BenchmarkingOverview view
 */
import {
  Box,
  Center,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BenchmarkingStat from '../components/BenchmarkingStat';
import Clickable from '../components/Clickable';
import ContentWrapper from '../components/ContentWrapper';
import Dialog from '../components/Dialog';
import ErrorStat from '../components/ErrorStat';
import PageHeader from '../components/PageHeader';
import StatGroup from '../components/StatGroup';
import Card from '../components/cards/Card';
import CardHeader from '../components/cards/CardHeader';
import Button from '../components/inputs/Button';
import FormControl from '../components/inputs/FormControl';
import FormProperty from '../components/inputs/FormProperty';
import {
  editBenchmarkingCompanyInfo,
  fetchBenchmarkingCompanyInfo,
  selectBenchmarkingCompanyInfo,
  selectCompanyInfoError,
  selectCompanyInfoStatus,
  submitBenchmarkingCompanyInfo,
} from '../slices/benchmarkingCompanyInfoSlice';
import {
  fetchCompany,
  selectCompanyError,
  selectCompanyId,
  selectCompanyName,
  selectCompanyStatus,
} from '../slices/companySlice';

/**
 * Displays overall information of a benchmarking
 * @returns Cards and stats for displaying information about the benchmarking
 */
const BenchmarkingOverview = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const companyId = useSelector(selectCompanyId);
  const benchmarkingCompanyInfo = useSelector(selectBenchmarkingCompanyInfo);
  const companyName = useSelector(selectCompanyName);

  const toast = useToast();

  const benchmarkingCompanyInfoStatus = useSelector(selectCompanyInfoStatus);
  const benchmarkingCompanyInfoError = useSelector(selectCompanyInfoError);

  const companyStatus = useSelector(selectCompanyStatus);
  const companyError = useSelector(selectCompanyError);

  const [benchmarkingRevenue, setBenchmarkingRevenue] = React.useState(
    benchmarkingCompanyInfo.annualRevenue,
  );
  const [benchmarkingEmployees, setBenchmarkingEmployees] = React.useState(
    benchmarkingCompanyInfo.annualFte,
  );

  const [validateBenchmarkingRevenue, setValidateBenchmarkingRevenue] = React.useState(false);
  const [validateBenchmarkingEmployees, setValidateBenchmarkingEmployees] = React.useState(false);

  const [errorMsgBenchmarkingRevenue, setErrorMsgBenchmarkingRevenue] = React.useState('');
  const [errorMsgBenchmarkingEmployees, setErrorMsgBenchmarkingEmployees] = React.useState('');

  // States for opening the info dialog
  const { isOpen: isInfoOpen, onOpen: onInfoOpen, onClose: onInfoClose } = useDisclosure();

  /**
   * Handles the change and validation of the benchmarking employees
   */
  const handleBenchmarkingEmployeesBValueChange = (inputValue) => {
    setBenchmarkingEmployees(inputValue);
    if (inputValue === '' || inputValue === null) {
      setErrorMsgBenchmarkingEmployees(t('benchmarkingOverview.inputEmployees'));
      setValidateBenchmarkingEmployees(false);
    } else {
      setErrorMsgBenchmarkingEmployees('');
      setValidateBenchmarkingEmployees(true);
    }
  };

  /**
   * Handles the change and validation of the benchmarking revenue
   */
  const handleBenchmarkingRevenueBValueChange = (inputValue) => {
    setBenchmarkingRevenue(inputValue);
    if (inputValue === '' || inputValue === null) {
      setErrorMsgBenchmarkingRevenue(t('benchmarkingOverview.inputRevenue'));
      setValidateBenchmarkingRevenue(false);
    } else {
      setErrorMsgBenchmarkingRevenue('');
      setValidateBenchmarkingRevenue(true);
    }
  };

  /**
   * Fetches the data for the benchmarkingOverview
   */
  const fetchData = () => {
    dispatch(fetchCompany())
      .then(unwrapResult)
      .then((company) => {
        dispatch(fetchBenchmarkingCompanyInfo({ companyId: company.id, benchmarkingId: id }))
          .then(unwrapResult)
          .then((res) => {
            setBenchmarkingEmployees(res.annualFte);
            setBenchmarkingRevenue(res.annualRevenue);
          })
          .catch(() => {
            toast({
              title: t('errors.benchmarkingOverview.fetchBenchmarkingDataFailed.title'),
              description: t('errors.benchmarkingOverview.fetchBenchmarkingDataFailed.description'),
              status: 'error',
              duration: 4000,
              position: 'top-right',
              isClosable: true,
            });
          });
      })
      .catch(() => {
        toast({
          title: t('errors.benchmarkingOverview.fetchCompanyDataFailed.title'),
          description: t('errors.benchmarkingOverview.fetchCompanyDataFailed.description'),
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  /**
   * Saves the benchmarking data regarding the whole company
   */
  const saveCompanyData = () => {
    if (
      (benchmarkingEmployees !== '' &&
        benchmarkingEmployees !== null &&
        benchmarkingRevenue !== '' &&
        benchmarkingRevenue !== null) ||
      (validateBenchmarkingEmployees && validateBenchmarkingRevenue)
    ) {
      dispatch(
        editBenchmarkingCompanyInfo({
          ...benchmarkingCompanyInfo,
          annualFte: parseFloat(benchmarkingEmployees, 10),
          annualRevenue: parseInt(benchmarkingRevenue, 10),
        }),
      )
        .then(unwrapResult)
        .then(() => {
          toast({
            title: t('benchmarkingOverview.successSaveChanges.title'),
            description: t('benchmarkingOverview.successSaveChanges.description'),
            status: 'success',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: t('errors.benchmarkingOverview.saveBenchmarkingFailed.title'),
            description: t('errors.benchmarkingOverview.saveBenchmarkingFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
    } else {
      toast({
        title: t('errors.benchmarkingOverview.errorSaveInputsEmpty.title'),
        description: t('errors.benchmarkingOverview.errorSaveInputsEmpty.description'),
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  /**
   * Submits the benchmarking
   */
  const submitBenchmarking = () => {
    if (benchmarkingEmployees !== null && benchmarkingRevenue !== null) {
      dispatch(submitBenchmarkingCompanyInfo({ companyId, benchmarkingId: id }))
        .then(unwrapResult)
        .then(() => {
          toast({
            title: t('benchmarkingOverview.successSubmitBenchmarking.title'),
            description: t('benchmarkingOverview.successSubmitBenchmarking.description'),
            status: 'success',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: t('errors.benchmarkingOverview.submitBenchmarkingFailed.title'),
            description: t('errors.benchmarkingOverview.submitBenchmarkingFailed.description'),
            status: 'error',
            duration: 4000,
            position: 'top-right',
            isClosable: true,
          });
        });
      onInfoClose();
    } else {
      toast({
        title: t('errors.benchmarkingOverview.errorSubmitInputsEmpty.title'),
        description: t('errors.benchmarkingOverview.errorSubmitInputsEmpty.description'),
        status: 'error',
        duration: 7000,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  /**
   * Renders the company section if fetching was succesfull
   * @returns The company section, a spinner or an error depending on the benchmarking fetch status
   */
  const renderBenchmarkingInfo = () => {
    if (benchmarkingCompanyInfoStatus === 'loading' || companyStatus === 'loading') {
      return (
        <Center mt={8}>
          <Spinner size="xl" />
        </Center>
      );
    }
    if (benchmarkingCompanyInfoStatus === 'succeeded' && companyStatus === 'succeeded') {
      return (
        <>
          <PageHeader
            title={`${t('benchmarkingOverview.pageHeader')} ${benchmarkingCompanyInfo.year}`}
          />
          <p>
            {t('benchmarkingOverview.overviewBenchmarkings1')} {benchmarkingCompanyInfo.year}.{' '}
            {t('benchmarkingOverview.overviewBenchmarkings2')}
          </p>
          <ContentWrapper>
            <Card>
              <CardHeader title={companyName} />
              <FormControl>
                <Stack direction={{ base: 'column', xl: 'row' }} justifyContent="space-evenly">
                  <VStack>
                    <FormProperty
                      label={t('global.totalRevenue')}
                      formType="currency-input"
                      disabled={benchmarkingCompanyInfo.submitDate !== null}
                      value={benchmarkingRevenue}
                      onValueChange={(val) =>
                        handleBenchmarkingRevenueBValueChange(
                          val,
                          benchmarkingRevenue,
                          setBenchmarkingRevenue,
                        )
                      }
                      testName="Gesamtumsatz in Euro"
                    />
                    <Box pb={4}>
                      <Text color="red">{errorMsgBenchmarkingRevenue}</Text>
                    </Box>
                  </VStack>
                  <VStack>
                    <FormProperty
                      label={t('global.numberEmployees')}
                      placeholder={t('global.numberEmployees')}
                      formType="number-input"
                      disabled={benchmarkingCompanyInfo.submitDate !== null}
                      value={benchmarkingEmployees}
                      onValueChange={(val) =>
                        handleBenchmarkingEmployeesBValueChange(
                          val,
                          benchmarkingEmployees,
                          setBenchmarkingEmployees,
                        )
                      }
                      testName="Anzahl der Mitarbeiter"
                    />
                    <Box pb={4}>
                      <Text color="red">{errorMsgBenchmarkingEmployees}</Text>
                    </Box>
                  </VStack>
                </Stack>
              </FormControl>
              <Center m={2}>
                <Button
                  color="primary"
                  text={t('benchmarkingOverview.saveButton')}
                  disabled={benchmarkingCompanyInfo.submitDate !== null}
                  action={() => {
                    saveCompanyData();
                  }}
                />
              </Center>
            </Card>
            <br />
            <StatGroup title={t('benchmarkingOverview.processtitle')}>
              <Clickable>
                <BenchmarkingStat
                  label={t('benchmarkingOverview.labelControlling')}
                  totalFte={benchmarkingCompanyInfo.sumFteControlling}
                  totalCost={benchmarkingCompanyInfo.sumCostControlling}
                  destination={`/benchmarking/${id}/controlling`}
                  data-testid="Benchmarking: Controlling"
                />
              </Clickable>
              <Clickable>
                <BenchmarkingStat
                  label={t('benchmarkingOverview.labelFinance')}
                  totalFte={benchmarkingCompanyInfo.sumFteFinance}
                  totalCost={benchmarkingCompanyInfo.sumCostFinance}
                  destination={`/benchmarking/${id}/finance`}
                  data-testid="Benchmarking: Finance"
                />
              </Clickable>
            </StatGroup>
          </ContentWrapper>
        </>
      );
    }
    if (benchmarkingCompanyInfoStatus === 'failed') {
      return (
        <ErrorStat
          // TODO: error msg benchmarkingCompanyInfoError
          errorMessage={benchmarkingCompanyInfoError}
          onRefresh={fetchData}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }
    if (companyStatus === 'failed') {
      return (
        <ErrorStat
          // error msg companyError
          errorMessage={companyError}
          onRefresh={fetchData}
          maxW="7xl"
          mx="auto"
          width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        />
      );
    }

    return null;
  };

  return (
    <div>
      {renderBenchmarkingInfo()}
      <ContentWrapper>
        <br />
        <Center m={2}>
          <Button
            color="primary"
            text={t('benchmarkingOverview.submitBenchmarking')}
            action={() => {
              onInfoOpen();
            }}
            disabled={
              benchmarkingCompanyInfo.submitDate !== null ||
              (benchmarkingRevenue === '' && benchmarkingEmployees === '')
            }
          />
        </Center>
        <br />
        <StatGroup title={t('benchmarkingOverview.evaluationBenchmarking')}>
          {benchmarkingCompanyInfo.submitDate !== null ? (
            <>
              <Clickable>
                <BenchmarkingStat
                  label={t('benchmarkingOverview.labelControlling')}
                  destination={`/benchmarking/evaluation/${id}/controlling`}
                  data-testid="Analysis: Controlling"
                  withoutStatNumbers
                />
              </Clickable>
              <Clickable>
                <BenchmarkingStat
                  label={t('benchmarkingOverview.labelFinance')}
                  destination={`/benchmarking/evaluation/${id}/finance`}
                  data-testid="Analysis: Finance"
                  withoutStatNumbers
                />
              </Clickable>
            </>
          ) : (
            'Die Auswertung wird erst freigeschaltet, wenn das Benchmarking eingereicht wurde.'
          )}
        </StatGroup>
        <Dialog
          title={t('benchmarkingOverview.warningSubmitBenchmarking')}
          secondaryActionName={t('benchmarkingOverview.submitButton')}
          secondaryAction={submitBenchmarking}
          isOpen={isInfoOpen}
          onClose={onInfoClose}
        >
          <Box ml={7}>
            <p>
              {t('benchmarkingOverview.warningAfterSubmit1')}{' '}
              <strong>{t('benchmarkingOverview.warningAfterSubmit2')}</strong>{' '}
              {t('benchmarkingOverview.warningAfterSubmit3')}
            </p>
          </Box>
        </Dialog>
      </ContentWrapper>
    </div>
  );
};

export default BenchmarkingOverview;
