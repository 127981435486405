/*
 * Renders the slider property component
 */
import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  useColorModeValue,
  FormControl,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Flex,
  Center,
  Tooltip,
} from '@chakra-ui/react';
import * as React from 'react';

/**
 * Displays the slider property component
 * @returns A flex box with label and discrete slider
 */
const SliderPropery = (props) => {
  const {
    label,
    tooltipText,
    marks,
    marksPositions,
    marksWidth,
    value,
    formType,
    colorEvenItems,
    disabled,
    testName,
    onValueChange,
    required,
    children,
    ...flexProps
  } = props;

  /**
   * Computes the step of the slider
   * @returns 100 / length of the marks
   */
  const getSliderStep = () => {
    return 100 / (marks.length - 1);
  };

  /**
   * Computes the value of the specific mark
   * @param {*} index The index of the mark
   * @returns Index * slider step
   */
  const getSliderMarkValue = (index) => {
    return getSliderStep() * index;
  };

  /**
   * Retrieves the corresponding string value from the slider number value
   * @param {number} sliderValue The value from the slider
   * @returns The corresponding string value
   */
  const getValueFromSlider = (sliderValue) => {
    const index = sliderValue / getSliderStep();

    return marks[index];
  };

  return (
    <Box
      px="6"
      py="4"
      _even={
        colorEvenItems
          ? {
              bg: useColorModeValue('horvath.grey-light'),
            }
          : null
      }
      {...flexProps}
    >
      <Box
        as="dt"
        minWidth="180px"
        color={disabled ? 'grey' : 'black'}
        style={{
          fontFamily: 'Quay Sans',
          fontWeight: '500',
          fontSize: '16px',
          color: '#353434',
          lineHeight: '1.25em',
          display: 'block',
          maxWidth: '440px',
        }}
      >
        <Flex>
          <Center>
            {label}
            <Flex sx={{ pointerEvents: 'all' }}>
              {tooltipText ? (
                <Tooltip
                  bg="horvath.blue-light"
                  p={3}
                  hasArrow="true"
                  placement="right"
                  label={tooltipText}
                >
                  <QuestionIcon color="horvath.blue-light" ml={1} />
                </Tooltip>
              ) : null}
            </Flex>
          </Center>
        </Flex>
      </Box>
      <Box ml={1} mb={6} pl={20} pr={20} as="dd" flex="1" fontWeight="semibold">
        <FormControl>
          <Slider
            value={getSliderMarkValue(marks.indexOf(value))}
            onChange={onValueChange ? (val) => onValueChange(getValueFromSlider(val)) : null}
            focusThumbOnChange={false}
            disabled={disabled}
            min={0}
            max={100}
            step={getSliderStep()}
            data-testid={`Select: ${testName}`}
          >
            {marks.map((mark, index) => (
              <SliderMark
                key={mark}
                value={getSliderMarkValue(index)}
                mt="3"
                ml={marksPositions[index]}
                fontSize="sm"
                style={{
                  width: marksWidth,
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
              >
                {mark}
              </SliderMark>
            ))}
            <SliderTrack boxSize={2} bg="horvath.blue-light">
              <Box position="relative" right={10} />
              <SliderFilledTrack bg="horvath.blue-dark" />
            </SliderTrack>
            <SliderThumb borderColor="horvath.blue-dark" boxSize={7} />
          </Slider>
        </FormControl>
      </Box>
    </Box>
  );
};

export default SliderPropery;
