/*
 * Renders the benchmarking stat component
 */
import {
  Stat as ChakraStat,
  StatLabel as ChakraStatLabel,
  StatNumber as ChakraStatNumber,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import valueManipulation from '../utils/valueManipulation';

/**
 * Displays a stat component for a benchmarking
 * @returns A small stat with label and value
 */
const BenchmarkingStat = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { label, totalFte, totalCost, destination, withoutStatNumbers, ...rest } = props;

  return (
    <ChakraStat
      sx={{
        '&:hover': {
          cursor: 'pointer',
          background: 'horvath.blue-light',
        },
      }}
      px={{
        base: 4,
        sm: 6,
      }}
      py="5"
      bg={useColorModeValue('horvath.blue-dark', 'gray.700')}
      shadow="base"
      onClick={() => history.push(destination)}
      {...rest}
    >
      <ChakraStatLabel
        fontSize="2xl"
        fontWeight="medium"
        isTruncated
        color={useColorModeValue('white', 'gray.400')}
      >
        {label}
      </ChakraStatLabel>
      {withoutStatNumbers ? null : (
        <Table size="sm" variant="unstyled" color="white">
          <Tbody>
            {totalCost ? (
              <Tr>
                <Td w={1}>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {t('global.costsStat')}
                  </ChakraStatNumber>
                </Td>
                <Td>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {valueManipulation.addWhitespaces(totalCost)}
                  </ChakraStatNumber>
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td w={1}>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {t('global.costsStat')}
                  </ChakraStatNumber>
                </Td>
                <Td>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    -
                  </ChakraStatNumber>
                </Td>
              </Tr>
            )}
            {totalFte ? (
              <Tr>
                <Td w={1}>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {t('global.fte')}
                  </ChakraStatNumber>
                </Td>
                <Td>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {totalFte}
                  </ChakraStatNumber>
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td w={1}>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    {t('global.fte')}
                  </ChakraStatNumber>
                </Td>
                <Td>
                  <ChakraStatNumber
                    fontSize="xl"
                    fontWeight="medium"
                    color={useColorModeValue('white', 'white')}
                  >
                    -
                  </ChakraStatNumber>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
    </ChakraStat>
  );
};

export default BenchmarkingStat;
