import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    horvath: {
      'blue-dark': '#05415A',
      'blue-light': '#008CC8',
      'grey-light': '#EEEEEE',
      'grey-mid': '#969696',
      'grey-dark': '#4A4A4A',
      'digital-turquoise': '#14E6D7',
      black: '#353434',
      red: '#FA5019',
    },
  },
  fonts: {
    heading: 'Corporate E W02 Md',
    body: 'ITC Quay Sans W02 Book',
  },
});

export default theme;
