import * as React from 'react';
import { useState } from 'react';
import { Box, Input } from '@chakra-ui/react';
import { useAsyncDebounce } from 'react-table';

const borderStyleInput = {
  border: '1px',
  borderColor: 'blue.500',
  borderRadius: '7',
  borderWidth: '2px',
};

// Define a default UI for filtering
const GlobalFilteringTables = ({ globalFilter, setGlobalFilter, placeholderInputSearch }) => {
  const [value, setValue] = useState(globalFilter);
  const change = useAsyncDebounce((para) => {
    setGlobalFilter(para || undefined);
  }, 200);

  return (
    <Box sx={borderStyleInput}>
      <Input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          change(e.target.value);
        }}
        placeholder={placeholderInputSearch}
        style={{
          fontSize: 'full',
          height: '36px',
          border: '0',
        }}
      />
    </Box>
  );
};

export default GlobalFilteringTables;
