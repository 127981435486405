import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PageShell from './components/PageShell';
import AdminCompanyOverview from './views/AdminCompanyOverview';
import User from './views/User';
import ControllingEvaluation from './views/ControllingEvaluation';
import ControllingBenchmarking from './views/ControllingBenchmarking';
import FinanceBenchmarking from './views/FinanceBenchmarking';
import FinanceEvaluation from './views/FinanceEvaluation';
import AdminUserOverview from './views/AdminUserOverview';
import Benchmarkings from './views/Benchmarkings';
import NotFound from './views/NotFound';
import BenchmarkingOverview from './views/BenchmarkingOverview';
import Login from './views/Login';
import LandingPage from './views/LandingPage';
import VerifyUser from './views/VerifyUser';
import { authSelector, authenticate, deauthenticate } from './slices/authSlice';
import api from './utils/api';
import Register from './views/Register';
import { isUserAdmin, fetchUser } from './slices/userSlice';
import InputEmailForPasswordReset from './views/InputEmailForPasswordReset';
import UserResetPassword from './views/UserResetPassword';

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const isAdmin = useSelector(isUserAdmin);
  const toast = useToast();
  const { t } = useTranslation();

  const redirectAndError = (redirectPath, errorTitle, errorDescription) => {
    toast({
      title: errorTitle,
      description: errorDescription,
      status: 'error',
      duration: 4000,
      position: 'top-right',
      isClosable: true,
    });
    return <Redirect to={{ pathname: redirectPath }} />;
  };

  /**
   * Checks if the user is (still) authenticated
   * and retrieves the data of the logged in user
   */
  const checkAuth = () => {
    api
      .get('api/user/me', { withCredentials: true })
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchUser()).then(() => {
            dispatch(authenticate());
          });
        } else {
          dispatch(deauthenticate());
        }
      })
      .catch(() => {
        dispatch(deauthenticate());
      });
  };

  /**
   * Renders the specified component if the user is authenticated otherwise
   * the user gets redirected to the login page
   */
  const PrivateRoute = React.memo(({ component: Component, ...rest }) => {
    React.useEffect(() => checkAuth());

    return (
      <Route
        {...rest}
        render={({ props, location }) =>
          auth ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
      />
    );
  });

  /**
   * Renders the login component if the user is not authenticated otherwise
   * the user gets redirected to the benchmarking page
   */
  const LoginRoute = React.memo(({ component: Component, ...rest }) => {
    React.useEffect(() => checkAuth());
    const { state } = useLocation();

    if (auth === true) {
      return <Redirect to={state?.from || '/'} />;
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  });

  /**
   * Renders admin views if the user has the role 'ADMIN'
   */
  const AdminRoute = React.memo(({ component: Component, ...rest }) => {
    React.useEffect(() => checkAuth());

    return (
      <Route
        {...rest}
        render={({ props, location }) =>
          // eslint-disable-next-line no-nested-ternary
          auth ? (
            isAdmin ? (
              <Component {...props} />
            ) : (
              redirectAndError('/', t('errors.app.403.title'), t('errors.app.403.description'))
            )
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )
        }
      />
    );
  });

  return (
    <div>
      <PageShell>
        <Switch>
          <LoginRoute exact path="/login" component={Login} />
          <LoginRoute exact path="/registration" component={Register} />
          <AdminRoute exact path="/admin/user" component={AdminUserOverview} />
          <AdminRoute exact path="/admin/company" component={AdminCompanyOverview} />
          <PrivateRoute exact path="/" component={LandingPage} />
          <Route exact path="/resetpassword" component={InputEmailForPasswordReset} />
          <Route exact path="/resetpassword/:token" component={UserResetPassword} />
          <Route exact path="/verify/:token" component={VerifyUser} />
          <PrivateRoute exact path="/benchmarking" component={Benchmarkings} />
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute exact path="/benchmarking/:id" component={BenchmarkingOverview} />
          <PrivateRoute
            exact
            path="/benchmarking/:id/controlling"
            component={ControllingBenchmarking}
          />
          <PrivateRoute
            exact
            path="/benchmarking/evaluation/:id/controlling"
            component={ControllingEvaluation}
          />
          <PrivateRoute
            exact
            path="/benchmarking/evaluation/:id/finance"
            component={FinanceEvaluation}
          />
          <PrivateRoute exact path="/benchmarking/:id/finance" component={FinanceBenchmarking} />
          <PrivateRoute exact path="*" component={NotFound} />
        </Switch>
      </PageShell>
    </div>
  );
};

export default App;
