/*
 * Renders the benchmarking card component which is used in the overview of all benchmarkings
 * the company has conducted in the past
 */
import * as React from 'react';
import { Flex, Divider, Text, Center } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import Card from './Card';
import Clickable from '../Clickable';

/**
 * Displays a card, that represents a benchmarking
 * @returns A clickable card with specified child components
 */
const BenchmarkingCard = (props) => {
  const history = useHistory();
  const { name, icon, destination, onClick, children } = props;

  const renderPrefix = () => {
    if (name) {
      return (
        <Text width="10vW" as="b">
          {name}
        </Text>
      );
    }
    if (icon) {
      return <Center width="10vw">{icon}</Center>;
    }
    return null;
  };

  return (
    <Clickable>
      <Card
        maxW="7xl"
        mx="auto"
        p={3}
        width={{ base: '400px', md: '600px', lg: '800px', xl: '1120px' }}
        bg="white"
        onClick={onClick ? () => onClick() : () => history.push(destination)}
        data-testid={destination}
      >
        <Flex>
          <Center>
            {renderPrefix()}
            <Divider borderColor="gray.300" ml={1} mr={1} orientation="vertical" />
            {children}
          </Center>
        </Flex>
      </Card>
    </Clickable>
  );
};

export default BenchmarkingCard;
