/*
 * Slice which provides reducer and states of the benchmarkings state
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

/**
 * Fetches the state of the benchmarkings
 */
export const fetchBenchmarkings = createAsyncThunk(
  'benchmarkings/fetchBenchmarkings',
  async ({ companyId }) => {
    const response = await api.get(`/api/benchmarking/company/${companyId}`, {
      withCredentials: true,
    });
    return response.data;
  },
);

/**
 * The slice of the benchmarkings state
 */
export const benchmarkingsSlice = createSlice({
  name: 'benchmarkings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBenchmarkings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBenchmarkings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchBenchmarkings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

/**
 * Select the benchmarkings of a benchmarking
 */
export const selectBenchmarkings = (state) => state.benchmarkings.data;

/**
 * Selects the year of the newest benchmarking
 */
export const selectNewestBenchmarking = (state) => {
  let year = 0;
  state.benchmarkings.data.map((benchmarking) => {
    if (benchmarking.year > year) {
      year = benchmarking.year;
    }
    return year;
  });
  return year;
};

/**
 * Select the status of the benchmarkings state
 */
export const selectBenchmarkingsStatus = (state) => state.benchmarkings.status;

/**
 * Select the error of the benchmarkings state
 */
export const selectBenchmarkingsError = (state) => state.benchmarkings.error;

export default benchmarkingsSlice.reducer;
