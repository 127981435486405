import { createAsyncThunk } from '@reduxjs/toolkit';

/**
 * Uses an async thunk to handle async request.
 * This is necessary because Redux hides important information when an error occurs
 * by throwing a default error (which does not contain information like e.g. status).
 * @see https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit
 *
 * @param {string} type - the type of the async thunk for reducer
 * @param {Function} fn - the function to be called
 * @returns {AsyncThunk<unknown, any, {}>} - the async thunk
 */
const useAsyncThunk = (type, fn) => {
  // TODO: Do this for all async thunks such that the error handling is consistent
  return createAsyncThunk(type, async (args, { rejectWithValue }) => {
    try {
      const response = await fn(args);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      throw rejectWithValue(err.response);
    }
  });
};

export default useAsyncThunk;
