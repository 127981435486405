/*
 * Renders the page header component
 */
import { Divider, Heading } from '@chakra-ui/react';
import * as React from 'react';

/**
 * Displays a heading and a divider
 * @returns The heading component
 */
const PageHeader = (props) => {
  const { title } = props;
  return (
    <>
      <Heading mt={8}>{title}</Heading>
      <Divider mb={2} />
    </>
  );
};

export default PageHeader;
