/**
 * Slice which provides reducer for the controlling input warnings
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ctrlProcess: {
    warningShown: false,
  },
  ctrlBcm: {
    warningShown: false,
  },
  ctrlCpra: {
    warningShown: false,
  },
  ctrlForecast: {
    warningShown: false,
  },
  ctrlFunctional: {
    warningShown: false,
  },
  ctrlImprovement: {
    warningShown: false,
  },
  ctrlManagementReporting: {
    warningShown: false,
  },
  ctrlParticipation: {
    warningShown: false,
  },
  ctrlPic: {
    warningShown: false,
  },
  ctrlProduction: {
    warningShown: false,
  },
  ctrlResearchDevelopment: {
    warningShown: false,
  },
  ctrlRiskManagement: {
    warningShown: false,
  },
  ctrlSales: {
    warningShown: false,
  },
  ctrlStrategicPlanning: {
    warningShown: false,
  },
};

/**
 * The slice of the warning controller state
 */
export const warningBenchmarkingControllingSlice = createSlice({
  name: 'warningBenchmarkingControlling',
  initialState,
  reducers: {
    deactivateWarning: (state, action) => {
      switch (action.payload) {
        case 'ctrlProcess': {
          state.ctrlProcess.warningShown = true;
          break;
        }
        case 'ctrlBcm': {
          state.ctrlBcm.warningShown = true;
          break;
        }
        case 'ctrlCpra': {
          state.ctrlCpra.warningShown = true;
          break;
        }
        case 'ctrlForecast': {
          state.ctrlForecast.warningShown = true;
          break;
        }
        case 'ctrlFunctional': {
          state.ctrlFunctional.warningShown = true;
          break;
        }
        case 'ctrlImprovement': {
          state.ctrlImprovement.warningShown = true;
          break;
        }
        case 'ctrlManagementReporting': {
          state.ctrlManagementReporting.warningShown = true;
          break;
        }
        case 'ctrlParticipation': {
          state.ctrlParticipation.warningShown = true;
          break;
        }
        case 'ctrlPic': {
          state.ctrlPic.warningShown = true;
          break;
        }
        case 'ctrlProduction': {
          state.ctrlProduction.warningShown = true;
          break;
        }
        case 'ctrlResearchDevelopment': {
          state.ctrlResearchDevelopment.warningShown = true;
          break;
        }
        case 'ctrlRiskManagement': {
          state.ctrlRiskManagement.warningShown = true;
          break;
        }
        case 'ctrlSales': {
          state.ctrlSales.warningShown = true;
          break;
        }
        case 'ctrlStrategicPlanning': {
          state.ctrlStrategicPlanning.warningShown = true;
          break;
        }
        default: {
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { deactivateWarning } = warningBenchmarkingControllingSlice.actions;

/**
 * Selects the warning benchmarking controlling state
 */
export const selectWarningBenchmarkingControlling = (state) => state.warningBenchmarkingControlling;

export default warningBenchmarkingControllingSlice.reducer;
